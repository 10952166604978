.wave_wating_sctn {min-height: 100vh;  width: 100%; margin: 0 auto; background: #221e29; display: block; padding: 20px;}  
.wave_wating_sctn  .wave_logo_part{ display: inline-block; width: 100%; text-align: left;}
.wave_wating_sctn  .wave_logo_part img{width: 200px;}

 

.wave_wait_timer{color: #eea236; font-size: 35px; font-weight: 100; margin-top: 50px;}
.wave_wait_timer i{margin-right: 5px;}
 
.wait_msg{display: inline-block; width: 100%; text-align: center; font-size: 32px; color: #fff; font-weight: 100;}
.wait_msg span{display: block; font-size: 24px; padding-top: 15px;}

.wave_waiting_contianer { display: table;  margin:20px auto 0px; padding:0px; border-radius: 20px; width: 100%; } 

.chat_block{display: inline-block; width: 100%; background: #0000005c; border-radius: 5px; padding: 30px; min-height: 500px;}
.chat_block ul{list-style: none; margin: 0px; padding: 0px; max-height: 60vh; overflow: auto;}
.chat_block ul li{display: inline-block; width: 100%; margin-bottom: 20px;}
.chat_block ul li .sender_detail{display: block; font-size: 12px; color: #aaa; font-weight: 500;}
.chat_block ul li .sender_detail span{float: right; font-size: 10px; color: #7d7d7d; display: inline-block; margin-top:5px; }
.chat_block ul li .chat_text{ background: #2E2A35; padding: 15px; border-radius: 5px; margin-top: 15px; position: relative;
 font-size: 14px; color: #868686}
 .chat_block ul li .chat_text audio{width: 100%;}
/*.chat_block ul li .chat_text:after { position: absolute; left: 25px; top: -8px; content: ""; display: inline-block; width: 0; 
 height: 0;  border-style: solid; border-width: 0 10px 10px 10px; border-color: transparent transparent #2E2A35 transparent;
}*/
 .chat_block h3 {text-align: left; color: #fff; font-size: 18px; margin: 0px 0px 15px; font-weight: 100}
 .chat_block h3 span{display: inline-block; padding: 2px 5px; background: #5000ff; color: #fff; border-radius: 5px; font-size: 13px; font-weight: 400; margin-left: 5px;}


.wave_wait_rrom_title{color: #fabd47; font-size: 22px; text-align: left; font-weight: 100; margin-bottom: 20px; padding-left: 10px;}
.wave_wait_rrom_title i{margin-right: 5px;}

.waiting_banner{display: block; text-align: center;}
.waiting_banner img{width: 600px; max-width: 100%;}

.waiging_msgh{color: #fff; font-size: 22px; text-align: center; font-weight: 100; margin-top: 40px;}

.waveno_message_info{ margin-top: 150px; padding: .75rem 1.25rem; border-radius: .25rem; background: #fff3cd24; border:solid 1px #fcc829; color: #d2a31b }


.waiging_msgh_join {color: #dbd81a ;font-size: 17px;text-align: center;	font-weight: 400;	margin-top: 40px;	background: #f0ffff2e;
	padding: 15px;	border-radius: 8px;}

@media only screen and (max-width:768px) { 

.col_reverse_mobview{flex-direction:column-reverse;}
.chat_block{margin-top: 20px;}
.waiging_msgh{font-size: 18px;}
}

@media only screen and (max-width:575px) { 
 .meeting_dtl_78{  margin-top: 15px;}
 .wave_waiting_contianer{padding: 0px;}
 .waiting_banner{margin-top: 15px;}

}
