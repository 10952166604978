@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
body{font-family: 'Poppins', sans-serif; font-display: swap; margin: 0; padding: 0 ;background-color: #0b0b14 !important  ; font-weight: normal;}
a{text-decoration: none!important;}
a, a img {outline: none;}
h1 h2, h3, h4, h5, h6 {font-family: 'Poppins', sans-serif;}
::-moz-selection { background: #5057f0; }
::selection { background: #5057f0; }
.pd{margin: 100px 0}
.bgdark {background:#14151f;}
.txt-yellow{color: #e3c179;  }
.txt-perpal{color: #5057f0;}
.bg-perpal {background: #5057f0;}
.pdt{margin-top:120px;}
.mt-90 {margin-top: 145px;}
.pt-140 {padding-top: 140px;}
.homepage-container{font-family: 'Poppins', sans-serif; background-color: #0b0b14 !important;}
.homepage-container .btn {font-family: 'Poppins', sans-serif !important;}
.heading {font-size: 40px;  letter-spacing: -2px; font-weight: 600; color: #FFF; }
.heading-md {font-size: 36px;  letter-spacing: -2px; font-weight: 500; color: #FFF; }


.header-fluid {background: rgb(21, 23, 35); transition: all 0.15s linear 0s;}
.header-fluid .header{ margin-top: 0px; padding: .5rem 0rem!important;}
.header{ width: 100%;  padding:3px 0px; margin-top:15px;}
.header .logo {position: relative; top: -1px;}
.header .w65{
  width: 65px;
}
.imsize{ width:220px;}
.header .nav{ float: right; }
.header .nav ul{ text-decoration: none;list-style: none; padding:0; margin:0; }
.header .nav ul li{float: left; }
.header  .navbar-collapse {justify-content: flex-start;}
.header .navbar-nav .nav-link  {color: #FFF; font-weight: 400; font-size: 14px; padding-left: 0.8rem; padding-right: 0.8rem;}
.header .navbar-nav .nav-link:hover {color:#5057f0;}
.header .navbar-list .nav-link { color:rgb(255, 255, 255, 0.9) }
.header .navbar-list .nav-link:hover { color: #FFF;}
.header .header-rgt-link .btn {padding: 6px 15px; border-radius:30px; margin: 0 3px; font-size: 13px; font-weight: 500;}
.header .signup-btn{background-color: #5057f0; width: 90px; color: #fff; border-radius: 3px; }
.header .signup-btn:hover{background-color:#5057f0; color: #ecfaff; transition:.8s;}
.header .login-btn{ color: #FFF; width: 90px; border:1px solid #FFF; }
.header .login-btn:hover {background: #c04a6f; border-color:#c04a6f ;}
.header .btn-join {color: #FFF; }
.header .btn-join:hover {background-color:#5057f0;}
.header-rgt-img {position: absolute; right: -40px; top: 0px; width: 600px;} 

.header-new {flex-flow: column;}
.header-new-navbar {background: #161824; padding-top: 15px; padding-bottom: 15px; margin-top: 15px;}
.header-rgt-section {margin-top: 40px;} 
.Webinar-lft {padding-top: 30px; position: relative;}
.Webinar-lft .img-one {position: absolute; left: 0px; margin-top: 20px;}
.Webinar-lft .img-two {position: absolute; right: 0px;}
.Webinar-lft h1 {font-size: 43px; padding-top: 100px; text-align: center; letter-spacing: -2px; font-weight: 600; color: #FFF;}
.Webinar-lft p {color:#bababa; font-weight: 300; text-align: center; margin: 20px 0px;} 
.Webinar-lft .btn-pink {background: #c04a6f; animation: btnshadow 2s infinite; color: #FFF; border-radius: 30px; width: 150px; height: 50px; font-weight: 500; padding-top: 7px;}
.webinar-rgt-img {margin-top: 40px; position: relative; z-index: 200;}
@-webkit-keyframes btnshadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(192,74,111, 0.7);
  }
  70% {
      -webkit-box-shadow: 0 0 0 15px rgba(192,74,111, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(192,74,111, 0);
  }
}
@keyframes btnshadow {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(192,74,111, 0.7);
    box-shadow: 0 0 0 0 rgba(192,74,111, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 15px rgba(192,74,111, 0);
      box-shadow: 0 0 0 15px rgba(192,74,111, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(192,74,111, 0);
      box-shadow: 0 0 0 0 rgba(192,74,111, 0);
  }
}
/*----------------Test Animation start here----------------------------------*/
.header-top-content {margin-top: 80px;}
.clear {clear: both;}
.slideContent {
  /* position: absolute; */
  top:195px;
/*  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);*/
  overflow: hidden;
 font-size: 55px; 
 letter-spacing: -2px;
 line-height: 40px;
 color: #FFF;
  width: 100%; 
 color: #5057f0 !important;

}
.slideContainer {
  font-weight: 600;
  overflow: hidden;
  height: 45px;
  padding: 0 0px;
}


.content__container__text {
  display: inline;
  float: left;
  text-align: center;
  margin: 0;
}
.slide-container-list {
  margin-top: 0;
  text-align: center;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
.slide-item {
  line-height: 50px; 
  text-align: center;
  margin: 0;
  letter-spacing: -3px;
}

@-webkit-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%, 12.66%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  16.66%, 29.32% {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
  33.32%,45.98% {
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
  }
  49.98%,62.64% {
    -webkit-transform: translate3d(0, -75%, 0);
            transform: translate3d(0, -75%, 0);
  }
  66.64%,79.3% {
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
  }
  83.3%,95.96% {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}

.logo-fluid {background:#1e202d; padding: 3px 0 0px 0px; height: 78px; margin-top: 80px;}
.logo-section li { display: inline-block; margin: 0 12px; position: relative;}
.logo-sprite {background: url(../../public/images/homePage/logo-sprite2.png); display: inline-block;}

.logoOne {width:123px; height: 71px; background-position: 0px 0px; position: relative; top:5px;}
.logoOne:hover {background-position: 0px -71px;}

.logoTwo {width:134px; height: 70px; background-position: -168px 0px; position: relative; top:3px;}
.logoTwo:hover {background-position: -168px -71px;}

.logoThree {width:91px; height: 71px; background-position: -343px 0px;}
.logoThree:hover {background-position: -343px -71px;}

.logoFour {width:97px; height: 67px; background-position: -478px 0px;}
.logoFour:hover {background-position: -478px -71px;}

.logoFive {width:145px; height: 71px; background-position: -617px 0px; position: relative; top:5px;}
.logoFive:hover {background-position: -617px -71px;}

.logoSix {width:181px; height: 71px; background-position: -806px 0px; position: relative; top:5px;}
.logoSix:hover {background-position: -806px -71px;}

.logoSeven {width:128px; height: 69px; background-position: -1023px 0px; position: relative; top:-1px;}
.logoSeven:hover {background-position: -1023px -71px;}


/*-----------Intro Section css start here-----------------*/
.intro-container {border:1px solid rgb(255, 255, 255, 0.2); background-color: #0b0b14; border-radius: 10px; position: relative; padding: 15px 5px;}
.line {border-color:rgb(255, 255, 255, 0.2) ; position: absolute; width: 100%; top:47%;}
.intro-container h2 {position: absolute; display: inline-block; color:rgb(255, 255, 255, 0.5); width: 260px; letter-spacing: 0px; font-weight: 400; margin: 0 auto; left: 0px; top:-18px; background-color: #0b0b14; padding: 5px 20px;  right: 0px; font-size: 16px;}
.intro-section {padding-top:70px; padding-bottom: 70px; margin-bottom: 70px; position: relative; padding-left: 0px; padding-right: 0px;}
.intro-box-section {display: flex; flex-direction: row; -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; justify-content: center; padding: 0px 0 0 0px;} 
.intro-col { width: 20%; border:1px solid rgb(255, 255, 255, 0.2);  display: flex; flex-direction: column; justify-content: center; color: #FFF;  align-items: center; margin: 0 10px;  text-align: center; border-radius: 10px; padding: 10px 10px;}
.intro-col .col-txt {font-size: 15px; color: #FFF; font-weight: 100;    }
.intro-col .col-bold-txt {font-size: 40px; font-weight: 600; letter-spacing: -1px;}
.intro-container .color-perpal {color: #863fff;}
.intro-container .color-blue {color: #2878ff;}
.intro-container .color-orange {color: #ff8128;}
.intro-container .color-green {color: #48e04e;}
.intro-container .color-red {color: #ff415c;}

/*-----------Intro Section css end here-----------------*/
/* .meeting-info  {background: #FFF; box-shadow: 0 0 10px #ccc; border-radius: 10px; padding: 15px;}
.meeting-info h2 {color:#2f2f44; font-size: 29px; letter-spacing: -2px; margin-bottom: 15px;}
.meeting-info .icon {background:#e91e63 ; width: 45px; height: 45px; display: inline-block; border-radius: 12px; text-align: center; color: #FFF; margin-right: 15px; font-size: 20px; padding-top: 12px; }
.meeting-info .meeting-list li {background: #f3f2eb; font-size: 15px; border-radius: 5px;padding: 7px 15px; margin-bottom: 10px;}
.meeting-info .meeting-list li i {color:#e91e63; margin-right: 10px;} */

/* .webinar-list li {background: #e9f7fd; border:1px solid #d2ebf7; font-weight: 500;  width: 45%;  text-align: center; margin: 10px; display: inline-block; font-size: 15px; border-radius: 10px;padding: 15px 15px; margin-bottom: 10px;}
.webinar-list li i {color: #03a9f4; font-size: 20px; margin-bottom: 10px;} 
.btn-view-more {background:#03a9f4 ; color: #FFF; border-radius: 30px; width: 120px; line-height: 1.8; height: 40px; font-size: 15px; margin-top: 20px;}
.btn-view-more:hover {background:#01a1eb ; color: #FFF; text-decoration: none;}
.webinar-list li .btn-join { border:1px solid #01a1eb; color: #01a1eb; border-radius: 20px; margin-top: 15px; width: 80px; padding: .20rem .5rem;}
.webinar-list li .btn-join:hover {background:#01a1eb; color: #FFF;} */

/*.upcoming-meeting {padding-top: 50px; padding-bottom: 0px; margin-top: -70px; }
 .meeting-info {background: #454cdc; min-height: 120px; display: flex; justify-content: center; flex-direction: column; align-items: center; border-radius: 10px; text-align: center; padding: 15px; margin-bottom: 30px; color: #FFF;	}
.meeting-info h3 {font-weight: 300; font-size: 14px; padding-top: 10px; color: #FFF; }
.meeting-info p {font-size: 14px; line-height: 21px; font-weight: 300;  text-align: center;}
.meeting-info i {font-size: 18px; margin-bottom: 5px; opacity: 0.9;}
.meeting-info .date {background:#545cf8 ; font-size: 12px; border-radius: 30px; padding: 5px 15px;} */

/*-----------Webinar Section css start here---------------------*/
.webinar-tabs {border-bottom: none; justify-content: center; margin-top: 40px;}
.webinar-tabs .nav-link {background:#1c1e2c ; border:none; display: inline-block; font-size: 14px; margin: 0px 5px;  color:rgb(255, 255, 255, 0.5); padding: 10px 20px; border-radius: 20px;}
.webinar-tabs .nav-item.show .nav-link, .webinar-tabs .nav-link.active {background:#545cf8; color: #FFF;}
.card-webinar {background: #1e202d; min-height: 120px; display: flex; justify-content: center; flex-direction: column; align-items: center; border-radius: 10px; text-align: center; padding: 15px; margin-bottom: 30px; color: #FFF;	}
.card-webinar h3 {font-weight: 300; font-size: 14px; padding-top: 10px; color: #FFF; }
.card-webinar p {font-size: 14px; line-height: 21px; margin: 20px 0px; font-weight: 100;  text-align: center;}
.card-webinar i {font-size: 15px;  opacity: 1;}
.card-webinar .btn-join {  background:#292c3d ; color: #FFF; font-size: 12px; border-radius: 20px; margin-top: 0px; width: 80px; padding: .25rem .5rem;}
.card-webinar .btn-join:hover {background:#01a1eb; color: #FFF;}
.btn-view-more {background:#545cf8 ; color: #FFF; border-radius: 30px; width: 150px; line-height: 2.5; height: 50px; font-size: 15px; margin-top: 30px;}
.btn-view-more:hover {background:#545cf8 ; color: #FFF; text-decoration: none;}
/* .public-webinar-section .webinar-flow-img {right: -50px; position: relative;} */

.event-card-fluid {margin-bottom: 90px;}
.event-card-fluid .carousel-control-next, .event-card-fluid .carousel-control-prev { width: 40px;}
.event-card-fluid .carousel-control-next-icon, .event-card-fluid .carousel-control-prev-icon {width: 40px; height: 40px; font-size: 16px; background: rgb(0, 0, 0, 0.5); display: flex; color:#FFF; justify-content: center; align-items: center; border-radius: 10px;}
.event-card-fluid .carousel-control-next-icon:hover, .event-card-fluid .carousel-control-prev-icon:hover {background:#545cf8 ;}
.event-card-fluid .carousel-control-next {right: -60px;}
.event-card-fluid .carousel-control-prev {left: -60px;}
.event-card-fluid .title-round {background:#1c1e2c ; display: inline-block; font-size: 13px; margin-top: 40px; color:rgb(255, 255, 255, 0.5); padding: 10px 20px; border-radius: 20px;}

.card-event {background:#161823; padding: 0px; border-radius: 10px; margin-top: 40px; position: relative; }
.card-event .card-img img {border-radius: 10px 10px 0px 0px; min-height: 215px; max-height: 215px;}
.card-event .card-content {padding-bottom: 15px;}
.card-event .bg-warning {background-color: #e6b319 !important;}
.card-event .event-status {position: absolute; right: 15px; width: auto; font-size: 13px; font-weight: 500; color:#FFF; padding: 4px 10px; border-radius: 5px;}
.card-event .card-tag {color:rgb(255, 255, 255, 0.5); font-size: 13px; font-weight: 500; margin-bottom: .75rem; text-transform: uppercase; font-weight: 500;}
.card-event .card-title {color:#FFF;  font-weight: 500; font-size: 15px; height: 40px;}
.card-event .card-caption {font-size: 13px; color:rgb(255, 255, 255, 0.5); font-weight: 100;}
.card-event .card-dt {border-bottom:1px solid rgb(255, 255, 255, 0.1); padding-bottom: 15px; margin-left: 0px; margin-right: 0px; margin-bottom: 1rem; }
.card-event .card-dt .event-txt {margin-top: .20rem; font-size: 18px; color:rgb(255, 255, 255, 0.6); font-weight: 500;  }
.card-event .card-dt .event-txt span {color:rgb(255, 255, 255, 0.4); font-size: 13px; font-weight: 100;}
.card-event .card-dt .date {padding-left: 0px;}
.card-event .card-dt .time {padding-right: 0px;}
.card-event .speaker-list {vertical-align: top; margin-top: 0.75rem;}
.card-event .speaker-list ul {display: inline-block; text-align: center; width: 100%; vertical-align: top; padding: 0px; margin-bottom: 0px;}
.card-event .speaker-list ul li {list-style: none; margin: 0 2px; text-align: center; display: inline-block; cursor: pointer; }
.card-event .speaker-list ul li .icon-cirle {width: 52px; height: 52px;  background:rgb(0, 0, 0, 0.2);  border-radius: 50% 50%;  margin-bottom: 5px;  }

.card-event .speaker-list .speaker-title { background:#0e0f18 ; text-transform: uppercase; display: inline-block; font-size: 12px; margin: 0px 0 15px 0px; color:rgb(255, 255, 255, 0.9); padding: 6px 20px; border-radius: 20px;}
.card-event .speaker-list ul li .speaker-name {color:rgb(255, 255, 255, 0.4); font-size: 10px; font-weight: 100; opacity: 0;}
.card-event .speaker-list ul li:hover .speaker-name { opacity: 1;}

.popover {background:#07070d; font-family: 'Poppins', sans-serif;}
.popover .popover-header {background: none; text-align: center; font-size: 13px; font-weight: 400; border:none; padding: .5rem 1rem 0rem 1rem; color:#FFF}
.popover .popover-body {padding: .5rem 1rem; text-align: center; color:rgb(255, 255, 255, 0.6); font-weight: 100; font-size: 12px;}
.bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {    border-bottom: 1px solid #07070d;}
.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {border-bottom-color:  #07070d;}
.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after {border-top-color:  #07070d;}

.link-view-more {color: #545cf8; font-size: 15px;}
.link-view-more:hover {color: #FFF;}
/* .card-event .speaker-list ul li:nth-child(1) .icon-cirle {border-color: #6adfac;}
.card-event .speaker-list ul li:nth-child(2) .icon-cirle {border-color: #eebe76;}
.card-event .speaker-list ul li:nth-child(3) .icon-cirle {border-color: #7aa4f1;} 
.card-event .speaker-list ul li:nth-child(4) .icon-cirle {border-color: #f06e6e;}
.card-event .speaker-list ul li:nth-child(5) .icon-cirle {border-color: #79e6dc;} 
.card-event .speaker-list ul li:nth-child(6) .icon-cirle {border-color: #8b8df7;} */

.card-event .card-top {position: absolute; right: 10px; top:10px; width: auto; }
.card-event .card-top .btn {font-size: 13px; font-weight: 500; color:#FFF; padding: 4px 10px; border-radius: 5px;}

/*-----------Webinar Section css start here---------------------*/

/*----------------Webinar Soultions css start here------------------*/
.webinar-solution {padding-top: 50px; padding-bottom: 0px; margin-top: -70px; }
.shape-devider-top {
	background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1280 100' style='enable-background:new 0 0 1280 100;' preserveAspectRatio='none' width='100%25' height='100px'%3E%3Cg fill='%230b0b14'%3E%3Cpath d='M0,82.7c0,0,280.3,50,612-31.3s668,0,668,0V0H0V82.7z'/%3E%3C/g%3E%3C/svg%3E");
	transform: scaleX(-1);
    height: 150px;
    background-size: 100% 150px ; 
    top: 0px;

    }

 .shape-devider-bottom {
background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1280 100' style='enable-background:new 0 0 1280 100;' preserveAspectRatio='none' width='100%25' height='100px'%3E%3Cg fill='%235057f0'%3E%3Cpath d='M0,82.7c0,0,280.3,50,612-31.3s668,0,668,0V0H0V82.7z'/%3E%3C/g%3E%3C/svg%3E");
	transform: scaleX(-1);
    height: 150px;
    background-size: 100% 150px ; 
    bottom: -150px;

    }

.shape-devider {   
	position: absolute;
    left: 0px;
    height: 150px;
    width: 100%;
    background-repeat: repeat-x;
    background-size: 100% 150px;
    pointer-events: none;
    z-index: 100;
    background-color: transparent;}

.feature-col {background: #454cdc; border-radius: 10px; text-align: center; padding: 15px; margin-bottom: 30px; color: #FFF;	}
.feature-col h3 {font-weight: 500; font-size: 16px; padding-top: 10px; color: #FFF; }
.feature-col p {font-size: 12px; font-weight: 300; opacity: 0.8; text-align: center;}

.webinar-flow {padding-bottom: 40px;}
.webinar-flow .webinar-flow-img {margin-left: -30px;}
.webinar-flow .list-item {text-align: center; margin: 0px; width: 100%; padding:0px 20px;  }
.webinar-flow .list-item li {list-style: none; width: 33%; display: inline-block; margin: 0px 0px 30px 0px; padding: 0px 30px;}
.webinar-flow h2 {padding-bottom: 50px;}
.webinar-flow .list-item h3 {font-weight: 500; font-size: 16px; color: #FFF; padding: 15px 0 5px 0px;  }
.webinar-flow .list-item p {font-size: 13px; font-weight: 100; opacity: 0.7; color: #FFF; text-align: center;}
.webinar-flow .btn-perpal {background: #6238b6; animation: pulse 2s infinite; color: #FFF; border-radius: 30px; width: 195px; height: 50px; font-weight: 500; padding-top: 11px; margin-top: 20px;}


.webinar-feature .webinar-flow-img {margin-left: -30px;}
.webinar-feature .list-item {text-align: center; margin: 0px; width: 100%; padding:0px 20px;  }
.webinar-feature .list-item li {list-style: none; width: 100%; display: inline-block; margin: 0px 0px 10px 0px; padding: 0px 0px;}
.webinar-feature h2 {padding-bottom: 50px;}
.webinar-feature .list-item h6 {font-weight: 500; font-size: 16px; color: #FFF; padding: 16px 0 0px 0px;  }
.webinar-feature .list-item p {font-size: 13px; font-weight: 100; opacity: 0.7; color: #FFF; text-align: center;}
.webinar-feature .btn-perpal {background: #6238b6; animation: pulse 2s infinite; color: #FFF; border-radius: 30px; width: 195px; height: 50px; font-weight: 500; padding-top: 11px; margin-top: 20px;}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(98,56,182, 0.7);
  }
  70% {
      -webkit-box-shadow: 0 0 0 15px rgba(98,56,182, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(98,56,182, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(98,56,182, 0.7);
    box-shadow: 0 0 0 0 rgba(98,56,182, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 15px rgba(98,56,182, 0);
      box-shadow: 0 0 0 15px rgba(98,56,182, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(98,56,182, 0);
      box-shadow: 0 0 0 0 rgba(98,56,182, 0);
  }
}
                   
.connect-section {position: relative; padding: 150px 0 0 0px; height: 500px;}
.connect-section h2 {padding: 80px 0px;}
.connect-section h2 span {color:#32d28e;}
.connect-section .dotted-img-one {position: absolute; right: 0px; top: -50px;}
.connect-section .dotted-img-two {position: absolute; left: 0px; bottom: -50px;}
.connect-section .circle-rgt {position: relative; margin-top: 50px;}
.connect-section .circle {width: 200px; height: 200px; border-radius: 50% 50%; position: absolute; display:flex; justify-content: center; align-items: center;  letter-spacing: -2px; font-weight: 600; text-align: center; }
.connect-section .circle-one {background:#4cacf3; color:#fff; top: 30px; z-index: 10; font-size: 30px;}
.connect-section .circle-two {background:#2fd68f; color:#FFF; left: 180px; font-size: 30px;}
.connect-section .circle-three {background:#f16565; color:#FFF; top: 30px; font-size: 23px; left: 350px; z-index: 10;}

.schedule-webinar {padding-bottom: 0px; position: relative;}
.shape-devider-bottom2 {
background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1280 100' style='enable-background:new 0 0 1280 100;' preserveAspectRatio='none' width='100%25' height='100px'%3E%3Cg fill='%2314151f'%3E%3Cpath d='M0,82.7c0,0,280.3,50,612-31.3s668,0,668,0V0H0V82.7z'/%3E%3C/g%3E%3C/svg%3E");
	transform: scaleX(-1);
    height: 150px;
    background-size: 100% 150px ; 
    bottom: -150px;

    }
.schedule-webinar .pr-0 {padding-right: 0px;}
.schedule-webinar h2 {margin-bottom: 40px; margin-top: 30px;}
.schedule-webinar h2 span {color:#f0754a;}
.process-col {background: #1e202d; border-radius: 10px; height: 150px; text-align: center; padding: 15px; margin-bottom: 15px; color: #FFF;	}
.process-col .txt-count {font-weight: 700; font-size: 50px; color:#68666c;}
.process-col h3 {font-weight: 300; line-height: 25px; font-size: 15px; padding-top: 10px; color: #FFF; }


.presentation-section { padding-bottom: 120px; position: relative;}
.presentation-section h2 {margin-bottom: 0px; padding-bottom: 40px;}
.presentation-section h2 span {color:#fed250;}
.presentation-section .circle {width: 180px; height: 180px; border-radius: 50% 50%; background:#1e202d; box-shadow: 0 0 15px #120f17; border:8px solid #1a1c29; display: flex; position: absolute; justify-content: center; text-align: center; color:#86899c; font-size: 16px; font-weight: 300; align-self: center; align-items: center; }
.presentation-section .circle-one {top: 80px; left: -20px;}
.presentation-section .circle-two {bottom: 80px; left: 0px;}
.presentation-section .circle-three {top: 80px; right: -40px;}
.presentation-section .circle-four {bottom: 80px; right: -20px;}
.presentation-section .circle-five {bottom: 51px; right: 0px; left: 0px; margin: 0 auto; z-index: 20;}
.whiteboard-tollbar {width: 316px; height:215px; position: absolute; right: 130px; top: 200px; z-index: 20;}


.faq {padding: 180px 0 60px 0px;}
.faq h3 {text-align: center; font-size: 34px; margin-bottom: 40px;}
.faq .tab-content {width: 90%; margin: 0 auto;}
.faq .card {background: #0b0b14 !important;}
.card-header{background-color:#1e202d;  border:0;color: #aaa9ac; border-radius:10px !important; margin-bottom: 15px; padding: .75rem .75rem; }
.card-header span {float: right;}
.card-body{background-color: #0b0b14; padding: 0px 15px 15px 15px; text-align: justify; color: #b2bede;font-size:15px; font-weight: 300; line-height: 27px; }
.card-body .link{ color: #f68601;font-size:18px}
.card{border: 0!important;background-color: #33303a!important;}
.card h5{color: #b2bede}
.card .btn-link{color: #b2bede;width: 100%;text-align: left;font-size: 20px;font-family: 'Poppins', sans-serif; text-decoration: none!important;}

.contact-us {background: #10101d; margin-top: 0px; padding: 30px 0px; color: #FFF;}
.contact-us h3 {letter-spacing: -2px; margin-bottom: 30px;}
.contact-us p {line-height: 30px; font-size: 15px; font-weight: 100;}
.contact-us p i {margin-right: 10px;}
.contact-us p strong {font-size: 20px;}
.contact-us p a {color: #f0754a;}
.contact-us p a:hover {color:#454cdc;}

.footer {  padding: 40px 0px 0px 0px;  position: relative; text-align: center;}
.footer-bottom {padding: 0px 0 30px 0px; margin-top: 40px; border-top:1px solid #32323e; position: relative;}
.poweredbytxt {color: #FFF; font-size: 13px; background: #0b0b14; position: absolute; left: 0px; right: 0px; top:-15px; margin: 0px auto; display: inline-block; width: 200px;}
.footer-wave-logo {margin-top: 20px;}
.sub-sites li {
    border-left: 1px solid #32323e;
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 10px;
    padding: 0 10px;
}
@media screen and (min-width:1400px) { 
  .header-rgt-img {width: 701px; right: -140px; top:0px;  }
  .header-slider-container {margin-bottom: 40px; height: 430px;}

 }

 @media screen and (min-width:767px) and (max-width:992px)  {
.header-fluid {padding-left: 0px; padding-right: 0px;}
.header .logo {left: 15px;}
.header .navbar-collapse {position: absolute; left: 0px; top:30px; background: rgb(21, 23, 35); z-index: 100; width: 100%; text-align: left;}
.header .navbar-collapse .navbar-nav {  width: 100%; text-align: left;}
.header .navbar-nav .nav-link {font-size: 1rem; padding: 1rem;}
.header .navbar-toggler {color:#FFF; position: absolute; left: -30px; top:7px}
.header .navbar-toggler:focus {box-shadow: none; outline: none; border:none;}
.header-navbar {position: absolute; width: 100%; max-width: 100%;}
.header-rgt-section {text-align: center; }
  .header-rgt-img {position: relative; left: 0px; right: 0px; width:auto ; margin-top: 40px;}
.logo-fluid {height: auto; margin-top: 40px;}
  .logo-section li {text-align: center; width: 24%;}
  .intro-box-section {flex-wrap: wrap; }
  .intro-col {width: 30%; margin: 0 10px 15px 10px;}
.event-card-fluid .carousel-control-prev {left: 0px;}
.event-card-fluid .carousel-control-next {right: 0px;}
.presentaion-wireframe { max-width: 100%;}
.presentation-section h2 {padding-bottom: 100px;}
.presentation-section .circle {z-index: 90;}
.webinar-feature .list-item li {width: 48%;}
.webinar-feature .webinar-flow-img {margin-left: 0px;}
.connect-section {height: 385px;}
.connect-section h2 {font-size: 32px; padding: 30px 0px;}
.connect-section .circle {width: 150px; height: 150px; font-size: 18px;}
.connect-section .circle-two {left: 129px;}
.connect-section .circle-three {left: 230px;}
.connect-section .dotted-img-one, .connect-section .dotted-img-two {width: 100px;}
.whiteboard-tollbar {width: 250px; height: 170px; top:270px; right: -12px;}
.whiteboard-tollbar img {width: 250px;}
 }

 @media screen and (max-width:767px) {
.header-fluid {padding-left: 0px; padding-right: 0px;}
.header .logo {padding-left: 15px;}
.header .header-rgt-link {margin-top: 0.5rem; margin-bottom: 0.5rem; text-align: center !important;}
.header-fluid .container {padding-left: 0px; padding-right: 0px;}
.header .navbar-collapse {position: absolute; left: 0px; background: rgb(21, 23, 35); z-index: 100; width: 100%; text-align: left;}
.header .navbar-collapse .navbar-nav {  width: 100%; text-align: left;}
.header .navbar-nav .nav-link {font-size: 1rem; padding: 1rem;}
.header .navbar-toggler {color:#FFF; position: absolute; right: 0px; display: none;}
.header .navbar-toggler:focus {box-shadow: none; outline: none; border:none;}
.header-rgt-img {position: relative; left: 0px; right: 0px;}
.header-rgt-section {margin-top: 30px;}

.logo-fluid {height: auto; margin-top: 40px; overflow: hidden;}
.logo-section {margin: 0rem; padding: 0rem;}


.intro-section {padding-top: 40px; padding-bottom: 40px; padding-left: 15px; padding-right: 15px; margin-bottom: 0px;}
.intro-container {padding: 15px; }
/* .intro-section, .webinar-tabs .nav-link {padding-left: 15px; padding-right: 15px;} */
.logo-section li {width: 49%; text-align: center; margin: 0;}
.intro-col { width: 100%; margin: 0 0 1rem 0;}
.intro-box-section {flex-direction: column;}
.line {display: none;}

.event-card-fluid {padding-left: 0; padding-right: 0; margin-bottom: 40px;}
.event-card-fluid .carousel-control-prev {left: 0px;}
.event-card-fluid .carousel-control-next {right: 0px;}
.webinar-tabs .nav-link {padding-left: 15px; padding-right: 15px;}



.Webinar-lft {padding-top: 20px;}
.Webinar-lft .img-two {right: -20px;}
.img-responsive {max-width: 100%;}
.col-reverse {flex-direction: column-reverse;}
.feature-col {margin-top: 15px !important; margin-bottom: 0px;}
.mob-plr-0 {padding-left: 0px; padding-right: 0px;}
.shape-devider {height: 70px; background-size: 100% 70px;}
.shape-devider-bottom {bottom: -60px;}
.pt-140 {padding-top: 50px;}
.webinar-rgt-img {margin-top: 15px;}
.webinar-solution {margin-top: 0px;}
.presentation-section {padding-left: 0px; padding-right: 0px;}
.presentation-section .circle {width: 100%; height: 180px; padding: 15px 15px; margin-bottom: 15px; border-radius: 10px; height: auto;  box-shadow: none; border:1px solid #42465d; display: flex; position: relative; justify-content: center; text-align: center; color:#797979; font-size: 16px; font-weight: 300; align-self: center; align-items: center; }
.presentation-section .circle br {display: none;}
.presentation-section .circle-one {top: auto; left: auto;}
.presentation-section .circle-two {bottom: auto; left: auto;}
.presentation-section .circle-three {top: auto; right: auto;}
.presentation-section .circle-four {bottom: auto; right: auto;}
.presentation-section .circle-five {bottom: auto; right: auto; left: auto; margin: 0 auto; z-index: 20;}
.whiteboard-tollbar {width: 170px;  top: 470px; right: 0px;}
.whiteboard-tollbar img {width: 170px; }
.presentation-section {padding-bottom: 50px;}
.webinar-flow-img {margin-left: -30px; margin-left: 0px;}
.webinar-flow h2 {padding-bottom: 20px;}
.webinar-flow-mid {margin-bottom: 30px;}
.webinar-flow .list-item {margin: 0px; padding: 0px;}

.faq { padding: 100px 0 40px 0px;}
.faq .tab-content {width: 95%;}
.faq .card .btn-link {white-space: normal;}
.faq h3 {font-size: 25px;}
.faq .card-header { padding: .50rem;}
.faq .card .btn-link {font-size: 15px;}
.faq .card-body {font-size: 13px; line-height: 25px;}
.webinar-flow .webinar-flow-img {margin-left: 0px;} 
.shape-devider-bottom2 {bottom: -70px;}
.contact-us p strong {font-size: 16px;}
.contact-us p {font-size: 13px;}
.footerdivya .footer-divider {margin: 0 10px !important;}

}

@media screen and (max-width:480px) {
.connect-section {height: auto;}
.connect-section h2 {padding: 20px 0; }
.connect-section .circle {position: relative; left: 0px; letter-spacing: -1px; right: 0px; margin: 0 auto 20px auto; top: auto; width: 100%; font-size: 22px; border-radius: 15px; height: 60px; }
.connect-section .dotted-img-one, .connect-section .dotted-img-two {width: 100px;}
.connect-section .circle br {display: none;}
.mob{display: none;}
.mobil{display:block;}
.heading {font-size: 32px;}
.heading-md {font-size: 28px;}
.mt-90 {margin-top: 0px;}
.sarv-wave .header .login-btn, .signup-btn{font-size: 14px;}
.Webinar-lft h1 {font-size: 35px;}
.slideContent {top: 220px; font-size: 45px;}
.whiteboard-tollbar {top:400px}

}
