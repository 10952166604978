.leftPanelContainer {
    display: flex; 
    flex-direction: row;
    height: 100%;

    .leftnav-tabs { 
        width: 80px;
        min-height: calc(100vh - 52px);
        background: var(--color--off-dark);
        text-align: center;
       
    }

    .nav-tabs {
        border-bottom: none;
        .nav-item {
            width: 100%;
            color:var(--color-white);

            &:nth-child(1) {
                .nav-link {
                  &.active, &:hover {border-left:5px solid #49D6BF;}  
                }
            }

            &:nth-child(2) {
                .nav-link {
                  &.active, &:hover {border-left:5px solid #F98D3C;}  
                }
            }

            &:nth-child(3) {
                .nav-link {
                  &.active, &:hover {border-left:5px solid #FB3F4B;}  
                }
            }

            &:nth-child(4) {
                .nav-link {
                  &.active, &:hover {border-left:5px solid #3F8BFC;}  
                }
            }

            &:nth-child(5) {
                .nav-link {
                  &.active, &:hover {border-left:5px solid #b449fc;}  
                }
            }

            &:nth-child(7) {
                .nav-link {
                  &.active, &:hover {border-left:5px solid #fc499d;}  
                }
            }
            
        }

        .nav-link {border:none; border-left:5px solid transparent; border-radius: 0; background: none; cursor: pointer; text-align: center; margin: 0.2rem 0;
            img {margin-bottom: 0.3rem; margin-left: -10px;}
            span {font-size: 13px; font-weight: 100; margin-left: -10px; opacity: 0.5;}

            &:hover { border-left:5px solid var(--color-primary); background: var(--color-theme-light);}

            &.active {border-left:5px solid var(--color-primary); background: var(--color-theme-light); color:var(--color-text-white);
                span {opacity: 1;}
            } 
        }
        
    }

    .tab-content {padding: var(--md-padding-x); width: 100%;}

    .btn-show {display: none;}

}

.btn-panelHandler {
    color:#FFF;
    background: var(--color-theme-light);
    border:1px solid rgba(255, 255, 255, 0.1); 
    font-size: var(--fs-12);
    width: 32px;
    height: 32px;
    border-radius: 0.4rem;
    margin: 15px auto 10px auto;
    
  
  }
  


  .hideLeftPanel {
      background: none;
      width: auto !important;

      .leftPanelContainer { 
        .leftnav-tabs {
            background: var(--color-theme);
        }

      }

.btn-show {display: block;}
.btn-hide {display: none;}

}