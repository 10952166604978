.wave_radio_reg [type="radio"] { z-index: -1;  position: absolute;  opacity: 0;}
.wave_radio_reg [type="radio"]:checked ~ label { border-color: #4062F6;  background-color: rgba(97, 154, 234, 0.16); color: #FFF;}
.wave_radio_reg [type="radio"]:checked ~ label:before {
  will-change: transform, border-width, border-color;
  -webkit-animation: bubble 1s ease-in;
          animation: bubble 1s ease-in;
}
.wave_radio_reg [type="radio"]:checked ~ label:after {
  will-change: opacity, box-shadow;
  -webkit-animation: sparkles 700ms ease-in-out;
          animation: sparkles 700ms ease-in-out;
}
.wave_radio_reg [type="radio"]:checked ~ label > span {
  will-change: transform;
  border: 0;
  background-image: -webkit-gradient(linear, left bottom, right top, from(#6E89FF), to(#4363EE));
  background-image: linear-gradient(to top right, #6E89FF, #4363EE);
  -webkit-animation: radio 400ms cubic-bezier(0.17, 0.89, 0.32, 1.49);
          animation: radio 400ms cubic-bezier(0.17, 0.89, 0.32, 1.49);
}
.wave_radio_reg [type="radio"]:checked ~ label > span:after {
  content: "";  position: absolute;  top: 50%;  left: 50%;  -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 6px;
  height: 6px;  border-radius: 10px;  background-color: #fff;}
 

.wave_radio_reg label { position: relative;  display: grid;  -webkit-box-align: center;    align-items: center; grid-template-columns: 0px auto 0px;  grid-gap: 35px;  width: 100%;  height: 45px;  padding: 0 20px;  border-radius: 0px; border: 2px solid transparent;  background-color: transparent;  -webkit-transition: all 300ms ease-in;  transition: all 300ms ease-in;   border-color: #ffffff21; background-color: #ffffff0a; color: #fff; font-size: 16px; font-weight: 400; text-align: left;}
.wave_radio_reg label:hover { border-color: #4062F6; background-color: rgba(97, 154, 234, 0.16);}
.wave_radio_reg label:before, label:after { position: absolute;  left: 29px; border-radius: 50%; content: '';}
.wave_radio_reg label:before { margin: -2rem;  border: solid 2rem #545461;  width: 4rem;  height: 4rem; -webkit-transform: scale(0);
          transform: scale(0);}
.wave_radio_reg label:after { margin: -0.1875rem;  width: 0.375rem;  height: 0.375rem;
  box-shadow: 0.32476rem -2.6875rem 0 -0.1875rem #ff8080, -0.32476rem -2.3125rem 0 -0.1875rem #ffed80, 2.30366rem -1.42172rem 0 -0.1875rem #ffed80, 1.6055rem -1.69573rem 0 -0.1875rem #a4ff80, 2.54785rem 0.91464rem 0 -0.1875rem #a4ff80, 2.32679rem 0.19796rem 0 -0.1875rem #80ffc8, 0.87346rem 2.56226rem 0 -0.1875rem #80ffc8, 1.29595rem 1.94258rem 0 -0.1875rem #80c8ff, -1.45866rem 2.28045rem 0 -0.1875rem #80c8ff, -0.71076rem 2.2244rem 0 -0.1875rem #a480ff, -2.69238rem 0.28141rem 0 -0.1875rem #a480ff, -2.18226rem 0.8312rem 0 -0.1875rem #ff80ed, -1.89869rem -1.92954rem 0 -0.1875rem #ff80ed, -2.01047rem -1.18791rem 0 -0.1875rem #ff8080;
}
.wave_radio_reg label > span { position: relative; display: -webkit-inline-box; display: inline-flex; width: 20px; height: 20px;
  border-radius: 20px;  border: 2px solid #454861;  background-image: -webkit-gradient(linear, left top, left bottom, from(#3B4059), to(#1C1E2D));
  background-image: linear-gradient(to bottom, #3B4059, #1C1E2D);}
 
.wave_radio_reg :not(:last-child) { margin-bottom: 4px;}


 

 
 

@-webkit-keyframes radio {
  0%, 17.5% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes radio {
  0%, 17.5% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
 
@-webkit-keyframes shine {
  from {
    -webkit-transform: translateX(-70px) rotate(10deg);
            transform: translateX(-70px) rotate(10deg);
  }
  to {
    -webkit-transform: translateX(300px) rotate(10deg);
            transform: translateX(300px) rotate(10deg);
  }
}
@keyframes shine {
  from {
    -webkit-transform: translateX(-70px) rotate(10deg);
            transform: translateX(-70px) rotate(10deg);
  }
  to {
    -webkit-transform: translateX(300px) rotate(10deg);
            transform: translateX(300px) rotate(10deg);
  }
} 

 
.wave_webinar_reg{  padding-top: 40px;  background: #221e29;  min-height: 100vh; width: 100%; text-align: center;}
.wave_webinar_reg .inner_from_part{ display: inline-block; width: 100%; margin: 0 auto; }
.wave_webinar_reg .webinar_title_2 { font-size: 28px; text-align: left;   margin-bottom: 15px;  color: #EE905D;  font-family: 'Comfortaa';}
.wave_webinar_reg .webinar_regdescription { font-size: 14px; text-align: left; font-weight: normal;  color: #ffffffad;  line-height: 22px; 
  margin-bottom: 40px; overflow: hidden;
   text-overflow: ellipsis;  display: -webkit-box;  -webkit-line-clamp: 5;   -webkit-box-orient: vertical}

.wave_webinar_reg .reg_field label{font-size: 16px; font-weight: 400; color: #fff; text-align: left; display: block;}
.wave_webinar_reg .reg_field .form-control { border-radius: 0px; font-size: 14px; padding: 20px; background: #ffffff0a; border-color: #ffffff21; color: #fff;}

.wave_webinar_reg .otp_input{display: flex; flex-direction: row; justify-content:space-between} 
.wave_webinar_reg .otp_input .form-control{display: flex; width: 15%;}

.webinar_reg_button{ display: inline-block;   font-size: 16px;   padding: 8px 30px;  text-align: center;  border-radius: 20px;  background: #f33c5e;  color: #fff; border:solid 1px  #f33c5e;}

.webnr_banner_sec{display: inline-block; vertical-align: top; width: 100%;   background: #0000001a}
.webinar_time_detail{font-size: 22px; color: #fff; margin-bottom: 25px; font-family: 'Comfortaa'; background: #2a71a5; padding:10px 25px; text-align: left; padding-left: 100px;}
.webinar_time_detail span{display: block; }
.webinar_time_detail font{color: gold; font-weight: 600; }
 
.webnr_banner{display: inline-block; width: 100%; padding: 0px 0px 25px; min-height: 360px;}


.webinar_brand_detail{background: #0000001a; border:2px dashed rgba(255, 255, 255, 0.2); padding: 0px; text-align: left; margin-bottom: 15px; border-radius: 5px;  }
.webinar_brand_detail .brand_logo{display: inline-block; vertical-align:middle;   width: 140px;
 height: 88px; background: #fff; text-align: center; position: relative; border-radius: 5px 0px 0px 5px}
.webinar_brand_detail .brand_logo img{max-width: 140px; max-height: 88px; position: absolute;left: 0px; right:0px; top: 0;bottom: 0;margin: auto;}  

.webinar_brand_col {min-height: 140px;}

.brand_name{font-size: 20px;  display: inline-block; vertical-align: middle; font-family: 'Comfortaa'; color: #FFF; padding: 0px 0px;}

.registrn_web_succsesmsg{  padding: 25px;  background: #ffffff0d;  border-radius: 10px;}

.webr_reg_tele .react-tel-input{padding: 8px 10px 8px 0px;}

.webr_reg_tele .react-tel-input{  font-size: 14px; padding: 0px; }
.webr_reg_tele .react-tel-input .flag-dropdown{background: #0000002e !important}
.webr_reg_tele .react-tel-input .flag-dropdown.open .selected-flag{background: #1d1923 !important; border-radius: 0px!important}
.webr_reg_tele .react-tel-input .selected-flag:hover, .dtl_input45 .react-tel-input .selected-flag:focus{background: #1d1923 !important; border-radius: 0px;}

/* .webr_reg_tele .react-tel-input .form-control {color: #fff!important; background: #ffffff0a!important; border:solid 1px #ffffff21; padding: 20px 20px 20px 50px; width: 100%;} */
.webr_reg_tele .flag-dropdown{padding-left: 0px!important; min-width: 38px!important}
.webr_reg_tele .react-tel-input .country-list{background: #242328}
.webr_reg_tele .react-tel-input .country-list .country{margin-bottom: 0px!important}
.webr_reg_tele .react-tel-input .country-list .country-name{color: #fff;}
.webr_reg_tele .react-tel-input .country-list .country:hover{background: #f1f1f117}
.webr_reg_tele  .react-tel-input .country-list .country.highlight{background: #f1f1f117}
.webr_reg_tele .react-tel-input .country-list .search{background: #26222c; border-bottom: solid 1px #ffffff0f;}
.webr_reg_tele .react-tel-input .country-list .search-box{border-color: #cacaca38}
.register_select{
    border-radius: 0px;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    background: #2B2731;
    border-color: #ffffff21;
    color: #fff;
}
.radio{display: flex;}
.webnr_header_banner{display: inline-block; vertical-align: top; box-shadow:0 0 20px rgba(0, 0, 0, 0.7); width: 100%; margin:0 0 40px 0;   background: #0000001a; border-radius: 10px;}
.webnr_header_banner .webnr_banner{display: inline-block; width: 100%; padding: 0px; min-height: 360px;}
.webnr_header_banner .no-banner { text-align: center; width: 100%; color: #4f4a56; font-size: 35px; font-weight: 500; margin-top: 80px; }
.webnr_header_banner .no-banner img {width: auto;}

.webnr-banner-img {width: 100%; text-align:center; vertical-align: middle;}
.webnr-banner-img img { max-height: 360px; max-width: 1100px;}
.webnr_lft_sec{display: inline-block; vertical-align: top; width: 100%; height: 352px;   background: #0000001a}
.webnr_lft_sec .webinar_brand_logo-col {margin-top: 0px; display: flex; flex-direction: column; align-items: center; justify-content: center; height: 230px;  }
.webnr_lft_sec .brand_name {margin-top:20px; opacity: 0.8; font-size: 28px; font-weight: 100; font-family: 'Roboto', sans-serif!important;}

@media screen and (max-width:992px) {
.reg_form_vlog{ width: 100%; max-width:100%; }
.wave_radio_reg label{grid-gap:27px; font-size: 13px;}
.no_p_md{padding: 0px 15px!important}
.wave_webinar_reg .webinar_title_2{font-size: 24px;}
.webinar_time_detail{padding: 15px 15px 15px 50px; font-size: 20px;}
}

@media screen and (max-width:767px) {
.webnr_banner_sec{background: none;}
.reg_form_vlog{ width: 80%; max-width:80%; }
.webnr_banner{display: none;}
.webnr_lft_sec {height: auto; margin-bottom: 30px;}
.webnr_lft_sec .webinar_brand_logo-col {height: auto; margin-bottom: 30px;}
}

@media screen and (max-width:500px) {
.reg_form_vlog{ width: 95%; max-width:95%; }
.wave_webinar_reg .webinar_title_2{font-size: 20px;}
.webinar_time_detail{padding: 15px 15px 15px 15px; font-size: 18px;}
.webnr-banner-img img {max-width: 100%; max-height: 250px;}
.webnr_header_banner .webnr_banner {min-height: 250px;}
}

 .lgn-label{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 10px;
  color: #FFFFFF;
  opacity: 0.5;
  text-align: left;
 }
 .lgn-text{
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 0px;
  color: #FFFFFF;
    text-align: left;
 }

 .header-m{
   background: #282A33;
   border-bottom: 1px solid rgba(255, 255, 255, 0.3);
   ;
 }
.dgns-text{
   font-size: 12px;
   line-height: 1.6;
   font-weight: 400;
}
 .bio-text{
   font-size: 10px;
   line-height: 1.6;
   font-weight: 400;
   overflow: hidden;
   height: 34px;

 }
