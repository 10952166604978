:root {
    --color-theme:#0b0b14;
   --color-theme-light:#1E202D;
   --color--off-dark:#151723;
   --color-text-light-theme: #777990;
   --color-border-bg: #0b0b15;
   --color-dark-bg: #0b0b15;
   --color-form-col-bg: #141621;
   --color-white: #FFF;
   --color-text-white: #FFF;
   --color-off-white: #F3F6F9;
   --color-off-light: #292c3c;
   --color-text-light: #8b8b98;
   --toggle-swicth-color: #3a3f52;

 
   --color-black: #000000;
   --color-gray: #777990;
   --color-gray-dark: #0b0b14;
   --color-gray-light: #8B9AA8;
   --color-gray-darkest: #111111;
   --color-gray-lighter: #a7b3bd;
   --color-gray-lightest: #d4d9df;
 
   --color-primary: #5057f0;
   --color-primary-hov: #484fe3;
   --color-success: #1cc98c;
   --color-danger: #ca4242;
   --color-warning: purple;
   --color-green: #1cc98c;
 
 
 
   --color-background: var(--color-gray-dark);
 
   --color-text: var(--color-gray);
   --color-heading: var(--color-gray-dark);
 
   --color-link: var(--color-primary);
   --color-link-hover: #2687ef;
 
   --color-gray-label: var(--color-gray);
 
   --color-transparent: #ff000000;
 }
 