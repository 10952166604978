@import './stylesheets/all.scss';

.eofficeContainer {
    display: flex; 
    flex-direction: row; 
    flex: 1; 
    flex-flow: wrap; 
}

@mixin panelbar {
    position: relative;
    top: 52px;
    background: var(--color-theme-light);
    min-height: calc(100vh - 52px);
}
.leftPanelSection {
    @include panelbar; 
    width:28%;
    border-radius: 0 1.9rem 1.9rem 0;
    @include breakpoints(largescreen) {
        width:20%; 
    }
}
.centerPanelSection { 
    flex: 1; 
    text-align: center; 
    h2 {font-size: 20px;}
    margin-top: 52px;
}
.rightPanelSection {
    @include panelbar; 
    width:28%; 
    border-radius: 1.9rem 0 0 1.9rem;  
}

.contentPanel {display: flex;  flex-direction: row; flex: 1;}