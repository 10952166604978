:root {
    --border-size-small: 1px;
    --border-size: 2px;
    --border-size-large: 3px;
    --border-radius: .2rem;
    --border-radius-round: 30px;
    --btn-height: 50px;
  
    --sm-padding-x: .50rem;
    --sm-padding-y: .3rem;
  
    --md-padding-x: 1rem;
    --md-padding-y: .45rem;
  
    --lg-padding-x: 2rem;
    --lg-padding-y: 0.6rem;
  
    --jumbo-padding-x: 3.025rem;
    --jumbo-padding-y: 1.5rem;

    --font-weight-thin: 100;
    --font-weight-md: 400;
    --font-weight-semi: 500;
  
  }
  