.FaqContent {font-family: 'Poppins', sans-serif; }
.accordion-wrapper .accordion-item h3.accordion-title {
  font-size: 1.75rem;
  font-weight: normal;
  margin: 0;
  
}

.accordion-wrapper .accordion-item h3.accordion-title button {
  position: relative;
  display: flex;
  align-items: center;
  background: #1e202d;
  border-radius: 0.6rem;
  border: none;
  font-size: 1.25rem;
  width: 100%;
  text-align: left;
  color: #b2bede;
  font-weight: 400;
  margin-top: 0.5rem;
  padding: 1.3rem;
  font-family: 'Poppins', sans-serif !important;
}

/* .accordion-wrapper .accordion-item h3.accordion-title button.active {
  background-color: #dfbd85;
  color: #ffffff;
  border: #dfbd85 solid 1px;
}

.accordion-wrapper .accordion-item h3.accordion-title button:hover {
    background-color: #dfbd85;
} */

.accordion-wrapper .accordion-item h3.accordion-title button span.title-wrapper {
  display: block;
  position: relative;
  width: 100%;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper {
  width: 5%;
  display: flex;
  justify-content: center;
}


.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper span.minus {
   content: url('../../../../public/images/homePage/FAQ/line.svg'); 
  width: 24px;
  height: 24px;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper span.plus {
  content: url('../../../../public/images/homePage/FAQ/plus.svg');
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  transition-timing-function: ease-in;
  transition: all 1s;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-close { 
  background: #e0e0e0;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0 1rem;
  opacity:0;
  width:100%;
  height:0;
  overflow: hidden;
  transition-timing-function: ease-out;
  transition: all 1s;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-open {
  overflow: hidden;
  color: #b2bede;
  padding: 1rem;
  font-size: 0.95rem;
  font-weight: normal;
  line-height: 1.7rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-radius: 0rem;
  opacity:1;
  width:100%;
  height: auto;

}

.accordion-wrapper .accordion-item .accordion-panel p {
  margin: 0;
}


@media screen and (max-width:480px) { 
  .accordion-wrapper .accordion-item h3.accordion-title button {font-size: 1rem;}
  .accordion-wrapper .accordion-item .accordion-panel .panel-open {text-align: justify; font-size: 0.9rem; line-height: 1.5rem;}

 }
