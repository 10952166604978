.modal {display: flex !important; justify-content: center; align-items: center;}   

.CustomPermissionModal {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 700px !important;
    width: 700px !important;

    .modal-content {
        background-color: var(--color-theme-light);
        border-radius: 1rem;
        padding: 1rem;  
    }

    .modal-header {border-bottom: none; color:var(--color-text-white)}
    .modal-title {font-weight: 500; font-size: var(--fs-22);}
    .form-control-textarea {height: 100px; resize: none;}
    .close {text-shadow: none; margin: 0; padding: 0; border-radius: 30px; font-size: var(--fs-16); color:var(--color-text-white); background: var(--color-theme); width: 30px; height: 30px;}

    .note-list {margin: 0px 0 30px 15px; font-weight: 100; padding: 0px; font-size: var(--fs-13); color:rgba(255, 255, 255, 0.5)}

    .form-col {background-color: var( --color-off-light); border-radius: 2rem; padding: 1rem;}
    .title-label {font-weight: normal; color: rgba(255, 255, 255, 0.5);}
    .custom-switch {padding-left: 0;}
    .custom-control {min-height: auto; top:-4px; z-index: 1!important;}
    .custom-control-label {margin-right: 1rem; cursor: pointer;}
    .custom-control-label::before {background-color: var(--toggle-swicth-color);}
    .custom-switch .custom-control-label::before {width: 3rem; height: 1.5rem; border-radius: 1rem; border:none; }
    .custom-switch .custom-control-input:checked~.custom-control-label::after{ transform: translateX(1.35rem);}
    .custom-control-input:checked~.custom-control-label::before {background-color: #1CC98C;}
    .custom-switch .custom-control-label::after {    
        top: calc(.25rem + 3px);
        left: calc(-2.25rem + 3px);
        width: calc(1.40rem - 4px);
        height: calc(1.40rem - 4px);
        border-radius: 50% 50%;
    }
}

// @media (min-width: 600px) {
//     .CustomPermissionModal {max-width: 1000px !important; }

// }



