.set--inverse {
  background: #393E4A;
  border: 1px solid #464F60;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 46px;
  color: rgba(255, 255, 255, 0.32);
}
p.response.error {
  color: #ff2828;
  font-size: 13px;
  margin: 10px;
  text-align: center;
}
.custom--card {
  color: #fff;
  
  .card-body-inner{
    display: flex;
    .card-heading{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 16px;
  
      .card-title{
        font-size: 1.8rem;
        margin-bottom: 0;
      }
    }
  }
 
  
  .table {
    color: #fff;
    tr {
      color: #fff;
      td {
        color: #fff;
      }
    }
  }
  label{
    opacity: 0.5;
    font-size: 14px;
    color: #fff;
  }
  // .form-control{
  //   background: #393e4a;
  //   border-color: #464f60;
  //   color: #FAFAFA;
  // }
}

.wconfirmation_dilog_bx1{
  padding-right: 0 !important;
  color: #fff;
    .modal-dialog{
      max-width: 400px;

    .modal-header{
      padding: 0 0 1rem;

      .close{
        &:hover{
         color: whitesmoke; 
        }
      }
    }
    
    .modal-content {
      background: #1e202d;
      border-radius: 30px;
      padding: 25px;    

      .cnfmrtn_msg {
        color: #fff;
        display: inline-block;
        width: 100%;
        margin-bottom: 30px;
        font-size: 22px;
        text-align: center;
        font-weight: 100;
      }
    }
  }
}

.usr-pagination.text-right {
  display: flex;
  justify-content: end;
  margin-right: 10px;
  .current.pagination {
    background: #ba567d;
    cursor: context-menu;    
  }
  .pagination {
    display: flex;    
    padding: 3px 10px;
    background: #ce165e;
    color: #fff;
    list-style: none;
    border-radius: 3px;
    margin-left: 8px;
    cursor: pointer;
  }
}
.host_list_dilog_bx1.wconfirmation_dilog_bx1{
  .modal-dialog{
    max-width: 767px;
    width: 100%;
      .card-heading {
        display: inline-block;
        margin-right: 25px;
        margin-bottom: 15px;
    }
    .table-dark{
      background-color: unset;
    }
  }
  
}

.usermanagment-topbanner {
  color: #fff;
  background: #282C35 !important;
  word-wrap: break-word;
  border-radius: 10px;
  padding: 10px 15px;
  h4 {
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #9d9d9d;
  }
  span.showlistmanagement {
    background: #1f222b !important;
    display: inline-block;
    padding: 5px 10px;
    margin-left: 20px;
    border-radius: 4px;
    font-size: 14px;
}
.btn-buynow {
  border-radius: 9px;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: none !important;
    font-size: 15px;
}
ul {
  padding: 0px;
  list-style: none;
  li {
    padding: 5px 2px;
    color: #9d9d9d;
  }
}
.imagepurchase-plan {
  text-align: center;
  img {
    width: 100%;
    max-width: 250px;
  }
}
}

.usermanagmentlist_main {
  color: #fff;
  nav.nav-tabs {
    border-bottom: 0px none;
    a {
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      margin: 0px 6px 0px 0px;
      padding: 0px 16px 0px 16px;
      min-height: 40px;
      line-height: 40px;
    }
    .nav-link {
      background: rgba(255, 255, 255, 0.21);
      border-radius: 3px 3px 0px 0px; 
    }
    .nav-link.active {
      background: #5057F0;
      border-radius: 3px 3px 0px 0px;
      border: 0px none;
      color: #fff;
    }
  }
  .tab-content {
    border: 1px solid rgba(255, 255, 255, 0.21);
    padding: 16px;
    .tableheadinglist {
      display: flex;
      justify-content: space-between;
      .tablelistfilter {
        display: flex;
        .dropdown {
          margin-right: 8px;
          button.btn.btn-primary {
            background: #282C35;
            border-radius: 6px;
            border: 1px solid #282C35;
            min-width: 200px;
            text-align: left;
            position: relative;
            justify-content: space-between;
            display: flex;
            align-items: center;
            min-height: 40px;
            padding: 0px 14px;
            font-size: 15px;
          }
        }
      }
    }
    .rightsideadduser {
      button.btn.btn-secondary {
        background-color: #ce165e;
        border: 1px solid #ce165e;
      }
    }
  }
  
  Table {
    color: #fff;
    background: #282C35;
    border-radius: 6px;
    thead {
      th {
        border-bottom: 1px solid rgba(255, 255, 255, 0.21);
        padding: 20px 10px;
        font-weight: 400;
        color: #808BA3;
        font-size: 16px;
        line-height: 19px;
      }
    }
    tbody {
      tr {
        td {
          font-size: 15px;
          line-height: 18px;
          color: #9FA6B6;
          padding: 15px 10px;
          .manageactive {
            background: rgba(76, 175, 80, 0.38);
            border: 1px solid #4CAF50;
            border-radius: 20px;
            padding: 4px 10px;
          }
          .manageInactive {
            border-radius: 20px;
            padding: 4px 10px;
            background: rgba(244, 67, 54, 0.38);
            border: 1px solid #F44336;
          }
          .managepending {
            background: #774b1f;
            border: 1px solid #fa8039;
            border-radius: 20px;
            padding: 4px 10px;
          }
          .editicon-list {
            color: #FFC107;
            margin-right: 10px;
          }
          .linkicon-list {
            color: #DC3545;
          }
        }
      }
    }
  }
}

.addusermodel {
  color: #fff;
  .modal-content {
    background: #282C35;
    border-radius: 32px;
    min-width: 800px;
    .modal-header {
      border: 0px none;
      padding: 30px 30px 0px 30px;
      button.close {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50px;
        width: 36px;
        height: 36px;
        padding: 0px;
        font-weight: 300;
        line-height: 36px;
        margin: 0px;
        span {
          display: inline-block;
            position: relative;
            top: -3px;
        }
      }
      button.close:hover {
        color: whitesmoke;
    }
    }
    .modal-body {
      padding: 15px 30px 20px 30px;
      p.response.error {
        color: #ff2828;
        font-size: 13px;
        margin: 0px;
      }
      p.response.success{
        color: #fed500;
        font-size: 13px;
        margin-left: 177px !important;
        margin: 0px;
      }
      .form-check.form-check-inline {
        margin-right: 30px;
      }
      label {
        font-size: 14px;
        line-height: 16px;
      }
      .form-check.form-check-inline {
        margin-right: 30px;
      }
      .form-check-inline .form-check-input {
        background: #00000000;
        border: 1px solid rgba(255, 255, 255, 0.21);
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      textarea.form-control {
        background: #393E4A;
        border: 1px solid #464F60;
        box-sizing: border-box;
        border-radius: 6px;
        resize: none;
        color: rgba(255, 255, 255, 0.32);
      }
      input.form-control {
        background: #393E4A;
        border: 1px solid #464F60;
        box-sizing: border-box;
        border-radius: 6px;
        min-height: 46px;
        color: rgba(255, 255, 255, 0.32);
      }
      input.form-control:focus {
          background-color: transparent;
          color: rgba(255, 255, 255, 0.32);
      }
      button.btn-pink {
        background: #E11361;
        border: 1px solid #E11361;
      }
      button.btn {
          border-radius: 6px;
          font-size: 14px;
          line-height: 16px;
          color: #FFFFFF;
          height: 40px;
          padding: 0px 16px;
      }
      button.btn.btnadduser {
        margin-top: 20px;
        margin-bottom: 15px;
      }
      .blockclass {
        display: block;
      }
    }
  }
}

.hostinvitationMain {
  background-image: linear-gradient(to right bottom, #fdfdff, #eeeef7, #dde0ee, #ccd2e6, #bac5de);
    padding: 40px;
    height: 100vh;
    .hostinvitationMain-inner {
      background-color: white !important;
      border-radius: 15px;
      box-shadow: 0px 3px 17px 0px #e2e1e1;
      margin: 0 auto;
      width: 90%;
      padding-left: 48px;
      padding-right: 48px;
      .rightsidesignup {
        img {
          width: 100%;
        }
      }
      .logomaindiv {
        text-align: center;
        position: relative;
        img {
          width: 375px;
          padding-top: 1.5rem!important;
        }
        .backbutton {
          margin-top: 0px !important;
          position: absolute;
          left: 6px;
          background-color: transparent;
          border: 0px none;
          padding: 0px;
          top: 36px;
          i {
            font-size: 20px;
            color: #251665;
          }
        }
      }

      .SignupLeftside {
        margin-top: 1rem!important;
        .thanksyouinvite {
          text-align: center;
          margin-top: 35%;
          h1 {
            color: #251665;
            font-weight: 700!important;
            line-height: 50px;
            font-size: 60px;
          }
          span {
            color: #f04b55;
            font-size: 22px;
            display: block;
          }
          p {
            color: #58d8c5;
            font-size: 18px;
            margin-top: 40px;
            border-bottom: 1px solid #66f1d6;
            padding-bottom: 5px;
            display: inline-block;
          }
        }
        .signupwithgooglemain {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
          margin-top: 30px;
          .signupwithgoogle .btn {
            border-radius: 2px;
            border: 1px solid transparent;
            font-size: 14px;
            font-weight: 500;
            min-height: 43px;
            padding: 0px 10px 0px 0px;
            background-color: rgb(66, 133, 244);
            // box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
            span {
              margin-right: 10px;
              background: white;
              padding: 0px;
              border-radius: 2px;
              min-height: 41px;
              display: inline-block;
              line-height: 41px;
              min-width: 38px;
            }
            img {
              width: 18px;
            }
          }
          .signupwith {
            color: #f04b55;
            position: relative;
            padding-left: 75px;
          }
            .signupwith:before {
              position: absolute;
              content: "";
              left: 0px;
              width: 58px;
              height: 1px;
              background-color: #f04b55;
              top: 11px;
          }
        }
        .headingText {
          color: #251665;
        }
        .signupformMain {
          label {
            margin-left: 1rem!important;
            font-size: 14px;
          }
          .signup-btn {
            background-color: #f04b55;
            border-color: #f04b55;
            box-shadow: 0px 5px 12px 0px #f5a7ab;
            margin-top: 30px;
            padding-left: 1.5rem!important;
            padding-right: 1.5rem!important;
          }
          .react-tel-input {
            .form-control {
              width: 100%;
            }
          }
        }
      }
    }
}