@import '../../stylesheets/form.scss';
@import '../../stylesheets/all.scss';
.contentContainer {
display: flex;
flex-direction: row;

}
.companyBranding-Content {
@include breakpoints(largescreen) {
        max-width: 1100px;
        
    }

}
.form-control-textarea {height: 100px !important;}
.form-label-info {font-size: var(--fs-13); color:rgba(255, 255, 255, 0.5); font-weight: 100;}
.contact-info-section {
    border:1px solid #292D41; 
    border-radius: 0.7rem; 
    padding: 18px 22px ; 
    position: relative;
    .col-sm-4 {padding-left: 8px; padding-right: 8px;}
    .title-label {font-size: var(--fs-13); font-weight: 400;}
    .form-legend-title { color:var(--color-text-white); background: var(--color-theme);   
        font-size: var(--fs-14);  text-align: left;  font-weight: 500;
    }      
    
}

.title-md {
    font-weight: 500;
    font-size: var(--fs-20);
    letter-spacing: -1px;
    color:var(--color-text-white);
    margin-bottom: 1.5rem;
}

.comp-form-col {
    background: var(--color-theme-light);
    padding: 0.6rem 1rem;
    border-radius: 0.5rem;

}

/*------------------Custom file css start here -------------------------------*/
.custom_file_control [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }
  .custom_file_control {margin-bottom: 10px;}
  
  .custom_file_control [type="file"] + label {
    background-color: var(--color-theme-light); 
    border: 1px solid var(--color-theme-light);
    width: 100%;
    height: 7.5rem;
    border-radius: 0.5rem;
    color: #5B5E6E;
    cursor: pointer;
    display: inline-block;
    font-family: 'Roboto', sans-serif!important;
    font-size: var(--fs-20);
    font-weight: 500;
    margin-bottom: 0rem;
    outline: none;
    padding: 1.6rem 50px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
    text-align: center;    
    }
    .custom_file_control i {font-size: 30px;}
   .custom_file_control [type="file"] + label:hover {
     border: 1px  solid var(--color-primary);
     color:var(--color-primary);
   }
  
  .custom_file_control .btn-upload {
  background-color: #f0f8ff21; 
  border-color: #fff3;
  border-radius: 50px;
  overflow: hidden;
  }
  
   .custom_file_control .btn-upload:hover {}
   .custom_file_control .btn-upload:hover::before {right: 75%;}
   
  /*------------------Custom file css end here -------------------------------*/
.form-datepicker {
    .react-datepicker-wrapper {width: 80%;}
    .react-datepicker__input-container {
        input {
            background: none;
             border:none;
             font-size: var(--fs-14);
            color:var(--color-text-white);
            height: 35px;

            &:focus {outline: none; border:none; box-shadow: none;}
            
        }
    }

    .icon-date {color:rgba(255, 255, 255, 0.5); font-size: var(--fs-13); position: absolute; right: 20px; bottom: 20px;  }
    .css-yk16xz-control, .css-1pahdxg-control {background: none; border:none; box-shadow: none;}
    .css-1uccc91-singleValue { color:#6c757d; font-size: var(--fs-14);}
    .css-g1d714-ValueContainer {padding: 0px;}
    .css-1okebmr-indicatorSeparator {display: none;}
    .css-6q0nyr-Svg {width: 18px;} 
}

.btn-add-more {font-size: var(--fs-13); border-radius: 30px; float: right;}
.uploaded-img {
    margin-bottom: 1rem;
    img {max-height: 100px; max-width: 250px;}
    }