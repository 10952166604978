
.viewRecordingModal iframe {border:none;}
.viewRecordingModal .btn-close {
    background: #5057f0;
    border:none;
    color: #FFF;
    font-size: 12px; 
    text-align: center;
    min-width: auto; 
    position: absolute;
    right: 10px;
    top:10px; 
    width: 30px; 
    height: 30px;
}

.dialogSection{
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.viewRecordingRow {
  justify-content: center; padding-top: 15px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin-top: 0;
}

.iframeSection {
  width: 100%;
  height: 96%;
}

.viewRecordingModal .recording-col {
    /* background-color: #272938; */
    border-radius: 15px;
    color:#FFF;
    padding: 15px;
    margin-bottom: 30px;

}

.viewRecordingModal .recording-col .icon {
    position: relative;
    width: 50px;
    margin: 0 auto;
}

.viewRecordingModal .recording-col .icon .dot { width: 10px; height: 10px; margin: 0 auto; position: absolute; top:17px;  left: 12px; background: #2a9742; border-radius: 50% 50%;}

.viewRecordingModal .recording-col .icon-video {
    color: #42465e;
    font-size: 45px;

}

.viewRecordingModal .recording-col .title-name { margin-top: 10px; font-size: 14px; margin-bottom: 15px;}

.viewRecordingModal .recording-col .btn-view {background: #2a9742; min-width: 70px; color:#FFF; font-size: 13px;  border-radius: 30px;}