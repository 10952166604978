.leftScrollbar {overflow-y: scroll; height: 88vh;}

.leftScrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .leftScrollbar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
  }
  
  .leftScrollbar::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 20px;           
  }
.searchPanel {background: var(--color-theme);  padding: 0.5rem 0.8rem; border-radius: 30px;}
.search-formControl {background: none; border:none; width: 82.5%; color:var(--color-text-white); font-size: var(--font-size-md); font-weight: 100;
    &:focus {outline: none;}

}

@-moz-document url-prefix() { 
    .search-formControl {width: 82%;}
  }

.btnSearch {background: none; border-radius: 50% 50%; width: 2.5rem; height: 2.5rem;  border:none; background: var(--color-theme-light);
    &:hover {background: var(--color-primary);}
}

.listSection {
    margin-top: 1rem;
    .title {
        color:var(--color-text-white);
        font-size: var(--fs-18);
        margin-bottom: 0.5rem;
        span { width: 1.9rem; height: 1.9rem; margin-right: 0.7rem; display: inline-block; text-align: center; border-radius: 0.45rem; }
    }

    .listContent {
        list-style: none; 
        margin: 0px; 
        padding: 0px;

        li {
            padding: 0.3rem 0;
            a {
                width: 100%;
                display: block;
                color:rgba(255, 255, 255, 0.6);
                font-size: var(--fs-14);
                font-weight: 100;
                text-decoration: none;
                span {margin-right: 1rem;}

                &:hover {color:var(--color-text-white);}
            }

            .dotcirle {  display: inline-block; width: 0.50rem; height: 0.50rem;}
            .green {background: #1CC98C;}
            .yellow {background: #FBC036;}
            .grey {background: #1CC98C;}
            .icon-mute {opacity: 0.6;}

            .count {float: right; color:var(--color-text-white); font-weight: 400; font-size: var(--fs-12); background: var(--color-primary); padding: 2px 10px; border-radius: 20px; }
        }
    }

    .bg-naviblue {background: #49D6BF;}
    .bg-orange {background: #F98D3C;}
    .bg-red {background: #FB3F4B;}
    .bg-red {background: #FB3F4B;}
    .bg-perpal {background: #5057F0;}
}