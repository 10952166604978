
// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus, 
// input:-webkit-autofill:active  {
// transition: background-color 5000s;
// -webkit-text-fill-color: #fff !important;
// background-color: var(--color-form-col-bg) !important;
// }
.form-col {
background: var(--color-form-col-bg); 
padding: 0.6rem 1rem;
border-radius: 0.5rem;

.form-control {
    background: none; 
    border:none; 
    padding: 0rem;  
    font-size: var(--fs-14);
    color:var(--color-text-white);
    
    &:focus {
        outline: none; 
        box-shadow: none;
        background: none; 
        color:var(--color-text-white);
        }
    
        &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active, &:-internal-autofill-selected  {
            transition: background-color 5000s;
            background-color: var(--color-form-col-bg) !important;
            outline: none; 
            box-shadow: none;
            -webkit-text-fill-color: var(--color-text-white) !important;
             
        }
    }

}

.title-label {
font-size: var(--fs-14); 
margin-bottom: 0;
font-weight: 500;
color:var(--color-text-white);
}



.custom-select {
    background-color:var(--color-form-col-bg);
    font-family: 'Roboto', sans-serif!important; 
    border:none; 
    font-size: var(--fs-14);
    color:rgba(255, 255, 255, 0.5);
    font-weight: 100;
    padding: 0rem;
    // background: #fff url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e) right .75rem center/8px 10px no-repeat;
    // background: var(--color-form-col-bg) url(img/arrow-down.svg) right center no-repeat ;

    option  {
        background: var(--color-theme-light); 
        
    }

    &:focus {outline: none; box-shadow: none; background: none; color:var(--color-text-white);}
}

.custom-control-label {color:rgba(255, 255, 255, 0.5); font-weight: 100; font-size: 13px;}

.btn-theme-primary { 
    background: var(--color-primary);
    border:none; 
    min-width: 8rem; 
    border-radius: 2rem; 
    height: 3rem;

    &:hover, &.active, &:focus, &:not(:disabled):not(.disabled):active {
        box-shadow: none !important;
        background-color: var(--color-primary-hov) ;
        border-color:var(--color-primary-hov);
    }


}

.btn-theme { 
    background: var(--color-primary);
    font-size: var(--fs-15);
    border:none; 
    &:hover, &.active, &:focus, &:not(:disabled):not(.disabled):active {
        box-shadow: none !important;
        background-color: var(--color-primary-hov) ;
        border-color:var(--color-primary-hov);
    }

}

.btn-off-dark { 
    background: var(--color--off-dark);
    border:none; 
    min-width: 8rem; 
    border-radius: 2rem; 
    height: 3rem;

    &:hover, &.active, &:focus, &:not(:disabled):not(.disabled):active {
        box-shadow: none !important;
        background-color: var(--color-theme-light) ;
        border-color:var(--color-theme-light);
    }


}
.multiselect-container {

    .search-wrapper {border:none;}
    .chip  {background: var(--color-primary); border-radius: 20px;}
    .searchBox {width: 100%; color:var(--color-text-white); font-size: var(--fs-14);}
    .optionListContainer  {background: var(--color-form-col-bg); color:var(--color-text-white); font-size: var(--fs-14); box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
    .highlight  {background: var(--color-primary);}
    .optionContainer {border:none;
    
        li {
            &:hover {background: var(--color-primary);}
        }
    }
    
}

.pageContentpanel {
    .error {font-size: 14px; margin-top: -10px; margin-bottom: 10px; }
}

.form-custom-checkbox{
    .custom-checkbox {margin-top: 10px;}
    .custom-control-label::before {background-color: var(--color-off-light); border-color:var(--color-off-light) ;}
    .custom-control-input:checked~.custom-control-label::before {background-color: var(--color-primary); border-color:var(--color-primary) ;}
}

.form-group {

    .react-tel-input {
        border:none; 
        background: none !important;
        .flag-dropdown.open  {
            background: var(--color-theme-light) !important;
            .selected-flag {
                background: var(--color-theme-light) !important;
                &:hover {background: var(--color-theme-light) !important;}
            }
        }
        .flag-dropdown {
            background: none !important;
            &:hover {background: var(--color-theme-light) !important;}

            .selected-flag {
               
                &:hover {background: var(--color-theme-light) !important;}
                }
        
                .country-list {
                    background: var(--color-form-col-bg); 
                        .search {background: var(--color-form-col-bg); }
                        .search-emoji {display: none;}
                        .search-box {background: var(--color-theme-light); color: var(--color-text-white); width: 95%; border-radius: 5px; padding: 8px 10px; border-color: rgba(255, 255, 255, 0.1); }
                        .no-entries-message {
                        span {color:rgba(255, 255, 255, 0.5); font-weight: 100;} 
                    }
                    .country-name {color: var(--color-text-white); font-size: var(--fs-14);}
                    .country{
                        &:hover{background: var(--color-theme-light) !important;}
                    }

                    .highlight {background: var(--color-theme-light) !important;}
                }
        }

        
    }
}