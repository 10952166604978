:root {
    --font-size-base: 1rem;
    --font-size-large: 1.25rem;
    --font-size-md: 0.95rem; 
    --font-size-small: 0.875rem;
    --font-size-smaller: .75rem;
    --font-size-xs: .575rem;
    --font-size-smallest: .35rem;
    
    --line-height-base: 1.25; // 20/16
    --line-height-computed: 1rem;
  
    --headings-font-family: inherit;
    --headings-font-weight: 500;
    --headings-line-height: 1.1;
    --headings-color: inherit;
  
    --font-weight-thin: 300;

    --fs-10: 0.625rem;
    --fs-11: 0.688rem;
    --fs-12: 0.75rem;
    --fs-13: 0.813rem;
    --fs-14: 0.875rem;
    --fs-15: 0.938rem;
    --fs-16: 1rem;
    --fs-18: 1.125rem;
    --fs-20: 1.25rem;
    --fs-22: 1.375rem;
    --fs-24: 1.5rem;
    --fs-30: 1.875rem;
    --fs-35: 2.188rem;


  }
  
