@import '../stylesheets/form.scss';
@import '../stylesheets/all.scss';

.companyInfo-Container {
    position: fixed;
    z-index: 999 !important;
    margin-top: -560px;
    width: 100vw;
    height: 80vh;
    background: var(--color-theme-light);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:#FFF;


        .title {
        font-weight: 500;
        font-size: var(--fs-30);
        letter-spacing: -1px;
        }

        .sub-title {
        color:rgba(255, 255, 255, 0.5);
        font-size: var(--fs-15);
        font-weight: var(--font-weight-thin);
        }

        .img-rgt-flex {display: flex; justify-content: center; align-self: center; align-items: center;}
        .btn-close {position: absolute; right: 2rem; top:1rem; background: none; display: flex; width: 2rem; height: 2rem; justify-content: center; align-items: center;  border-radius: 50% 50%; background: var(--color-theme); font-size: var(--fs-12); border:none; color:var(--color-text-white); }

}