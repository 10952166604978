@import '../../../stylesheets/table.scss';

.DepartmentList {
    display       : flex;
    flex-direction: row;
}

.department-list-content {
    width: 100%;

    .primary-action {
        font-size    : var(--fs-13);
        padding      : .40rem .7rem;
        border-radius: .3rem;
        font-weight  : var(--font-weight-md);
    }

    .btn-action-filter {
        background-color: var(--color-theme-light);
        border          : none;
        margin-right    : 0.6rem;

        i {
            opacity: 0.5;
        }

        &:hover,
        &.active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
            box-shadow      : none !important;
            background-color: var(--color--off-dark);
            border-color    : none;
        }

    }

}

.team-popover {
    .popover {
        min-width: 450px;
    }
}

.tagname {
    background   : var(--color-theme-light);
    color        : var(--color-text-light);
    font-size    : var(--fs-12);
    margin       : 0 5px 5px 0;
    display      : inline-block;
    border-radius: 30px;
    padding      : 0.2rem 0.5rem;

    }

.layout-table {
    background   : #0f1019;
    border-radius: 1rem;
    width        : 100%;
    margin-top   : 2rem;
}

.filter-popover {
    .popover {
        min-width: 450px;
    }

    .filter-row {
        margin-right: 0;
        margin-top  : 0.4rem;
    }

    .filter-box {
        padding-right: 0;
    }

    .form-control-filter {
        background   : var(--color-theme-light);
        border       : 1px solid var(--color-theme-light);
        font-size    : var(--fs-13);
        margin-bottom: 15px;
        padding      : 1rem;

        &:focus {
            outline     : none;
            box-shadow  : none;
            background  : none;
            border-color: rgba(255, 255, 255, 0.1);
            color       : var(--color-text-white);
        }

    }

    .btn-filter {
        border-radius: 30px;
        min-width    : 80px;
        font-size    : var(--fs-13);
    }

    .btn-clear {
        background  : var(--color-theme-light);
        border-color: var(--color--off-dark);

        &:hover,
        &.active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
            box-shadow      : none !important;
            background-color: var(--color-theme-light);
            border-color    : none;
        }

    }

}