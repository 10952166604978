.meetingBaseInfo{
  .detailsSection {
    width: 90%;
    align-self: center;
    border-radius: 21px;
    padding: 22px;
  }
  .editBtn{
    border: 0;
    width: 104px;
    color: white;
  }
  .mh75{
    max-height: 72px;
  }
  label{
    font-size: 14px;
    color: #88929b !important;
  }
  .url{
    background: #00000026;
    padding: 15px;
    border-radius: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 70%;
    width: 70%;
  }
  .btnCss{
    margin: 5px;
    border-radius: 10px;
    box-shadow: none;
    padding: 10px 11px;
    min-width: 113px !important;
    width: 150px;
  }
  .divider{
    border-right: 1px solid #4d4d4d70;
  }
  .w375{
    width: 375px;
  }
}

.participants{
  th{
    border: 0px;  
  }
  .br12{
    border-radius: 12px;
  }
}

.formContainerAddParticipants{
  border-radius: 10px;
  button.btnBgLess.activeType {
    color: #ce165e;
    border-bottom: 1px solid;
  }
}

.Options{
  border-radius: 10px;
  padding: 10px 18px;
  .ImagesSection{
    width: auto;
    max-height: 97px;
    margin-top: 10px;
    opacity: 0.7;
  }
  .cardSection {
    border-radius: 12px;
    padding: 15px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .containerCard {
    flex: 0 0 33%;
    max-width: 33%;
    position: relative;
  }
  .tagContainer{
    width: 270px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #8080806b;
    height: 157px;
  }
  .cardOptions{
    display: grid !important;
    grid-template-columns: repeat(3 ,1fr);
    row-gap: 12px;
  }
}

@media screen and (max-width: 992px){
  .cardOptions{
    display: grid !important;
    grid-template-columns: repeat(2 ,2fr) !important;
    column-gap: 5px;
    row-gap: 12px;
  }
  .cardWidth-100{
    width: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  .overViewCvBtn{
    display: block !important;
    text-align: center;
  }
  .resScreenDes{
    display: grid !important;
    grid-template-columns: repeat(2 ,2fr);
    row-gap: 12px;
  }
  .cardOptions{
    display: grid !important;
    grid-template-columns: repeat(1 ,1fr) !important;
    row-gap: 12px;
  }
  .cardWidth-100{
    width: 100% !important;
  }
  .meetingTagSection{
    text-align: center;
  }
  .justifyCenter{
    justify-content: center;
  }
  .meetingButtonSection{
    display: flex !important;
  }
  .btnFont{
    font-size: 13px;
  }
  .urlSectionWithBtn{
    display: block !important;
  }
  .urlInputCopy{
    width: 100% !important;
  }
  .urlShareBtns{
    width: 96% !important;
    margin-bottom: 4px !important;
  }
}


.switch-check .form-check-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.switch-check .form-check-label:after, .switch-check .form-check-label:before {
  position: absolute;
  top: 0.25rem;
  left: -2.25rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}
.switch-check .form-check-label:after {
  top: calc(0.25rem + 2px);
  left: calc(0rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
}

.switch-check .form-check-label:before {
  left: 0rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.switch-check .form-check-label:before {
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.switch-check .form-check-label:before {
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.switch-check .form-check-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.switch-check .form-check-input:checked~.form-check-label:before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.switch-check .form-check-input:checked~.form-check-label:after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.switch-check .form-check-input {
  margin-left: 0;
}
.form-switch {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 0rem;
}
.pl-35 {
  padding-left: 35px;
}
.switch-check .form-switch {
  margin-left: 0.5rem!important;
  margin-right: 0.5rem!important;
}
.switch-check.options {
  right: 0px;
}
.switch-check.options .form-switch {
  margin-left: 0px!important;
  margin-right: 0px!important;
}
.switch-check.options .form-check-label:before {
  right: 0px;
  left: auto;
}
.switch-check.options .form-check-input {
  right: 0px;
  left: auto;
  width: 28px;
  cursor: pointer;
}
.switch-check.options .form-check-label:after {
  right: calc(0rem + 2px);
  left: -25px;
}
.switch-check .form-check-label {
  cursor: pointer;
}