
.containerReport{
  .react-datepicker-wrapper{
    width: 100% !important;
  }

  th{
    border:0ch;
  }
}

.filterReports .react-datepicker-wrapper{
  width: 100% !important;
}

.labelOpacity{
  opacity: 0.5;
}

.notAllowed{
  cursor: not-allowed
}


@media only screen and (max-width: 767px) {
  .typeBtn{
    text-align: center;
  }

  .filterReports{
    text-align: center;
justify-content: center !important;
margin-top: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .reportsNote{
    text-align: center;
  }
  .reportsNote button {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 435px) {
  .filterReports{
    display: block !important;
    text-align: center;
  }

  .typeBtn .meetingBTn{
    margin-right: 0 !important;
  }
}

