.txt_theme {
  color: #483d8bd9;
}

.divya-header {
  box-shadow: 0 0 5px #ccc;
  background: #fff;
  position: relative;
  z-index: 100;
  padding: 0px 0px;
  position: fixed;
  top: 0;
}
.divya-header .icon-theme {
  position: absolute;
  top: 8px;
  right: 15px;
}
.divya-header .logo-right {
  text-align: right;
}
.divya-header-row {
  align-items: center;
}
.divya-header .icon-theme {
  text-align: right;
  color: #221e29;
}
.divya-header .icon-theme a {
  color: #484350;
}
.divya_login_container {
  display: flex;
  background: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}
.divya_login_container .loginbg {
  width: 50%;
  height: calc(100vh - 45px);
  top: 45px;
  background: url('../../../public/divya/login/main-img.png') no-repeat;
  position: absolute;
  left: 0px;
  background-position: center center;
  background-size: cover;
}
/* .divya_login_container .login_form_sec{display: block; height: auto; width: 460px; } */
.divya_login_container .lgn_frm_bx {
  box-shadow: none;
  background: #fff;
  border-radius: 12px;
  padding: 0;
  width: 100%;
  margin: auto;
}
.divya_login_container .lgn_logo {
  text-align: center;
  margin-bottom: 0.5rem;
}
.divya_login_container .lgn_logo:focus {
  outline: none;
}
.divya_login_container .lgn_logo img {
  width: 140px;
  margin-left: -10px;
}
.divya_login_container .login-container {
  min-height: 100vh;
  position: relative;
  justify-content: flex-end;
  align-items: center;
}

.footer-bot-link {
  width: 100%;
  position: absolute;
  color: #333;
  font-size: 13px;
  bottom: 15px;
  right: 0;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  padding-top: 15px;
}
.footer-bot-link a {
  color: #333;
  text-decoration: none;
}
.divya_login_container .w_login_heading {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}
.divya_login_container .w_login_heading h4 {
  color: #007bff;
  font-weight: 500;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
}
.divya_login_container .w_login_heading p {
  font-size: 1rem;
  font-weight: 400;
  color: #555;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
}

.divya_login_container ._wform_group {
  margin-bottom: 1.5rem; /*background: hsla(216, 33%, 97%, 1);  border-radius: 30px;  border: 1px solid hsla(216, 33%, 97%, 1);*/
  padding: 0px 5px;
}
.divya_login_container ._wform_group_prepend {
  background: #fff;
  border-radius: 5px;
  border-color: #ccc;
}
._wform_group_prepend {
  margin-right: -1px;
  display: flex;
}
._wform_group_prepend i {
  font-size: 16px;
  color: #666;
  margin-top: 4px;
}

.divya_login_container ._wform_group label {
  font-size: 13px;
  color: #666;
  margin: 0px 0 10px 13px;
  padding: 0px;
}

.divya_login_container ._wform_group label span {
  color: #cb2a2a;
  font-weight: 600;
}
._wform__group_text {
  text-align: center;
  display: inline-block;
  min-width: 48px;
  border-radius: 0px;
  border: 0px;
  padding: 0px 0px 0px 5px;
}
._wform_form_cntrl {
  border: none;
  padding-left: 0.25rem;
  border-radius: 0px;
  background: transparent;
  box-shadow: none;
  padding: 0px 10px 0px 5px;
  height: auto;
  display: block;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0px;
  position: relative;
  font-size: 14px;
}

._wform_form_cntrl:focus,
._wform_form_cntrl:active {
  box-shadow: none;
  outline: none;
}

.divya_login_container ._grp_rw {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-top: 0.8rem;
}
.divya_login_container .ftr_tag_log {
  color: #6c757d;
}
.divya_login_container .ftr_tag_log a {
  color: #007bff;
}
.divya_login_container .extrnal_link span .btn-link {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}
.extrnal_link span {
  display: inline-block;
  width: 45%;
  padding: 0px 10px;
}
.extrnal_link span a {
  font-size: 14px;
  color: #666;
}
.extrnal_link span .btn-link {
  font-size: 14px;
  color: #007bff;
}
.extrnal_link span .btn-link:hover,
.extrnal_link span .btn-link:focus {
  box-shadow: none !important;
}

.extrnal_link span:last-child {
  float: right;
  text-align: right;
}

.divya_login_container .login-left-img img {
  max-width: 80%;
}

.divya_login_container .btn-join-meeting {
  font-size: 17px;
  height: 55px;
  width: 48%;
  margin-left: 15px;
}

.divya_login_container .btn-login {
  height: 55px;
  font-size: 17px;
  width: 48%;
}
.divya_login_container .submisn_btn {
  width: 150px;
  display: inline-block;
  border: 1px solid rgba(66 133 244);
  height: 45px;
  color: rgba(66 133 244);
  border-radius: 30px;
  font-size: 15px;
}
.divya_login_container .submisn_btn:hover {
  background: rgba(66 133 244);
  color: #fff;
}
.divya_login_container .or-text {
  width: 45px;
  height: 45px;
  border-radius: 50% 50%;
  -webkit-border-radius: 50% 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5e5e5;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  font-size: 13px;
  margin: 0 15px;
}
.divya_login_container .btn-google-signin {
  display: inline-block;
  border-radius: 30px;
}
.divya_login_container .btn-google-signin button {
  height: 45px;
  box-shadow: none !important;
}
.divya_login_container .btn-google-signin button {
  border-radius: 30px !important;
  padding: 5px !important;
}
.divya_login_container .btn-google-signin button div {
  border-radius: 50% 50% !important;
  width: 31px;
  height: 31px;
  padding: 4px !important;
}
.divya_login_container .btn-google-signin button span {
  font-weight: 400 !important;
}

.ftr_tag_log {
  text-align: center;
  font-size: 14px;
}

.error_msg_56 {
  font-size: 12px;
  width: 100%;
  margin-top: -5px;
  margin-bottom: 10px;
  padding-left: 17px;
  color: #eb6737;
}

._wform_group.has-error {
  border: solid 1px #eb6737;
}

.react-code-input {
  display: inline-flex !important;
}
.react-code-input input {
  font-size: 14px !important;
  height: 20px !important;
  width: 40px !important;
  margin-top: 0px !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  border-bottom: solid 1px #00000040 !important;
  border-radius: 0px !important;
  font-family: inherit !important;
  text-align: center !important;
  padding-left: 0px !important;
}

.footerLogin {
  width: 100%;
}

@media all and (min-width: 1200px) {
  .footerLogin {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

/* @media all and (min-width: 1300px){
   
    .divya_login_container ._wform_group {margin-bottom: 1rem;}
    .divya_login_container ._grp_rw {margin-bottom: 1rem;}

 } */
@media all and (max-width: 1100px) {
  .divya_login_container .btn-login,
  .divya_login_container .btn-join-meeting {
    width: 47%;
    font-size: 15px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .lgn_frm_bx {
    margin: 20px auto;
  }
  .react-code-input input {
    height: 20px !important;
    width: 12% !important;
  }
  .extrnal_link span {
    width: 50% !important;
    padding: 0px;
  }
  .extrnal_link span.resend_otp_timer {
    width: 100% !important;
  }
  .divya_login_container ._wform_group {
    width: 100%;
    margin-right: 0px;
  }
  .footerLogin {
    position: relative;
  }
  .divya_login_container .loginbg {
    display: none;
  }
  .divya_login_container .login-container {
    min-height: 100vh;
  }
  .footer-bot-link {
    width: 100%;
  }
  .divya-header-row img {
    width: 70px;
  }
  .divya-header .icon-theme {
    display: none;
  }
  .divya_login_container .login-left-img {
    display: none;
  }
  .divya_login_container .btn-login,
  .divya_login_container .btn-join-meeting {
    width: 47%;
    font-size: 15px;
  }
}

@media all and (min-width: 320px) and (max-width: 600px) {
  .divya_login_container .login_form_sec {
    width: 90%;
    margin: 0 auto;
  }
  .divya_login_container .or-text {
    margin: 0 5px;
  }
}

@media all and (min-width: 320px) and (max-width: 499px) {
  .divya_login_container .lgn_logo img {
    width: 240px;
  }
  .lgn_frm_bx {
    padding: 1.5rem;
  }
  .divya_login_container .w_login_heading h4 {
    font-size: 2rem;
  }
  .w_login_heading p {
    font-size: 13px;
    line-height: 22px;
  }
  ._wform_group {
    padding: 8px 5px;
  }
  .extrnal_link span .btn-link {
    font-size: 12px;
  }
  .divya_login_container .submisn_btn,
  .divya_login_container .btn-google-signin {
    width: 100%;
  }
  .divya_login_container .btn-google-signin button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .divya_login_container .or-text {
    margin: 15px 0px;
  }
  .divya_login_container .lgn_logo img {
    width: 160px;
  }
}
.g-btn-custom {
  border: 0px;
  padding: 0px;
  position: relative;
}
.g-btn-custom button {
  background-color: rgb(66, 133, 244)!important;
  color: rgb(255, 255, 255)!important;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px!important;
  font-size: 14px!important;
  font-weight: 500!important;
  border-radius: 2px!important;
  border: 0px;
  padding: 12px 14px 12px 54px;
}
.g-btn-custom:before {
  position: absolute;
  content: "";
  background: url(./images/g-logo.svg) no-repeat center center;
  background-color: #fff;
  width: 18px;
  height: 18px;
  top: 5px;
  left: 5px;
  padding: 18px;
  border-radius: 4px;
}