.logo-section li {position: relative;}

.logo-section li .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -150px;
    transform: translate(90px, -100px);
    background-color: #000;
    color: #bababa;
    padding: 0.5rem;
    width: auto;
    font-size: 12px;
    border-radius: 10px;
    text-align: center;
  }
.logo-section li .popover__title {color: #FFF; font-size: 14px; margin-bottom: 5px;}
.logo-section li .popover__content  p {margin-bottom: 10px; }
  .logo-section li .popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    bottom: -8px;
    border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  
  border-top: 10px solid #000;
    transition-duration: 0.3s;
    transition-property: transform;
  }
  .logo-section li:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(90px, -120px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }

  