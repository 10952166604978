.table-list {
    color      : var(--color-text-light);
    font-size  : var(--fs-15);
    font-weight: normal;

    thead {
        th {
            border-top    : none;
            vertical-align: middle;
            font-size     : var(--fs-14);
            color         : rgba(255, 255, 255, 0.4);
            font-weight   : var(--font-weight-semi);
            border-bottom : 1px solid #171825;
        }
    }

    tr {
        &:hover {
            background: var(--color--off-dark);
        }
    }

    td {
        border-bottom: none;
        font-size    : var(--fs-14);
        border-top   : 1px solid #171825;


    }

    .check-col {
        width: 30px;
    }

    .th-10 {
        width: 15%;
    }

    .nowrap {
        white-space: nowrap;
    }

    .btn-round {
        border-radius: 30px;
        font-size    : var(--fs-12);
    }

    .btn-edit {
        background-color: var(--color-primary);
        border-color    : var(--color-primary);
        min-width       : 60px;

        i {
            font-size   : var(--fs-10);
            transform   : rotate(-90deg);
            margin-right: 3px;
        }

    }

    .txt-para {
        font-size: var(--fs-12);
    }

}