.NsModal {max-width: 1100px; margin: 2rem auto 0rem auto;}
.NsModal .modal-body {padding:20px}
.NsModal .modal-content {background:#221e29 ;}
.NsModal .ns-rgt-content .txt-title {font-size: 28px; color:rgba(255, 255, 255, 0.6); font-weight: 100;}
.NsModal .ns-rgt-content  {padding-top: 60px;}
.NsModal .ns-rgt-content h2 {color:#FFF; font-size: 23px; font-weight: 100; font-family: 'Comfortaa', cursive; padding: 20px 0px;}
.NsModal .ns-rgt-content h2 .colorYellow {color: #f8d661;}
.NsModal .ns-rgt-content h2 .colornavyblue {color: #2eb99f;}
.NsModal .ns-rgt-content .btn-continue{background:none; border:none; font-size: 17px; color: #eb1b6d;}
.NsModal .ns-rgt-content .btn-continue i {margin-left: 5px; font-size: 16px; margin-top: 5px;}
.NsModal .ns-rgt-content .txt-or { width: 40px; height: 40px; background:#1d1923; display: inline-block; color:rgba(255, 255, 255, 0.6); border-radius: 50% 50%; font-size: 14px; margin: 0 20px; padding-top: 9px; vertical-align: middle; }
.NsModal .ns-rgt-content .btn-download {background:#eb1b6d ; color: #FFF; }
.NsModal .ns-rgt-content .btn-download:hover, .NsModal .ns-rgt-content .btn-download:focus {color: #FFF; background:#f62376 ;} 
.NsModal .ns-rgt-content .btn-continue:hover, .NsModal .ns-rgt-content .btn-continue:focus, .NsModal .ns-rgt-content .btn-txt-download:hover, .NsModal .ns-rgt-content .btn-txt-download:focus {border:none; outline:none; }
.NsModal .ns-rgt-content .btn-txt-download {background:none; border:none; text-decoration: underline;}
.NsModal .circle-list li { width: 100px; height: auto; display: inline-block; vertical-align: middle;    color:rgba(255, 255, 255, 0.6); font-size: 13px;  margin: 15px 15px;}
.NsModal .circle-list li .circle {background: #1d1923; width: 100px; height: 100px;  border-radius: 50% 50%; text-align: center; vertical-align: middle; margin-bottom: 10px; }
.NsModal .circle-list li .circle img {margin-top: 25px;}
.NsModal .bottom-title {font-size: 20px; color:rgba(255, 255, 255, 0.6); font-weight: 100;}

@media only scrren and (max-width:992px) {
.NsModal .ns-rgt-content {padding-top: 20px;}
.NsModal {max-width: 100%; margin: 2rem .5rem .5rem .5rem;}
   
}

@media only screen and (max-width:767px) {
    .NsModal {max-width: 100%; margin: 2rem .5rem .5rem .5rem;}
}

@media only screen and (max-width:480px) {
    .nsaddon-img {width: 250px;} 
    .NsModal {max-width: 100%; margin:2rem .5rem .5rem .5rem;}
    .NsModal .modal-body {padding:15px 10px;}
    .NsModal .ns-rgt-content {padding-left: 0px; padding-right: 0px;}
    .NsModal .ns-rgt-content .txt-title {font-size: 22px;}
    .NsModal .ns-rgt-content {padding-top: 0px;}
    .NsModal .ns-rgt-content h2 {font-size: 18px; line-height: 30px;}
    .NsModal .ns-rgt-content .txt-or { margin: 0 0px; width: 35px; height: 35px; font-size: 12px; padding-top: 8px;}
    .NsModal .ns-rgt-content .btn-continue i {font-size: 13px;}
    .NsModal .ns-rgt-content .btn-continue {font-size: 14px;}
    .NsModal .bottom-title {font-size: 16px; margin-top: 20px; margin-bottom: 20px; line-height: 24px;}
    .NsModal .circle-list {margin:0px; list-style: none; padding: 0px;}
    .NsModal .circle-list li { margin: 5px; height: auto; width: 75px;}
    .NsModal .circle-list li .circle {width: 75px; height:75px;}
    .NsModal .circle-list li .circle img {width: 40px;}
    .NsModal .circle-list li:nth-child(2) .circle img {margin-top: 21px !important;}
    .NsModal .circle-list li:nth-child(5) .circle img  {margin-top: 32px !important;}
    .NsModal .circle-list li:nth-child(6) .circle img  {width: 30px !important;}
    .NsModal .circle-list li:nth-child(7) .circle img  {margin-top: 22px !important; width: 32px !important;}
    .NsModal .ns-rgt-content .col-md-12 {padding-left: 0px; padding-right: 0px;}
    .NsModal .modal-body .padlr-0 {padding-left: 5px; padding-right: 5px;}
    .NsModal .ns-rgt-content .btn-download {font-size: 13px;}

 }