.modal {display: flex !important; justify-content: center; align-items: center;}   

.CustomModal {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 700px;

    .modal-content {
        background-color: var(--color-theme-light);
        border-radius: 1rem;
        padding: 1rem;  
    }

    .modal-header {border-bottom: none; color:var(--color-text-white)}
    .modal-title {font-weight: 500; font-size: var(--fs-22);}
    .form-control-textarea {height: 100px; resize: none;}
    .close {text-shadow: none; margin: 0; padding: 0; border-radius: 30px; font-size: var(--fs-16); color:var(--color-text-white); background: var(--color-theme); width: 30px; height: 30px;}

    .note-list {margin: 0px 0 30px 15px; font-weight: 100; padding: 0px; font-size: var(--fs-13); color:rgba(255, 255, 255, 0.5)}
}



