.layoutContainer {
  width: 100%;
  // height: 100vh;
}

.displayNone {
  transition: 0.3s all;
  display: none;
  visibility: hidden;
}

.ml256 {
  margin-left: 95px;
  transition: 0.3s all;
}

.transition5 {
  transition: 0.5s all;
}
.w40 {
  width: 40px !important;
  transition: 0.3s all;
}

.w70 {
  width: 70px !important;
  transition: 0.3s all;
}

.mt53 {
  margin-top: 53px !important;
  transition: 0.3s all;
}

.ls1 {
  letter-spacing: -1px;
}

.ml100 {
  margin-left: 100px !important;
  transition: 0.3s all;
}

.ml9 {
  margin-left: 9px !important;
  transition: 0.3s all;
}

.ml55 {
  margin-left: 55px !important;
  transition: 0.3s all;
}

.sideContainer {
  width: 90px;
  text-align: center;
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 1;
  transition: 0.3s all;
  border-right: 1px solid #EBEBEB;

  .btnIcon {
    padding: 5px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 7px;
  }

  .btnIcon svg {
    font-size: 18px;
  }

  .mt94 {
    margin-top: 94px !important;
  }

  .bottomSection {
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 40px;
    display: flex;
    width: 235px;
    background: #fff;
    border-top-right-radius: 10px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    padding-top: 2px;
  }

  .transition {
    transition: 0.3s all;
    position: absolute;
    width: 70%;
    left: 68px;
    top: 14px;
    text-align: left;
  }

  .h100 {
    height: 100px !important;
  }

  .toggle {
    width: 100%;
    display: flex;
    transition: 0.3s all;
    font-size: 14px;
    margin-top: 19px;
    margin-bottom: 6px;
  }

  .toggleBtn {
    background: transparent;
    border: 1px solid #3b4150;
    border-radius: 8px;
    padding: 5px 10px;
  }

  .dropdown-item {
    margin: 8px 0px;
  }

  // .dropdown-item:hover {
    // background-color: #ce165e !important;
  // }
  a.welcomeMsg.dropdown-item:hover a {
    color: #ce165e !important;
  }

}
.logo-btn {
  min-height: 88px;
  border-bottom: 1px solid #EBEBEB;
}

.btnInnerCenter {
  display: flex;
  align-items: baseline;
  margin: 10px auto !important;
  width: 93%;
  padding: 7px 5px;
  outline: none !important;
}

.sideBarBtnContainer {
  padding: 10px 0;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #EBEBEB;
}

.activeSection {
  background: #ce165e;
}

.headerContainer {
  width: 97%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.childrenContainer {
  padding: 0 10px;
}
.rgtHeaderBtn {
  border-radius: 9px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: none !important;
  font-size: 15px;
  color: #fff;
}
button.actionCell {
  border: 0px;
  width: 30px;
  height: 30px;
  margin: 1px;
  display: inline-block;
}

.optionDropDown{
  border-radius: 8px;
}


.profileImg{
  display: inline-block;
  width: 35px;
  height: 35px;
  color: #fff;
  background: #ce165e;
  border-radius: 10px;
  text-align: center;
  line-height: 35px;
  margin-right: 10px;
}
.activeSectionToggle{
  color: #ce165e !important;
}

.w-100Auto256{   width: 100% !important;}/****Removerd calc(100% - 100px)***/

.w-100Auto100{   width: calc(100% - 100px) !important;}

.headerTextLimit{
  white-space: nowrap;
  width: 530px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#react-aria9228263230-4 {
  padding-right: 0px;
}
.thumb-btn {
  padding: 20px 30px;
}
.thumb-btn-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.thumb-btn-icon {
  background: #797979;
  border-radius: 30px;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
.thumb-btn-icon svg {
  height: 50px;
}
.thumb-btn-box {
  width: 320px;
}
.dashobard-clock {
  padding-bottom: 24px;
}
.thumb-btn-heading {
  padding-top: 6px;
  display: block;
  color: #6C757D;
  font-size: 14px;
  font-weight: 500;
}
.thumb-btn-icon.green {
  background: #029930;
}
.meet-title {
  font-size: 20px;
  font-weight: 700;
}
.meetings-right {
  padding: 30px 40px 40px;
  border-start-start-radius: 20px;
  height: 714px;
}
.sidebar-nav-link {
  display: inline-block;
  width: 76px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
}
.copyright .welcomeMsg {
  padding-left: 3px;
}
/*****media query starts from here *******/

@media only screen and (max-width: 1400px) {
  .sideContainer .scrollbar-container {
    position: relative;
    height: 70vh;
  }
}

@media only screen and (max-width: 1200px) {
  .sideContainer .scrollbar-container {
    position: relative;
    height: 60vh;
  }
}


@media screen  and (max-width: 410px) {
  .dropDownRightSection{
    display: block;
  }
}

.w-0{
  width: 0px !important;
}
@media (max-width: 400px){
  .headerContainer{
    width: 100% !important;
  }
   .dropDownRightSection {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
  .nameDropDown {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    right: 15px;
  }
  .dropDownPart.dropdown {
    position: relative;
    float: right;
    z-index: 999;
    overflow: inherit;
  }

}
@media only screen and (max-width: 390px) {
  .nameDropDown {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    right: 10px;
  }
}

@media only screen and (min-width: 992px) {
  // .ml256 {
  //   height: 100vh;
  // }
  // .meetings-right {
  //   height: 100vh;
  // }
  .pt-100-lg {
    padding-top: 100px;
  }
}