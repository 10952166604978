.Popup {
  max-width: 600px;
  margin: 5rem auto 0rem auto;
}
.Popup .modal-body {
  padding: 40px;
}
.Popup .txt-msg {
  font-size: 1.8rem;
  letter-spacing: -1px;
  color: #fff;
  text-align: left;
  margin-bottom: 15px;
  text-align: center;
}
.Popup .btn {
  min-width: 150px;
  font-size: 1rem !important;
  height: 60px;
  border-radius: 1rem !important;
}
.Popup .submitSubColor {
  border-color: #06caa5;
}
.br22Popup {
  border-radius: 22px;
}

.sub_msg_detl89 {
  font-size: 15px;
  font-weight: 100;
  display: block;
  margin-bottom: 15px;
  color: #ffffff85;
}

.form_actn_btn69 {
  display: block;
  width: 100%;
  margin-top: 30px;
}

._jform_group {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 1rem;
}
/* ._jform_group .form-control::placeholder {
  font-size: 1rem;
  line-height: 10px;
} */
._jform_group .form-control {
  border-radius: 0px;
  background: transparent;
  border: none;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0.8rem;
  padding: 1rem;
  height: 4rem;
  /* border-bottom: solid 1px #ffffff2b; */
  color: #fff;
  font-size: 1rem;
  font-weight: 100;
}
._jform_group .form-control:focus {
  font-size: 1.3rem;
}
._jform_group .form-control:hover,
._jform_group .form-control:active,
._jform_group .form-control:focus {
  box-shadow: none;
}

.j_error_msg {
  font-size: 12px;
  width: 100%;
  margin-top: 5px;
  color: #eb6737;
  padding-left: 13px;
}

@media only screen and (max-width: 480px) {
  .Popup {
    max-width: 100%;
    margin: 10rem 0.5rem 0.5rem 0.5rem;
  }
}
