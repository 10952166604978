@import '../../../stylesheets/form.scss';

.createDepart-container {
    display       : flex;
    flex-direction: row;
}

.createDepart-FormSection {
    flex: 1 1;
}

.createDepart-container .form-control-textarea {
    height: 100px !important;
}

.circle-add {
    width          : 25px;
    height         : 25px;
    padding-top    : 4px;
    margin-right   : 10px;
    font-size      : var(--fs-12);
    display        : inline-block;
    justify-content: center;
    text-align     : center;
}

.img-section {
    display        : flex;
    align-self     : center;
    justify-content: center;
}