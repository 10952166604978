.register_lgn_frm_bx ._wform_group ._wform_group_prepend{
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  min-height: 40px;
  line-height: 40px;
}
.register_lgn_frm_bx  ._wform_group {
  margin-bottom: 1rem;
  padding: 8px 5px;
}
.register_lgn_frm_bx   ._wform_form_cntrl{
  padding: 0px 10px 0px 10px;
}
.error_msg_host_register{
    font-size: 12px;
    color: #f00;
    font-weight: 500;
}