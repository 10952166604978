.treetable {
  table {
    tr th {
      font-weight: 500;
      color: #fff;
      font-size: 1rem;
    }

    tr td {
      font-weight: normal !important;
      color: #a4a9b4;
      font-size: 0.85rem;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .headingWhiteText {
      color: #fff;
      font-weight: 500;
    }
    .btn,
    .badge {
      color: #fff !important;
      font-weight: normal;
      font-size: 0.8rem;
      padding: 0.3rem 0.5rem;
    }
    .btnBgLess {
      background: transparent;
      border: 0;
      outline: none;
    }
  }
}
