@import '../stylesheets/all.scss';
.talkContainer {display: flex;  flex-direction: row; flex: 1;}
@mixin panelbar {
    position: relative;
    top: 52px;
    background: var(--color-theme-light);
    height: calc(100vh - 52px);
}
.talkContainer .rightPanelSection {
    @include panelbar; 
    width:400px; 
    border-radius: 1.9rem 0 0 1.9rem; 
    @include breakpoints(largescreen) {
        width:550px; 
    } 
}