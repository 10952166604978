.settingContainer{
  width: 90%;
  align-self: center;
  border-radius: 21px;
  padding: 22px;

  .logo{
    margin-bottom: 60px;
  }

  .uploadBtn{
    background: white;
    border-radius: 21px;
    padding: 5px 15px;
    margin: auto;
    margin-bottom: 15px;
  }

  .uploadImg{
    width: 280px;
    height: 250px;
    border-radius: 16px;
    text-align: center;
    display: grid;
    align-content: space-between;
    justify-content: center;
  }

}
.deleteBtnIcon{
  font-size: 25px;
  position: absolute;
  right: 5px;
  top: 4px;
  z-index: 99;
}

.imgFavicon{
  width: 200px;
  margin: auto;
  position: relative;
  top: 10px;
  padding: 20px;
  max-height: 200px;
}


.error{
border: 2px solid red;
}

.deleteBtn{
  margin-top: 38px;
  padding: 11px !important;
}
.deleteBtnWiHi{
  width: 50px;
  height: 50px;
}

.center{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 720px){
  .fontNewMargin{
  margin: 5px 10px !important;
}
.uploadImg{
  width: 250px !important;
}
}
@media (max-width: 600px){    
  .fontNewMargin{
  margin: 5px 0px !important;
}
.uploadImagesSection{
  flex-direction: column !important;
}
}
@media (max-width: 324px){
  .uploadImg{
    width: 250px !important;
  }
}