.webinar_bg{background: #1a1820}
.webinar_stream_sec { width: 100%;  padding-right: 400px;}
.webinar_banner_sec{display: inline-block; width: 100%; /*background: #000;*/ padding: 15px; border-radius: 5px; margin-top: 8px;}
 .webinar_title{ color: #EE905D; font-size: 18px; font-family: 'Comfortaa', cursive; font-weight: bold; display: inline-block;
   vertical-align: super; border-left: solid 1px #423E49; padding-left: 15px; }

.webinar_Container{ width: 100%; position: fixed;  padding-bottom: 50px; right: 0px; top: 52px; height: calc(100vh - 52px ); 
   } 
.webinar_Container .tab_section{ display: inline-block; width: 100%; padding: 0px; border-bottom: solid 1px #dddddd12;}

.webinar_right_part{ width: 400px; position: fixed;  padding-bottom: 50px; right: 0px; top: 52px; height: calc(100vh - 52px ); background: #221e29;
  border-left: solid 1px #dddddd12; } 
.webinar_right_part .tab_section{ display: inline-block; width: 100%; padding: 0px; border-bottom: solid 1px #dddddd12;}
.tab_section .nav-tabs{border: none;}

.tab_section .nav-tabs{border: none;}
.tab_section .nav-tabs li a{padding: 5px 7px; border: none; background: none; border-radius: 0px; color: #ffffffc7; font-size: 12px; cursor: pointer;}
.tab_section .nav-tabs li a i{display: block; text-align: center; margin-bottom: 5px; margin-top: 5px; font-size: 18px;}
.tab_section .nav-tabs li a:hover{color: #fff;}
.tab_section .nav-tabs li a.active{ background: none; color: #ffae14 }

.webinar_tab_content {margin: 15px 0px; padding: 0px 15px;}
.webinar_tab_content h3{ font-size: 18px; font-weight: 400; color: #fff; margin: 0px; padding: 0px; text-align: left; }
.text_d_white{color: #ffffffa3}
.host_list{ display: inline-block; width: 100%; margin-top: 5px; max-height: 70vh; overflow: auto; }
.host_list ul{list-style: none; margin: 0px; padding: 0px;}
.host_list ul li{display: inline-block; width: 100%; padding: 10px 10px; background: #f2f2f20f; border-radius: 4px; margin-bottom: 15px;}
.host_list ul li .host_image{display: inline-block; width: 60px; vertical-align: top}
.host_list ul li .host_image img{width: 48px; height: 48px; border-radius: 5px;}

.host_list ul li .host_detail{ display: inline-block; width: 260px; vertical-align: top; }
.host_list ul li .host_name{ font-size: 16px; color: #FFF; margin-bottom:5px; }
.host_list ul li .host_position{ font-size: 13px; color: rgba(255, 255, 255, 0.7) !important; line-height: normal; text-align: left;  font-weight: 300; margin-bottom: 0px;}
.host_list ul li .host_description{ font-size: 12px; color: #ffffffa3; line-height: 18px; text-align: left; font-weight: 400; margin-top: 7px; margin-bottom: 0px; width: 100%;}

.attendee_search_bx{ display: inline-block; width: 100%; background: #ffffff1f; padding: 4px 10px; border-radius: 5px; }
.attendee_search_bx input[type=text] {background: none; border: none; width: 80%; color: #fff; font-size: 13px;}
.attendee_search_bx input[type=text]:hover {border: none; background: no-repeat; outline: none; box-shadow: none;}
.attendee_search_bx .btn{padding: 0px; float: right; font-size: 13px;}

.attendee_list{display: inline-block; margin-top: 10px; max-height: 75vh; overflow: auto; width: 100%; min-height: 400px;}
.attendee_list ul{list-style: none; margin: 0px;padding: 0px;}
.attendee_list ul li{display: inline-block; width: 60px; float: left; margin: 5px;}
.attendee_list ul li .attende_outer{display: inline-block; width: 100%; height: 60px; background: #ffffff40; border-radius: 5px; position: relative;}
.attendee_list ul li .attende_outer .attendee_name{display: block; background: #000000ab; color: #fff; position: absolute; bottom: 0px; left: 0px; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 12px; padding: 2px; border-radius: 0px 0px 5px 5px;}
.attendee_list ul li .attende_outer .attendee_action_btn{ position: absolute; right: 5px; top: 0px;}
.attendee_list ul li .attende_outer .attendee_action_btn .btn-primary{padding: 0px; background: none; border: none; color: #fff; font-size: 12px;}
.attendee_list ul li .attende_outer .attendee_action_btn .btn-primary:hover, .attendee_action_btn .btn-primary:focus{box-shadow: none;}
.attendee_list ul li .attende_outer .attendee_action_btn .dropdown-menu{ background: #282330 }
.attendee_list ul li .attende_outer .attendee_action_btn .dropdown-menu .dropdown-item{ color: #fff; font-size: 13px; }
.attendee_list ul li .attende_outer .attendee_action_btn .dropdown-menu .dropdown-item:hover, .attendee_list ul li .attende_outer .attendee_action_btn .dropdown-menu .dropdown-item:focus{background: none; color: #fff;}

.attendee_list ul li .attende_outer .sb-avatar__text div span {vertical-align: top!important; padding-top: 14px; font-size: 18px;}

.attendee_list ul li.user-inactive .attende_outer .sb-avatar__text{background: rgb(45, 46, 46) none repeat scroll 0% 0%!important}


.chat_sec{display: inline-block; width: 100%;}
.chat_sec ul{list-style: none; margin: 0px; padding: 0px; max-height: 70vh; overflow: auto;}
.chat_sec ul li{display: inline-block; width: 100%; margin-bottom: 0px;}
.chat_sec ul li .sender_detail{display: block; font-size: 12px; color: #aaa; font-weight: 500;}
.chat_sec ul li .sender_detail span{display: inline-block;  }
.chat_sec ul li .sender_detail span img{width: 25px; height: 25px; border-radius: 50%;}
.chat_sec ul li .chat_text{ background: #2E2A35; padding: 15px; border-radius: 10px; margin-top: 10px; position: relative;
 font-size: 13px; color: #f8eeee}
.chat_sec ul li .chat_text:after { position: absolute; left: 25px; top: -8px; content: ""; display: inline-block; width: 0; 
 height: 0;  border-style: solid; border-width: 0 10px 10px 10px; border-color: transparent transparent #2E2A35 transparent;
}

.chat_sec .chat_type_area{ position: absolute; bottom: 0px; left: 0px; width: 100%; padding: 0px;}
.chat_sec .chat_type_bx{background: #151723; border-top:solid 1px #dddddd12; padding: 10px; width: 100%; display: inline-block;}
.chat_sec .chat_type_area .btn-link{padding:0px 4px; color: #46434d; cursor: pointer;}
.chat_sec .chat_type_area .btn-link:hover, .chat_sec .chat_type_area .btn-link:focus{box-shadow: none;}
.chat_sec .type_input{ width: 90%; border: none; outline: none; font-size: 13px; background: transparent; color: #f2f2f2; }

.question_list{display: inline-block; width: 100%;}
.question_list ul{list-style: none; margin: 0px; padding: 0px; max-height: 70vh; overflow: auto;}
.question_list ul li{display: inline-block; width: 99%; margin-bottom: 25px;}

.question_list .chat_type_area{ position: absolute; bottom: 0px; left: 0px; width: 100%; padding: 0px;}
.question_list .chat_type_bx{background: #151723; border-top:solid 1px #dddddd12; padding: 10px; width: 100%; display: inline-block;}
.question_list .chat_type_area .btn-link{padding:0px 4px; color: #46434d; cursor: pointer;}
.question_list .type_input{ width: 90%; border: none; outline: none; font-size: 13px; background: transparent; color: #f2f2f2; }

.question_time{width: 100%; font-size: 12px;}
.question_block{ display: inline-block; width: 100%; padding: 10px 25px 10px 10px; margin-top: 5px; border-radius: 5px; background: #2E2A35; color: #eda721; font-size: 14px; position: relative;}
.question_action_block{display: inline-block; position: absolute; right: 5px; top: 5px; }
.question_action_block .btn-primary{color: #ffffffd1; background: none; border:none; outline: none;}
.question_action_block .btn-primary:focus, .question_action_block .btn-primary:hover{outline: none; box-shadow: none!important; background: none!important;}

.question_action_block .dropdown-menu{ background: #282330 }
.question_action_block .dropdown-menu .dropdown-item{ color: #fff; font-size: 13px; }
.question_action_block .dropdown-menu .dropdown-item:hover, 
.question_action_block .dropdown-menu .dropdown-item:focus{background: none; color: #fff; outline: none;}

.question_block .answer_text{ color: #fff; line-height: normal; font-size: 12px; display: block; margin-top: 5px; }
.question_block .answer_text_bx{ display: block; margin-top: 5px; }
.question_block .answer_text_bx .form-control{background: #ffffff17; color: #fff; font-size: 13px; border-color: #dfd2d221}
.question_block .answer_text_bx .btn{padding: .10rem .5rem}

.quesion_status_block{width: 100%; display: block; margin-top: 5px;}


 .webinar_waiting_text{font-family: 'Comfortaa', cursive; color: #fbad18; font-weight: bold; font-size: 22px; line-height: 35px; text-align: center;}
 .webinar_waiting_banner img{width: 400px;}

 .webinar_mod_waiting_banner img{width: 500px;}
 .webinar_mod_waiting_text{ color: #5f5b6a;  font-size: 25px; font-weight: 300; line-height: 40px; text-align: center;}
 .webinar_mod_waiting_text .txt-perpal {color: #795bfd; font-weight: 300; font-size: 28px;}
 .webinar_mod_waiting_text .txt-yellow {color: #37a094; font-weight: 300;}
 
 .hand_rise_sec{border-left: solid 1px #dddddd12}
 .hand_rise_sec .hand_rise_btn{width: 100%; height: 100%; background: none; border: none; outline: none; font-size: 20px; color: #ffffff6b;}
 .hand_rise_sec .hand_rise_btn.active{color: green}

.attendeehand_rise {
  position: absolute; left: -5px; top: -5px;  background: #fff;color: #000; display: inline-block;  width: 17px;height: 17px;text-align: center;
  font-size: 12px;border-radius: 50%; line-height: 17px;}

 .block_message{color: #fff; font-size: 13px;}
 .no_data_found_webinar{color: #d0982f; padding: 5px 15px; border-radius: 5px; background:#ffffff12 ; font-size: 14px; text-align: center; margin-top: 50px;}

 .webinar_header{padding: 8px 15px;}
 .webinar_header .s_wave_logo{width: 220px; background-size: 200px; height: 30px; background-position: 0px 6px;}
 
 .webinar_user_detail_p{ color: rgba(255, 255, 255, 0.8) ; font-size: 14px; }
 .webinar_user_type{font-size: 12px; margin-left: 5px; color: #fff}

.webinar_detail_mobile_view{display: none;}


 .webinar_video_box{display: inline-block; width: 100%; height: 500px; background: #312C3B; border-radius: 5px ; position: relative;}
 .webinar_video_box_thumbnail{display: inline-block; width: 100%; height: 200px; background: #312C3B; border-radius: 5px; position: relative; }
 .webinar_thumbnail_list{list-style: none; margin: 0px; padding: 0px; max-height: calc(100vh - 100px); overflow-x: auto;}
 .webinar_thumbnail_list li{display: inline-block; width: 100%; margin-bottom: 5px;}
 
  .webinar_video_box .person_name{position: absolute; bottom: 0px; left: 0px; color: #fff; padding: 5px 10px; background: #000; font-size: 12px;}
  .webinar_video_box .person_type{position: absolute; top: 0px; right: 0px; color: #fff; padding: 5px 20px; background: #ffae14; font-size: 12px;}

  .webinar_video_box_thumbnail .person_name{position: absolute; bottom: 0px; left: 0px; color: #fff; padding: 5px 10px; background: #000; font-size: 12px;}
  .webinar_video_box_thumbnail .person_type{position: absolute; top: 0px; right: 0px; color: #fff; padding: 5px 20px; background: #ffae14; font-size: 12px;}
 
 .webinar_video_box video { /*pointer-events: none;*/ padding: 0px; }



.webinar_video_grid { display: grid; grid-template-columns: repeat(4, 1fr); grid-auto-rows: 180px;  grid-gap: 5px;}
.webinar_video_grid  .grid_bx {border: 2px solid #C8458D;   background-color: #1f1c28;  padding: 0em;  color: #a1a1af; position: relative;}
/*.webinar_video_grid .active {  grid-column: 1 / 3;  grid-row: 1 / 3; border:solid 3px #fcb43d!important;}
.webinar_video_grid .grid_bx.active:nth-child(2n), 
.webinar_video_grid .grid_bx.active:nth-child(3n), 
.webinar_video_grid .grid_bx.active:nth-child(4n), 
.webinar_video_grid .grid_bx.active:nth-child(5n), 
.webinar_video_grid .grid_bx.active:nth-child(6n), 
.webinar_video_grid .grid_bx.active:nth-child(7n){ grid-column: inherit;  grid-row: inherit;} */
.webinar_video_grid .grid_bx video{width: 100%; pointer-events: none;}

 
.video_user_name{position: absolute; left: 0px; bottom: 0px; background: #000000de; padding: 5px 10px; color: #fff; font-size: 12px;}
.video_user_name span{margin-left: 5px; color: #fbad18; float: right;}
.video_user_type{position: absolute; right: 0px; top: 0px; padding: 2px 5px; font-size: 11px; color: #fff;  background: #ce165e; border-radius: 0px 0px 5px 5px;}


.video-grid-container {display: grid; align-items: center; justify-content: center; grid-gap: 5px; grid-auto-flow: dense; grid-template-rows: repeat(1, 1fr);}
.video-grid-container .grid_bx {border: 2px solid #C8458D;   background-color: #1f1c28;  padding: 0em;  color: #a1a1af; position: relative;}
.video-grid-container .grid_bx video{width: 100%; pointer-events: none;}

.webinar_video_grid  .grid_bx:nth-child(2n){ border-color: darkcyan; }
.webinar_video_grid  .grid_bx:nth-child(3n){ border-color: mediumorchid; }
.webinar_video_grid  .grid_bx:nth-child(4n){ border-color: #2A9458; }
.webinar_video_grid  .grid_bx:nth-child(5n){ border-color: darkorange; }
.webinar_video_grid  .grid_bx:nth-child(6n){ border-color: firebrick; }
.webinar_video_grid  .grid_bx:nth-child(7n){ border-color: lightseagreen; }
.webinar_video_grid  .grid_bx:nth-child(8n){ border-color: #96bb7c; }
.webinar_video_grid  .grid_bx:nth-child(9n){ border-color: #d3de32; }
.webinar_join_btn {background: #f33c5e; border-color:#f33c5e; color: #FFF;}

/*------------Join Webinar Modal start here-------------------*/
.join_webinar_modal { margin: 8rem auto;}
.join_webinar_modal .modal-content {background:#1e202d !important; box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);}
.join_webinar_modal .join_modal-title {text-align: center; color: #FFF; font-size: 25px; margin-bottom: 20px; font-weight: 300;}
.join_webinar_modal .join_modal-title .icon {width: 80px; height: 80px; margin-bottom: 10px; padding: 18px; display: inline-block; background: #161824; color: #fff; font-size: 30px; border-radius: 50% 50%;}
.join_webinar_modal .modal-text {opacity: 0.8; font-weight: 300;}

.join_webinar_modal .btn-pink {background: #5057f0; margin-top: 15px; color: #FFF; padding: 8px 20px; border-radius: 30px;}
.join_webinar_modal .btn-pink:hover {background:#4850e6 ;}

.tab_section-inner .nav-tabs{border: none;}
.tab_section-inner .nav-tabs li a{padding: 7px 7px; border: 1px solid transparent; border-radius: 5px; box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5); background: rgba(0, 0, 0, 0.2); margin: 0 7px 20px 7px;  color: #ffffffc7; font-size: 14px; cursor: pointer;}
.tab_section-inner .nav-tabs li a i{ text-align: center; margin-bottom: 5px; margin-top: 5px; font-size: 18px;}
.tab_section-inner .nav-tabs li a:hover{color: #fff;}
.tab_section-inner .nav-tabs li a.active, .tab_section-inner .nav-tabs li a:hover{ background: rgba(0, 0, 0, 0.2); color: #26ae97; font-weight: 400; border:1px solid #26ae97; }

/*-------------Webinar New css start here----------------------*/
.padlr-5{padding-left: 5px; padding-right: 5px;}
.webinar_col {background:#1e202d; border-radius: 10px; height: 85vh; margin-top: 15px; padding: 15px;}
.webinar_col .LoadSpinner {display: flex; justify-content: center; align-items: center; align-self: center; height: 100%;}
.webinar_col .LoadSpinner img {margin-top: -100px;}
.webinar_col h2 {color:#FFF; text-align: center; font-size: 18px; font-weight: 400; text-transform: uppercase; }
.webinar_col h2 i {margin-right: 10px; font-size: 25px;}

.webinar_col .head-speaker {color:#62ecd3;}
.webinar_col .head-attendee {color:#6495ed;}
.webinar_col .head-chat {color:coral;}
.webinar_col .head-question {color:#df4c85;}

.webinar_col .no_data_found_webinar {background: none; color: rgba(255, 255, 255, 0.4); font-size: 25px; display: flex; justify-content: center; height: 85%;  margin-top: 0px; align-items: center; align-self: center;}
.chatContent .no_data_found_webinar { height: 100% !important; }
.speakerContent .host_list ul li .host_detail {width: 77%;}
.chatContent .chat_sec ul {height: 66vh; }
.chatContent .chat_sec .chat_type_area {width: 92%; left: 0px; right: 0px; margin: 0 auto; bottom: 15px;}
.chatContent .chat_sec .chat_type_bx {border-radius: 30px; border-top: none;}
.chatContent .chat_sec .chat_type_bx .btn-link i {color: coral;}

.attendeeContent .attendee_search_bx {background: #1d1923!important; border: 1px solid rgba(255, 255, 255, 0.1  ); margin-top: 10px; padding: 8px 10px; font-size: 16px;}
.attendeeContent  .attendee_search_bx input[type="text"] {font-size: 15px;}

.questionsContent .tab_section-inner .nav-tabs {margin-top: 15px;}
.questionsContent .question_list ul {height: 65vh; }
.questionsContent .tab_section-inner .nav-tabs li a.active, .questionsContent .tab_section-inner .nav-tabs li a:hover {color: #df4c85; border: 1px solid #df4c85;}

/*-----------------------ScrollBar CSS start here--------------------------*/

.scrollbar::-webkit-scrollbar {
  width: 8px;
}

/* Mozilla Browser css start here */
.speakerContent .scrollbar  {
  scrollbar-width: thin;          
  scrollbar-color:#2fb29a #1d1923;
}


.chatContent .scrollbar  {
  scrollbar-width: thin;          
  scrollbar-color:#ff7f50 #1d1923;
}

.attendeeContent .scrollbar  {
  scrollbar-width: thin;          
  scrollbar-color:#6495ed #1d1923;
}

.questionsContent .scrollbar  {
  scrollbar-width: thin;          
  scrollbar-color:#fd488e #1d1923;
}
/* Mozilla Browser css end here */


/* Chrome Safari Browser css start here */
.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}


.scrollbar::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  /* background: rgba(118,59,223,0.8);  */
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
/* .scrollbar::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(118,59,223,0.4);  
} */

.speakerContent .scrollbar::-webkit-scrollbar-thumb { 
  background: rgba(40,157,136,0.8); 
}

.speakerContent .scrollbar::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(40,157,136,0.4); 
}

.attendeeContent .scrollbar::-webkit-scrollbar-thumb { 
  background: rgba(50,117,236,0.8); 
}

.attendeeContent .scrollbar::-webkit-scrollbar-thumb:window-inactive { 
  background: rgba(50,117,236,0.4); 
}

.chatContent .scrollbar::-webkit-scrollbar-thumb { 
  background: rgba(253,109,57,0.8); 
}

.chatContent .scrollbar::-webkit-scrollbar-thumb:window-inactive { 
  background: rgba(253,109,57,0.4); 
}

.questionsContent .scrollbar::-webkit-scrollbar-thumb { 
  background: rgba(253,72,142,0.8); 
}

.questionsContent .scrollbar::-webkit-scrollbar-thumb:window-inactive { 
  background: rgba(253,72,142,0.8); 
}


/* Chrome Browser css start here */
.web-d-none {display: none;}


@media screen and (max-width:1250px) {
.webinar_right_part{width: 350px;}
.webinar_stream_sec{padding-right: 350px;}
.host_list ul li .host_detail{width: 230px;}
.webinar_video_grid {  grid-template-columns: repeat(3, 1fr); }

.webinar_header .s_wave_logo{width: 180px; background-size: 160px;}
}

@media screen and (max-width:990px) {
.webinar_right_part{width: 250px;}
.webinar_stream_sec{padding-right: 250px;}
.host_list ul li .host_detail{width: 100%;}
.webinar_video_grid {  grid-template-columns: repeat(3, 1fr); }

.tab_section .nav-tabs li a font{display: none;}
.question_block{font-size: 12px; line-height: normal;}
.chat_sec ul li .chat_text{padding: 8px;}
.host_list ul li .host_name{font-size: 14px;}
.host_list ul li .host_position{font-size: 12px;}
.chat_sec .type_input{width: 85%}

.webinar_title{border-left:0px; padding-left: 0px;}
.d_none_mob_view{display: none!important;}
.webinar_detail_mobile_view{display: block;}
.web-d-none {display: block;}
.mob-d-none {display: none;}
}

@media screen and (max-width:768px) {
.webinar_header{padding: 3px 10px;}
.webinar_right_part{width: 100%; position: relative; top: inherit;}
.webinar_stream_sec{padding-right: 0px; width: 100%; min-height: auto;}
.tab_section .nav-tabs li a font{display: block;}
.webinar_video_grid {  grid-template-columns: repeat(2, 1fr); grid-auto-rows: 150px;  }
.webinar_video_grid .grid_bx.active {  grid-column: 1 / 2;  grid-row: 1 / 2;}
.chat_sec .type_input{width: 90%}
.webinar_user_detail_p{font-size: 12px;}                                              
.webinar_title{font-size: 16px;}
.webinar_user_type{font-size: 10px;}
.webinar_detail_mobile_view .f_size_14{font-size: 12px!important}
.webinar_mod_waiting_text {font-size: 20px; line-height: 35px; margin-top: 1rem !important;}
.webinar_mod_waiting_text span {font-size: 22px !important;}
.webinar_mod_waiting_banner img {margin-top: 20px;}
.host_list ul li .host_detail {width: 80%;}

}

@media screen and (max-width:450px) {
.webinar_waiting_banner img, .webinar_mod_waiting_banner img{width: 90%;}
.webinar_waiting_text{font-size: 16px; line-height: 27px;}
.webinar_header .s_wave_logo{width: 130px; background-size: 120px; background-position: 0px 10px;}
.webinar_title{font-size: 14px;}
.username_ellipsis{width: 60px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: inline-block; vertical-align: middle; padding-left: 5px;}
.mob-start-webinar {float: none !important; width: 100%; text-align: center; margin-top: 20px; }
.mob-start-webinar button {font-size: 15px !important; border-radius: 30px; padding:7px 20px !important;}
.mob-mt-2 {margin-top:0rem !important;}


}
 
.webinar_video_loader{position: absolute; left:0px; right: 0px; top: 0px; bottom: 0px; margin:  auto;  width: 100px; height: 100px; text-align: center; }
.webinar_video_loader img{width: 100%;}