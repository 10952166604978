.sideBarPanel {
    margin-left : -15px;
    margin-right: -15px;

    @mixin textFormat {
        justify-content: center;
        align-items    : center;
    }

    .sideBar-Profile {
        display       : flex;
        flex-direction: row;
        align-items   : center;
        border-bottom : 1px solid rgba(255, 255, 255, 0.1);
        padding       : 0 15px 15px 15px;

        .user-profile-img {
            width        : 50px;
            height       : 50px;
            border-radius: 0.6rem;
            background   : #CE165E;
            font-size    : var(--fs-22);
            color        : var(--color-text-white);
            display      : flex;
            margin-right : 1rem;
            @include textFormat;

            img {
                border-radius: 0.6rem;
            }
        }

        .user-info {
            color    : #F6DA5C;
            font-size: var(--fs-20);

            span {
                text-transform: uppercase;
                font-size     : var(--fs-15);
                color         : var(--color-text-white);
            }

        }

    }


    .sideNavBar {
        padding: 1rem;

        .nav-item {
            padding-top   : 0.8rem;
            padding-bottom: 0.8rem;

            a {
                font-size  : var(--fs-15);
                font-weight: 400;
                color      : rgba(255, 255, 255, 0.5);

                i {
                    margin-right: 1rem;
                }

                .dropdown-arrow {
                    float    : right;
                    font-size: var(--fs-10);
                }

                &:hover {
                    text-decoration: none;
                    color          : var(--color-text-white);
                }
            }
        }

        .subNavbar {
            padding: 15px 0 0 36px;

            .nav-item {
                padding-top   : 0.2rem;
                padding-bottom: 0.2rem;

                a {
                    font-size: var(--fs-14);

                    i {
                        margin-right: 0.3rem;
                        font-size   : 7px;
                    }
                }
            }

        }
    }



}