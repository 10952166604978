.customModal {
  .modal-content {
    border-radius: 2rem;
    padding: 1.5rem;
    .modal-body {
      padding-top: 0rem;
    }
    .modal-header {
      border-bottom: none;
      padding-bottom: 0;
    }
    .modal-footer {
      border-top: none;
    }
    h4 {
      font-size: 1.8rem;
      letter-spacing: -1px;
    }
    .form-group {
      .form-label,
      label {
        opacity: 0.5;
        font-size: 14px;
        margin-left: 0 !important;
      }
    }
    .form-control {
      // background-color: #1c1f26;
      // border-color: #1c1f26;
      height: calc(1.5em + 1.3rem + 2px);
      border-radius: 0.5rem;
      // color: #fff;
      font-size: 15px;
    }
    .react-tel-input .flag-dropdown {
      border-color: transparent;
    }
  }

  .modal-600 {
    min-width: 800px;
  }
  .btnClose {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50% 50%;
    width: 2.2rem;
    height: 2.2rem;
  }

@media only screen and (max-width:575px) {
   .modal-600{
          min-width: 100% !important;
          position: relative;
          top: 250px;
        }
  
  }
}
