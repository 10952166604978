@import './general.scss';
@import './colorPalette.scss';
@import './typography.scss';
@import './breakpoint.scss';

input,
button,
textarea,
select {
  font-family: 'Roboto', sans-serif !important;
}

.title {
  font-weight   : 500;
  font-size     : var(--fs-24);
  letter-spacing: -1px;
  color         : var(--color-text-white);
}

.sub-title {
  color      : rgba(255, 255, 255, 0.5);
  font-size  : var(--fs-15);
  font-weight: var(--font-weight-thin);

  @include breakpoints(largescreen) {

    margin-bottom: 2rem;
  }
}

.pageContentpanel {
  display   : flex;
  flex      : 1 1;
  padding   : 30px;
  margin-top: 3rem;
}

/*---------Custom Check box------------------------*/
.styled-checkbox {
  position: absolute;
  opacity : 0;

  &+label {
    position: relative;
    cursor  : pointer;
    padding : 0;
  }

  // Box.
  &+label:before {
    content       : '';
    margin-right  : 10px;
    display       : inline-block;
    vertical-align: text-top;
    width         : 16px;
    height        : 16px;
    background    : none;
    border        : 1px solid rgba(255, 255, 255, 0.3);
    border-radius : 3px;
  }

  // Box hover
  &:hover+label:before {
    background  : var(--color-primary);
    border-color: var(--color-primary);
  }

  // Box focus
  &:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked+label:before {
    background  : var(--color-primary);
    border-color: var(--color-primary);
  }

  // Disabled state label.
  &:disabled+label {
    color : #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled+label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked+label:after {
    content   : '';
    position  : absolute;
    left      : 4px;
    top       : 9px;
    background: white;
    width     : 2px;
    height    : 2px;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}

/*------------------PopOver Css start here--------------------*/
.popover {
  background-color: var(--color--off-dark);
  border-radius   : 0.8rem;
}

.bs-popover-auto[x-placement^=bottom]>.arrow::after,
.bs-popover-bottom>.arrow::after {
  border-bottom-color: var(--color--off-dark) !important;
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-top {
  .arrow:before {
    border-top-color: var(--color--off-dark) !important;
  }
}