@import "../../../stylesheets/form.scss";
@import "../../../stylesheets/all.scss";
.createUserAccount {
  display: flex;
  flex-direction: row;
  .formColSection {
    @include breakpoints(desktop) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @include breakpoints(largescreen) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .rgt-img-section {
    @include breakpoints(desktop) {
      display: none;
    }

    @include breakpoints(largescreen) {
      display: flex;
    }
  }

  .form-datepicker {
    .react-datepicker-wrapper {
      width: 80%;
    }
    .react-datepicker__input-container {
      input {
        background: none;
        border: none;
        font-size: var(--fs-14);
        color: var(--color-text-white);
        height: 35px;

        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }

    .icon-date {
      color: rgba(255, 255, 255, 0.5);
      font-size: var(--fs-13);
      position: absolute;
      right: 20px;
      bottom: 32px;
    }
  }

  .btn-field {
    font-size: var(--fs-13);
    font-weight: 500;
    border-radius: 30px;
  }
  .btn-upload-csv {
    background: #1758e3;
    border-color: #1758e3;
  }
  .pl-0 {
    padding-left: 0;
  }
  .pr-0 {
    padding-right: 0;
  }
  .circle-add {
    width: 20px;
    height: 20px;
    padding-top: 3px;
    margin-right: 5px;
    font-size: var(--fs-10);
    display: inline-block;
    justify-content: center;
    text-align: center;
  }

  /*------------------Custom file css start here -------------------------------*/
  .custom_file_upload {
    width: auto;
    display: inline-block;
    margin-right: 15px;
  }
  .custom_file_upload [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  .custom_file_upload [type="file"] + label {
    background-color: #1758e3;
    border: 1px solid #1758e3;
    width: 120px;
    height: 35px;
    border-radius: 30px;
    color: var(--color-text-white);
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif !important;
    font-size: var(--fs-13);
    font-weight: 500;
    margin-bottom: 0rem;
    outline: none;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
    text-align: center;
  }
  .custom_file_upload i {
    font-size: 13px;
    margin-right: 5px;
  }
  .custom_file_upload [type="file"] + label:hover {
    border: 1px solid #1758e3;
    color: var(--color-text-white);
  }

  .custom_file_upload .btn-upload {
    &:hover {
      color: #fff;
    }
  }

  .custom_file_upload .btn-upload:hover::before {
    right: 75%;
  }

  /*------------------Custom file css end here -------------------------------*/
  .btn-edit {
    position: absolute;
    right: 15px;
    background: var(--color-primary);
    border-color: var(--color-primary);
    border-radius: 0.4rem;
    padding: 0.20rem 0.45rem;
    font-size: var(--fs-12);

    &:hover, &.active, &:focus, &:not(:disabled):not(.disabled):active {
        box-shadow: none !important;
        background-color: var(--color-primary-hov) ;
        border-color:var(--color-primary-hov);
    }
  }
}
.createUserAccount .form-control-textarea {
  height: 100px !important;
}
.circle-add {
  width: 25px;
  height: 25px;
  padding-top: 4px;
  margin-right: 10px;
  font-size: var(--fs-12);
  display: inline-block;
  justify-content: center;
  text-align: center;
}
.img-section {
  display: flex;
  align-self: center;
  justify-content: center;
}
