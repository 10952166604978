@import "../../stylesheets/form.scss";
@import "../../stylesheets/all.scss";
.UserProfile {
  display: flex;
  flex-direction: row;

  .profile-header {
    background: var(--color-form-col-bg);
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    padding: 15px;
    flex-direction: row;

    .profile-lft {
      position: relative;
      .profile-img {
        width: 128px; 
        height: 128px; 
        max-width: 128px; 
        max-height: 128px; 
        min-width: 128px; 
        min-height: 128px; 
        border-radius: 0.8rem; }
    }

    .user-info {
      margin-left: 2rem;
      .user-name {
        font-size: var(--fs-24);  
        color:var(--color-text-white); 
        font-weight: var(--font-weight-semi);
        
        .btn-edit {
          position: relative;
          right: auto; 
          top:auto; 
          font-size: var(--fs-10); 
          padding: 0.20rem 0.40rem;}
        }
        .user-post {
          font-size: var(--fs-14); 
          color:rgba(255, 255, 255, 0.4); 
          font-weight: 400; 
        }
        .user-subtext {
          font-size: var(--fs-16);
          color:rgba(255, 255, 255, 0.5); 
          font-weight: 100;
          margin-top: 0.5rem;
          i {margin-right: 0.5rem;}
        }
      }

  }

  .react-tel-input  {
    .form-control{color:#FFF !important;} 
    .flag-dropdown {padding-left: 0px !important;}
  }

  .formColSection {
    @include breakpoints(desktop) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @include breakpoints(largescreen) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .rgt-img-section {
    @include breakpoints(desktop) {
      display: none;
    }

    @include breakpoints(largescreen) {
      display: flex;
    }
  }

  .form-datepicker {
    .react-datepicker-wrapper {
      width: 95%;
    }
    .react-datepicker__input-container {
      input {
        background: none;
        border: none;
        font-size: var(--fs-14);
        color: var(--color-text-white);
        height: 35px;

        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }

  .icon-clock {color:var(--color-text-white);}      
    }

    .icon-date {
      color: rgba(255, 255, 255, 0.5);
      font-size: var(--fs-15);
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }

  .btn-field {
    font-size: var(--fs-13);
    font-weight: 500;
    border-radius: 30px;
  }
  .btn-upload-csv {
    background: #1758e3;
    border-color: #1758e3;
  }
  .pl-0 {
    padding-left: 0;
  }
  .pr-0 {
    padding-right: 0;
  }
  .circle-add {
    width: 20px;
    height: 20px;
    padding-top: 3px;
    margin-right: 5px;
    font-size: var(--fs-10);
    display: inline-block;
    justify-content: center;
    text-align: center;
  }

  /*------------------Custom file css start here -------------------------------*/
  .custom_file_upload {
    width: auto;
    display: inline-block;
    
    position: absolute;
    top:2px;
    right:5px;
  }
  .custom_file_upload [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  .custom_file_upload [type="file"] + label {
    background-color: #1758e3;
    border: 1px solid #1758e3;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    color: var(--color-text-white);
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif !important;
    font-size: var(--fs-13);
    font-weight: 500;
    margin-bottom: 0rem;
    outline: none;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
    text-align: center;
  }
  .custom_file_upload i {
    font-size: 10px;
    
  }
  .custom_file_upload [type="file"] + label:hover {
    border: 1px solid #1758e3;
    color: var(--color-text-white);
  }

  .custom_file_upload .btn-upload {
    &:hover {
      color: #fff;
    }
  }

  .custom_file_upload .btn-upload:hover::before {
    right: 75%;
  }

  /*------------------Custom file css end here -------------------------------*/
  .btn-edit {
    position: absolute;
    right: 15px;
    background: var(--color-primary);
    border-color: var(--color-primary);
    border-radius: 0.4rem;
    padding: 0.20rem 0.45rem;
    font-size: var(--fs-12);

    &:hover, &.active, &:focus, &:not(:disabled):not(.disabled):active {
        box-shadow: none !important;
        background-color: var(--color-primary-hov) ;
        border-color:var(--color-primary-hov);
    }
  }
}
.createUserAccount .form-control-textarea {
  height: 100px !important;
}
.circle-add {
  width: 25px;
  height: 25px;
  padding-top: 4px;
  margin-right: 10px;
  font-size: var(--fs-12);
  display: inline-block;
  justify-content: center;
  text-align: center;
}
.img-section {
  display: flex;
  align-self: center;
  justify-content: center;
}
