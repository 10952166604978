

.step{   
  color: #333;
  background-size: 30px auto;
  padding: 16px 0px 8px 28px;
  width: 100%;
}

.secondStep{
  right: 15px;
  flex: 0 0 55%;
  max-width: 55%;
}

.arrow{
  width: 0;
  height: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
  z-index: 2;
}

.mb15{
  margin-bottom: 15px !important;
}


.arrowShadow{
  width: 0;
  height: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
}

.secondContain{
  right: 16px;
}

.right24{
  right: 34px;
}


.fieldContainer{
  height: calc(100% - 729px);
  overflow-y: auto;
  max-height: 64vh;
  -ms-overflow-style: none;
}

.fieldContainer::-webkit-scrollbar {
  display: none;
}

.stepNumber{
  display: inline-block;
  width: 26px;
  height: 26px;
  color: #fff;
  background: #ce165e;
  border-radius: 79px;
  text-align: center;
  margin-right: 10px;
}

.inputIcon{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}




.textarea, .formSelect, .customDatepicker {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}


.inputFields{
  flex: 0 0 85%;
  max-width: 85%; 
}

.previewHeading{
  flex: 0 0 100%;
  max-width: 100%;
}

.radioForm input[type=radio]{
  margin-top:12px !important;
}


.inviteManually label{
  width: 100% !important;
}
.monthSelection{
  display: flex !important;
}
.disSelectionDd{
  width: 100%;
  display: flex;
}
.radioFormMargin{
  margin-left: 15px;
}

.bulkUpload{
  width: 670px;
  height: 120px;
  text-align: center;
  border: 1px dashed gray;
  margin-left: 23px;
}
.repeatSection{
  width: 25% !important;
}
.endDateTags{
  display: flex;
}
.radioFormAfter{
  margin-left: 10px;
}
.successUpload{
  height: 81px;
  border-color: #42ba96;
  color: #42ba96;
}
@media screen and (max-width: 929px){
  .scheduleMeetContainer label{
    // display: none !important;
  }
  .scheduleMeetContainer{
    width: 100%  !important;
  }
  .scheduleMeetingPadding{
    padding: 0px !important;
  }
 
}
@media screen and (max-width: 576px) {
  .stepsLabel{
    display: none !important;
  }
  .stepsLabelText{
    margin: 0px !important;
  }
  .step{
    // padding: 15px 0px 15px 5px !important;
    text-align: center;
  }
  .recurrenceTypeSch{
    width: 100% !important;
  }
  .repeatSection{
    width: 100% !important;
  }
  .endDateTags{
    display: block;
  }
  .bulkUpload{
  width: 100%;
  height: 100%;
  padding: 10px;
  }
.monthSelection{
  display: block !important;
  }
  .radioFormMargin{
   margin-left: 0px;
  }
  .disSelectionDd{
    display: block;
  }
  .margBottom{
    margin-bottom: 2px;
  }
  .radioFormAfter{
    margin-left: 0px !important;
  }
}