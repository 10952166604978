.wave_wating_sctn {min-height: 100vh;  width: 100%; margin: 0 auto; background: #221e29; display: block; padding: 20px;}  
.wave_wating_sctn  .wave_logo_part{ display: inline-block; width: 100%; text-align: left;}
.wave_wating_sctn  .wave_logo_part img{width: 200px;}

.meeting_dtl_78{font-size: 15px; text-align: left;}
.meeting_dtl_78 span{display: inline-block; margin-right: 25px;  }
.meeting_dtl_78 span font{color: #ffffff94; font-size: 14px;}
.meeting_dtl_78 span.wr_meeting_name{color: #f63d85}
.meeting_dtl_78 span.wr_meeting_id{color: #6d6dff}

.wave_wait_timer{color: #eea236; font-size: 35px; font-weight: 100; margin-top: 50px;}
.wave_wait_timer i{margin-right: 5px;}
 
.wait_msg{display: inline-block; width: 100%; text-align: center; font-size: 32px; color: #fff; font-weight: 100;}
.wait_msg span{display: block; font-size: 24px; padding-top: 15px;}

.wave_waiting_contianer_msg { display: flex; flex-direction: column; align-items: center;  justify-content: center;
   max-width: 1200px; margin: 0 auto; height: calc(100vh - 100px)}

 @media all and (max-width: 768px){
 .meeting_dtl_78{text-align: center;}
 .wave_waiting_contianer_msg{height: auto; margin-top: 80px;}
.wait_msg{font-size: 24px;}
.wait_msg span{font-size: 16px;}
.wave_wait_timer{font-size: 22px;}
}
	