.webinar_dtl_title {
  font-size: 24px;
  font-weight: 100;
  color: #2d91da;
  font-family: "Comfortaa";
}
.w_webinar_jion_btn {
  display: inline-block;
  font-size: 13px;
  padding: 7px 15px;
  text-align: center;
  border-radius: 20px;
  background: #2a5df4;
  cursor: pointer;
  color: #fff;
  border: solid 1px #2a5df4;
}

.webinar_detail_page {
  display: inline-block;
  width: 100%;
  background: #ffffff0a;
  border-radius: 10px;
  padding: 25px;
}
.webinar_detail_page hr {
  border-color: #ffffff17;
  margin-bottom: 20px;
}
.webinar_detail_page .field_title_sm {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  color: #ffffffa8;
  margin-bottom: 10px;
}
.webinar_detail_page .sm_meeting_name {
  font-size: 20px;
  color: #2d91da;
  margin-top: -5px;
  text-transform: capitalize;
}
.webinar_detail_page .sm_meeting_password {
  font-size: 16px;
  color: #fff;
  margin-top: -5px;
}
.webinar_detail_page .sm_meeting_time {
  font-size: 16px;
  color: #8ce7fdd6;
}
.webinar_detail_page .sm_meeting_time i {
  margin-right: 5px;
}
.webinar_detail_page .sm_meeting_time font {
  font-size: 16px;
  padding-top: 5px;
  color: #d2bb40;
}

.webinar_detail_page .sm_meeting_id {
  color: #fff;
  font-size: 17px;
  text-transform: capitalize;
}

.webinar_detail_page .w_type_live {
  display: inline-block;
  color: #fff;
  padding: 4px 15px;
  border-radius: 20px;
  background: #2ea11d;
  line-height: normal;
  font-size: 13px;
}
.webinar_detail_page .w_type_recorded {
  display: inline-block;
  color: #fff;
  padding: 4px 15px;
  border-radius: 20px;
  background: #ae3641;
  line-height: normal;
  font-size: 13px;
}

.webinar_description {
  font-size: 13px;
  color: #ffffffb0;
  display: block;
  padding: 10px;
  border-radius: 5px;
  background: #0e0d0d24;
  font-weight: 100;
}

.webinar_invitio_sw {
  max-height: 450px;
}

.invtregistration_status {
  display: inline-block;
  color: #fff;
  font-size: 13px;
  width: 100%;
}
.invtregistration_status .esattendi_name {
  display: inline-block;
  margin-right: 15px;
  width: 200px;
}
.swregststs {
  display: inline-block;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 20px;
  background: #2e9f0e;
  color: #fff;
  line-height: normal;
}

.swregststs_gray {
  display: inline-block;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 20px;
  background: #666;
  color: #fff;
  line-height: normal;
}

.participnrt_name_td {
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.brand_info_form {
  display: inline-block;
  width: 100%;
}
.brand_info_form .form-group label {
  color: #ffffff9c;
  width: 100%;
  display: block;
}
.brand_info_form .brand_name_1 {
  font-family: "Comfortaa";
  font-size: 22px;
  color: #eaf2f8;
}
.brand_info_form .brand_name_1 .custom_btn {
  min-width: 28px;
  text-align: center;
}
.brand_name_edit .form-control {
  background: #f0f8ff21;
  color: #fff;
  font-size: 15px;
  border-color: #fff3;
}

.brand_name_edit .error_msg_56 {
  margin-top: 5px;
  padding-left: 0px;
}

.brand_logo_img {
  display: inline-block;
  background: #221e29;
  border: solid 1px #dddddd2b;
  padding: 5px;
  position: relative;
  border-radius: 5px;
}
.brand_logo_img img {
  border-radius: 5px;
}
.brand_logo_img .remove_brand_img {
  position: absolute;
  right: -40px;
  top: 0px;
  background: #bf2f35;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 13px;
  padding: 3px 8px;
}

.add_host_bx .form-control {
  background: #ffffff0a;
  border-color: #736f6f9e !important;
  font-size: 14px;
  color: #fff;
  border-radius: 0px;
}
.add_host_bx .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.add_host_bx .react-tel-input {
  background: #ffffff0a;
  border: solid 1px #736f6f9e;
  font-size: 14px;
  padding: 4px 10px 4px 0px;
}
.add_host_bx .react-tel-input .flag-dropdown {
  background: #0000002e !important;
}
.add_host_bx .react-tel-input .flag-dropdown.open .selected-flag {
  background: #1d1923 !important;
  border-radius: 0px !important;
}
.add_host_bx .react-tel-input .selected-flag:hover,
.dtl_input45 .react-tel-input .selected-flag:focus {
  background: #1d1923 !important;
}
.add_host_bx .react-tel-input .country-list .country-name {
  color: #fff;
}
.add_host_bx .react-tel-input .phone-input {
  color: #fff !important;
}
.add_host_bx .flag-dropdown {
  padding-left: 0px !important;
  min-width: 38px !important;
}
.add_host_bx .react-tel-input .country-list {
  background: #242328;
}
.add_host_bx .react-tel-input .country-list .country {
  margin-bottom: 0px !important;
}
.add_host_bx .react-tel-input .country-list .country:hover {
  background: #f1f1f117;
}
.add_host_bx .react-tel-input .country-list .country.highlight {
  background: #f1f1f117;
}
.add_host_bx .react-tel-input .country-list .search {
  background: #26222c;
  border-bottom: solid 1px #ffffff0f;
}
.add_host_bx .react-tel-input .country-list .search-box {
  border-color: #cacaca38;
}

.host_block_sec {
  padding: 0px;
}
.host_block_sec .host_counter {
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  background: rgb(172, 106, 20);
  color: #fff;
  padding: 1px 10px;
}
.host_block_sec hr {
  border-top: 1px dashed #ffffff21;
}

.speaker_invite_list {
  display: inline-block;
  width: 100%;
  border-bottom: solid 1px #ffffff0f;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.speaker_invite_list:last-child {
  border-bottom: none;
}
.speaker_profile {
  display: inline-block;
  width: 250px;
  vertical-align: middle;
}
.speaker_profile .speaker_avtar_img {
  width: 30px;
  display: inline-block;
  vertical-align: top;
}
.speaker_profile .speaker_avtar_img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  vertical-align: middle;
}
.speaker_profile .speaker_avtar {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #1a97c9b0;
  color: #fff;
  text-align: center;
}
.speaker_profile .speaker_name {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  color: #fff;
}
.speaker_profile .speaker_name span {
  display: block;
  font-size: 14px;
}
.speaker_profile .speaker_name font {
  display: block;
  font-size: 12px;
  color: #ffffffa3;
}

.speaker_contact_detail {
  display: inline-block;
  width: 200px;
  vertical-align: top;
  font-size: 13px;
  color: #fff;
}
.speaker_contact_detail font {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
}
.speaker_bio {
  display: inline-block;
  font-size: 12px;
  width: 150px;
  vertical-align: top;
  color: #fff9;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.speaker_action_link {
  display: inline-block;
  vertical-align: top;
  float: right;
}

.csv_download_btn {
  font-size: 12px;
  padding: 3px 10px;
  background: #01a33d;
  border-radius: 20px;
  color: #fff;
  margin-left: 10px;
  border-color: #01a33d;
}
.csv_download_btn:hover {
  color: #fff;
}

/* .mergLeft34{
  margin-left: 34%;
} */
/*------------------Custom file css start here -------------------------------*/
.custom_file [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

.custom_file [type="file"] + label {
  background-color: #f0f8ff21;
  border: 1px solid #f0f8ff21;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", sans-serif !important;
  font-size: inherit;
  font-weight: 400;
  margin-bottom: 0rem;
  outline: none;
  padding: 1rem 50px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  text-align: center;
}

.custom_file [type="file"] + label:hover {
  border: 1px #eb6737 solid;
  color: #eb6737;
}

.custom_file .btn-2 {
  background-color: #f0f8ff21;
  border-color: #fff3;
  border-radius: 50px;
  overflow: hidden;
  width: 200px;
  }

.custom_file .btn-2:hover {
  background-color: darken(#99c793, 30%);
}
.custom_file .btn-2:hover::before {
  right: 75%;
}

/*------------------ Basic Info -------------------------------*/
.baseInfo {
  display: flex;
  justify-content: center;
}

.timer {
  font-size: 19px;
  font-weight: 500;
}

.baseInfo .detailsSection {
  width: 100%;
  align-self: center;
  border-radius: 21px;
  padding: 22px;
}

.baseInfo div.title {
  font-size: 14px;
  text-transform: capitalize;
  color: #2d91da;
  width: 100%;
font-family: 'Roboto';
}
.title-lc{
  text-transform: lowercase !important;
}
.baseInfo .description {
  width: 100%;
  font-size: 13px !important;
  padding: 2px 3px;
  color: #ffffff9e;
}
.baseInfo .webinar_id {
  width: 100%;
  font-size: 15px !important;
  padding: 2px 3px;
  color: #ffffff9e;
}

.baseInfo .scrollbar-container.ps {
  min-height: 10px;
  max-height: 100px;
}

.typeCard {
  background: #343944;
  padding: 5px 12px;
  border-radius: 13px;
}

.baseInfo .btnCss {
  margin: 5px;
  border-radius: 12px;
  box-shadow: none;
  padding: 10px 11px;
  min-width: 113px !important;
}

.regUrl {
  background: #00000026;
  padding: 15px;
  border-radius: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyUrlBtn {
  font-size: 18px;
  margin-left: 25px;
}

.copyUrlBtn:hover {
  background: #aeaeae;
  border-radius: 6px;
}

.timeNote {
  color: #7c7a7aad;
}

.secondSection {
  border-radius: 12px;
  padding: 15px;
  background-color: #1f222b;
}

.divider-Right {
  border-right: 1px solid #ffffff24;
}

.baseInfo label {
  font-size: 14px;
  color: #88929b !important;
}

span.truncate {
  width: 91%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*------------------ Speakers -------------------------------*/

.btnAddSpe {
  border-radius: 9px;
  padding: 7px;
  padding-left: 34px;
  padding-right: 34px;
  box-shadow: none !important;
}

.speakers table {
  border-radius: 12px;
}
.speakers .table-dark {
  background-color: #282c35;
}

.speakers th,
.speakers td {
  border-top: 0px !important ;
}

.share {
  color: #06caa5 !important;
}

div.shared_url {
  padding: 9px;
  border: 1px solid #00000054;
  border-radius: 10px;
  width: 100%;
  word-break: break-all;
}

.closeBtn {
  background: transparent;
  font-size: 15px;
  border: 0;
}
/* 
.tableHeader th:nth-child(1) {
  width: 1%;
  text-align: center;
}
.tableHeader th:nth-child(2) {
  width: 6%;
}
.tableHeader th:nth-child(3) {
  width: 8%;
}
.tableHeader th:nth-child(4) {
  width: 5%;
}
.tableHeader th:nth-child(5) {
  width: 2%;
}
.tableHeader th:nth-child(6) {
  width: 5%;
  text-align: center;
} */

.inputGroupCancel {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.inputGroupSearch {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

tr.tableHeader th {
  font-weight: 400 !important;
  color: #808ba3;
}

tr.welcomeMsg td {
  color: #9fa6b6 !important;
  font-size: 15px;
}

.speakers .searchSectionWebinar input.searchInput.form-control {
  height: 55px;
  font-size: 16px !important;
}

/*--Modal--*/

.w413 {
  width: 413px !important;
}

.w75 {
  width: 75px;
}

.border1R10 {
  border: 1px solid #393e4a !important;
  border-radius: 10px;
}

.activeField {
  background: transparent;
  color: #ce165e;
  border-bottom: 1px solid;
}

.addSpeakers .profile-pic {
  max-width: 200px;
  max-height: 200px;
  display: block;
}

.addSpeakers .file-upload {
  height: 0;
  width: 0;
}
.addSpeakers .circle {
  border-radius: 40px !important;
  overflow: hidden;
  width: 180px;
  height: 180px;
  position: relative;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addSpeakers .circle i {
  font-size: 4rem;
}
.addSpeakers img {
  max-width: 100%;
  max-height: 180px;
  min-height: 100%;
  border-radius: 30px !important;
}
.addSpeakers .p-image {
  position: absolute;
  /* right: 20px; */
  right: auto;
  margin-right: -104px;
  top: 50px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.addSpeakers .p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.addSpeakers .upload-button {
  font-size: 0.8em;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.addSpeakers.upload-button:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #999;
}

/*------------------Branding-------------------------------*/

.branding .container {
  width: 90%;
  align-self: center;
  border-radius: 21px;
  padding: 22px;
}

.branding .uploadInput {
  height: 0;
  width: 0;
  overflow: hidden;
}

.branding label.uploadLogo {
  width: 100%;
  height: 100px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.branding label.uploadLogo:hover {
  border: 1px solid #ce165e !important;
  color: #ce165e !important;
}

.branding label.activeSection {
  border: 1px solid #198754 !important;
  color: #198754 !important;
}
.branding label.errorSection {
  border: 1px solid #dc3545 !important;
  color: #dc3545 !important;
}

.btnCenter {
  display: flex;
  align-items: center;
  justify-self: center;
}

.inputBrandName {
  border: 0;
  padding: 25px 21px;
  border-radius: 12px;
}

/*------------------ Entry page -------------------------------*/
.activeTab {
  color: #ce165e !important;
  border-bottom: 2px solid;
}

.entry {
  margin-left: 2%;
  margin-right: 3%;
  padding: 10px 18px;
  border-radius: 10px;
}

.entry .cardSection {
  border-radius: 12px;
  padding: 15px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.entry .cardSection h6 {
  border-bottom: none;
}

.entry .ImagesSection {
  width: 180px;
  height: 180px;
}

.entry .subDes {
  font-size: 14px;
  height: 100px;
  overflow: auto;
  margin-top: 7px;
  color: #9f9f9fde;
}

.entry .containerCard {
  flex: 0 0 33%;
  max-width: 33%;
  position: relative;
}

.entry .addFieldBtn {
  border: 0;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 16px;
  position: absolute;
  top: 17px;
  right: 60px;
}

.entry .switchBtn {
  position: absolute;
  top: 14px;
  right: 11px;
}

/*------------------ participants -------------------------------*/

.participants table {
  border-radius: 12px;
  text-align: center;
}

.participants th {
  border: 0px !important;
}

.tagInputContainer {
  border: 1px solid;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 8px;
  border-radius: 4px;
  padding-left: 3px;
  padding-right: 3px;
}

.listTagInput {
  background-color: #14161a;
  padding: 4px;
  border-radius: 4px;
  margin: 0px 6px;
  align-items: center;
  word-break: break-all;
  margin-bottom: 8px;
}

.tagListClose {
  fill: #fff;
  padding: 0px;
  border-left: 1px solid #636363;
  padding-left: 3px;
  margin-left: -3px;
  width: 20px;
}

.tagInput {
  background: transparent;
  border: 0;
  outline: 0;
  height: 35px;
  margin-bottom: 8px;
}

/*------------------Custom file css end here -------------------------------*/

@media screen and (max-width: 550px) {
  .webinar_detail_page {
    padding: 15px;
    margin-top: -15px;
  }
  .webinar_dtl_title {
    font-size: 17px;
  }
  .webinar_detail_page .sm_meeting_name {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .webinar_detail_page hr {
    margin-bottom: 15px;
  }
  .mob-mb-10 {
    margin-bottom: 10px;
  }
}


/* switch button start here */



.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9d9d9d;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #ce165e;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(10px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on,
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked+.slider .on {
  display: block;
}

input:checked+.slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.t-text{
  font-size: 12px !important;
}

/* switch css end here ---------------------- */


.customModal .modal-content .form-control {
  height: calc(1.5em + 0.8rem + 2px) !important;
  border-radius: 0.2rem !important;
}

.pr-cardb .card-body{
  background-color: #282c35;
  border-radius: 10px;
}
.pr-cardb{
  border-radius: 10px;
      width: 250px;
      height: 290px;
}
.card-img{
      width: 120px;
      height: 120px;
      border-radius: 50%;
}

.btn-edit{
   position: absolute;
   top: 10px;
   left: 0;
}
   
.btn-remove {
    position: absolute;
    top: 10px;
    right: 11px;
}


.btn-3 {
  background-color: #f0f8ff21;
  border-color: #fff3;
  border-radius: 50px;
  overflow: hidden;
  height: 150px;
}

.f-size-m{
      overflow-y: scroll;
      height: 85px;
}
.f-size-m::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #404040;
}

.f-size-m::-webkit-scrollbar {
  width: 10px;
}

.f-size-m::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #737272;
  border: 1px solid #000;
}
@media (max-width: 600px) {
  /* .mergLeft34{
    margin-left: 0;
  } */
  .q-a-toggle{
    text-align: left !important;
  }
  .webinar-url-link{
    padding: 0 0.55rem!important;
    margin-left: 0rem !important;
  }
  .speakers-modal{
    position: relative;
    /* top: 65%; */
  }
  .brandLogo{
    text-align: center;
  }
  .brandLogoSection{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.webinar-url-link{
  margin-left: 0.5rem;
}
