.labelBtn {
  background: transparent;
  border: 0px;
  text-decoration: underline;
  font-size: 13px;
}

.otpContainer {
  .OtpInput {
    // background-color: rebeccapurple;
    input {
      border: 1px solid #ced4da !important;
      margin-right: 5px;
      height: 29px !important;
      outline: none;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}

.forgetPassword {
  text-align: center;
  .modal-dialog {
    max-width: 600px !important;
    width: 600px !important;
  }
  .modalDescription {
    font-size: 14px;
  }
  .contactSales a {
    font-size: 13px;
  }
  .headingText {
    color: #251665;
  }
  .imgContainer {
    bottom: 50%;
  }

  input.bg-light.form-control {
    border-radius: 8px;
  }
  button.btn.btn-primary {
    background-color: #f04b55;
    border-color: #f04b55;
    box-shadow: 0px 5px 12px 0px #f5a7ab;
  }
  h3 {
    color: #281864;
  }
  input#formBasicEmail {
    width: 330px;
    margin: 0 auto;
  }
}


.modalContent{
  background: #F9F9F9 !important;
}