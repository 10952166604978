.customCard {
  border-radius: 30px;
  width: 100%;
  color: #282c35;
  height: 340px;
  padding: 30px;
}

.cardImage {
  width: 300px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 225px;
}

.cardText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
}
.cardText h6 {
  font-size: 1.5rem;
  letter-spacing: -1px;
  font-weight: 600;
}
.cardText h4 {
  font-size: 2rem;
  font-weight: 600;
}

.TotalMeet {
  background-color: #fddd89 !important ;
}

.Webinar {
  background-color: #60eacb !important ;
}
.ScheduleMeet {
  background-color: #fcc49d !important ;
}

.btnSection {
  display: flex;
  width: 76%;
  height: 60px;
  border-radius: 13px;
  // box-shadow: 1px 3px 2px #818080ad;
  // box-shadow: 0px 2px 1px #0000007d;
  border: 0;
  outline: none;
  align-items: center;
  border: none;
  box-shadow: 0px 8px 8px #0000001a;
  cursor: pointer;
  outline: none;
}

.br10 {
  border-radius: 10px;
}

.startbtn {
  background-color: #28a745;
  border-radius: 50px;
  font-size: 13px;
  color: #fff;
}
.sharebtn {
  font-size: 13px;
  color: #fff;
  background-color: #fd552c;
  border-radius: 50px;
}
.innerList {
  border-radius: 15px;
}
.customNavbars a {
  border: 0 !important;
  margin-left: 10px;
}

.MeetingContainer {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #fd552c;
    border-radius: 28px;
    font-weight: 500;
  }
  a.nav-link {
    color: #7a7a7ae0;
  }

  .clockSection {
    font-size: 64px;
    color: #3F3F3F;
    font-weight: 500;
    line-height: 80px;
  }
  .clock-day {
    color: #3F3F3F;
    font-size: 26px;
  }

  .noMeets {
    color: #7a7a7aa6;
    text-align: center;
  }
  .startbtn {
    background-color: #28a745;
    border-radius: 50px;
    font-size: 13px;
    color: #fff;
  }
  .sharebtn {
    font-size: 13px;
    color: #fff;
    background-color: #fd552c;
    border-radius: 50px;
  }
  .innerList {
    border-radius: 15px;
  }
  .customNavbars a {
    border: 0 !important;
    margin-left: 10px;
  }
  .customNavbars a.active {
    background-color: #fd552c !important;
    border-radius: 40px;
    color: #fff !important;
  }
  .customNavbars a.hover {
    background-color: #fd552c !important;
  }
  .nav .nav-item:hover {
    background-color: #fd552c !important;
    border-radius: 40px;
    color: #fff !important;
    transition: none;
  }
  .customlist {
    border-radius: 50px;
    box-shadow: 0px 5px 8px 0px rgb(224, 224, 224);
  }

  .meetId {
    font-size: 13px;
    color: #878787db;
  }

  button:focus {
    box-shadow: 0px 8px 8px #0000001a;
    // box-shadow: 1px 3px 2px #818080ad;
    outline: none;
  }

  .scrollSection {
    height: 39vh;
    overflow: auto;
  }

  .imgContainer {
    text-align: center;
    margin-top: 19px;
  }

  .textWebCenter {
    text-align: -webkit-center !important;
  }
}

.textLimit{
  white-space: nowrap;
  width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meetCardContainer {
  background: #282c35;
  margin-top: 12px;
  border-radius: 6px;
  padding: 6px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;

  .time {
    color: rgba(256, 256, 256, 0.7);
  }
}

.meetCardContainer .dateSection {
  padding: 14px;
  text-align: center;
  min-width: 90px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 600;
  font-size: 30px;
}
.time {
  font-size: 18px;
  font-weight: 400;
}
.lh-30 {
  line-height: 30px;
}
.text-meet-id {
  color: #4BA892;
  font-size: 14px;
  font-weight: 500;
  padding-top: 4px;
}
.meeting-title {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 4px;
}
.card-time {
  font-size: 14px;
}

.MeetIdSection {
  background: #343944;
  height: 26px;
  font-size: 11px;
  padding-left: 8px;
  padding-right: 9px;
  padding-top: 5px;
  border-radius: 12px;
  font-size: 12px;
}

.btnSectionCard {
  padding: 12px 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 10px;
  font-weight: 500;
  box-shadow: none;
  border: 0;
  font-size: 12px;
}

.auth {
  background: #343944;
}

.startMeeting {
  background-color: #06caa5;
}
.welcomeMsg span.font-weight-bold, .headerContainer h3 {
  font-weight: 500!important;
}
.welcomeMsg span.font-weight-bold {
  color: #CE165E;
}
.headerContainer h3 {
  font-size: 22px;
  margin-bottom: 0px;
}
.btn-primary-outline,
.btn-primary-outline-white {
  text-transform: uppercase;
}
.btn-primary-outline {
  background: rgba(206, 22, 94, 0.1);
  border: 1px solid rgba(206, 22, 94, 0.4);
  color: #CE165E;
  font-weight: 500;
}
.btn-primary-outline:hover,
.btn-primary-outline:visited,
.btn-primary-outline:focus,
.btn-primary-outline:focus-visible,
.btn-primary-outline:active {
  background: rgba(206, 22, 94, 0.2);
  border: 1px solid rgba(206, 22, 94, 0.4);
  color: #CE165E;
  outline: 0px;
}
.btn-primary-outline.btn-sm {
  font-size: 12px;
  padding: 4px 10px;
}
.btn-primary-outline-white {
  background: rgba(206, 22, 94, 0);
  border: 1px solid rgba(206, 22, 94, 0.4);
  color: #CE165E;
  font-weight: 500;
}
.btn-primary-outline-white:hover,
.btn-primary-outline-white:visited,
.btn-primary-outline-white:focus,
.btn-primary-outline-white:focus-visible,
.btn-primary-outline-white:active {
  background: rgba(206, 22, 94, 0);
  border: 1px solid rgba(206, 22, 94, 0.4);
  color: #CE165E;
  outline: 0px;
}
.btn-primary-outline-white.btn-sm {
  font-size: 12px;
  padding: 4px 10px;
}
.meet-card {
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
}
.meet-card .btn-primary-outline-white {
  margin-right: 5px;
}
.text-bdr-btn {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 2px;
  cursor: pointer;
}
.text-bdr-btn:before {
  content: "";
  position: absolute;
  height: 1px;
  border-bottom: 1px dashed;
  border-color: #3F3F3F;
  width: 100%;
  bottom: -4px;
}

/*****media query starts from here *******/

@media only screen and (max-width: 1400px) {
  .cardImage {
    width: 100%;
    height: auto;
  }
  .cardText {
    position: absolute;
    bottom: 30px;
    width: 100%;
    left: 0;
    justify-content: space-around;
  }
}



@media only screen and (max-width: 1300px) {
  .cardText h6 {
    font-size: 20px;
  }
  .cardText h4 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1200px) {
  .customCard {
    height: 320px;
  }
  .cardText {
    bottom: 10px;
  }
  .cardText h6 {
    font-size: 16px;
  }
  .cardText h4 {
  font-size: 18px;
  }
  .layoutContainer .childrenContainer {
    padding: 0 15px !important;
  }
  .headerContainer h3{
    font-size: 21px;
  }
  .btnSectionCard {
    padding: 7px 10px;
  }

  .MeetingContainer .textWebCenter button{
    justify-content: center;
    margin: 0px 10px;
  }
  .MeetingContainer .textWebCenter button:hover{
    background-color: #ce165e !important;
    outline: none;
  }
  .MeetingContainer .textWebCenter button span{
    margin-left: 0 !important;
  }
.meetCardContainer .dateSection, .MeetIdSection{
    display: none;
  }
  .layoutContainer .ml100, .layoutContainer .ml256 {
    margin-left: 0px !important;
} 
}
@media only screen and (max-width: 991px) {
  .MeetingContainer .textWebCenter {
    display: flex;
    order: 1;
  }
  .upcomingMeetContainer {
    order: 2;
    margin: 30px 0px;
  }
  .customCard {
    height: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .MeetingContainer .imgContainer {
    display: none;
  }

  .customCard {
    height: auto;
  }
  .cardText {
    bottom: 22%;
  }
  .cardText h4, .cardText h6 {
  font-size: 14px;
  }

}

@media only screen and (max-width: 625px) {
  .MeetingContainer .customColumn{
    flex: 0 0 50%;
    max-width: 50%;
  }
  .MeetingContainer .customColumn:nth-child(3) {
    margin-top: 15px;
  }
  .headerUserName{
    display: none;
  }
 .upcomingMeetContainer .upcomingMeetText{
    font-size: 16px;
    margin-left: 10px;
  }
  .upcomingMeetContainer{
    padding: 0;
  }
}


@media only screen and (max-width: 575px) {
  .MeetingContainer .customColumn{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .customCard {
    padding: 25px;
  }
  .cardText {
    bottom: 18%;
  }
  .MeetingContainer .customColumn:nth-child(2) {
    margin-top: 15px;
  }
  .cardText h4, .cardText h6 {
    font-size: 18px;
  }
  .cardText {
    justify-content: space-between;
    padding: 0px 30px;
  }
  .headerContainer h3 span {
    font-size: 16px;
  }
  .headerContainer h3 {
    margin-left: 5px !important;
  }
  .customColumn{
    padding: 0;
  }
  .upcomingMeetContainer .upcomingMeetText{
    font-size: 14px;
    margin-left: 10px !important;
  }
  .meetCardContainer.sideBar.welcomeMsg {
    display: block;
    text-align: center;
}
.meetCardBtnSection{
  margin-top: 30px;
}

.idMeetSection{
  width: 100%;
}
.MeetingContainer .textWebCenter button{
  margin: 0 auto;
}
}