  .schedule_campaign_sec {
    display: flex;
    flex-direction: row;
    align-items: flex-start;  
    max-width: 1000px;
    margin: 50px auto 0px;
    padding-top: 50px;
}

.crate_campaing_sec {
    display: flex;
    width: 100%;
    float: left;
    align-items: left;
    flex-direction: column;
} 

.hasError { border-color: #eb6737 !important; }

.bg_container_sh_conf{background: #221e29; min-height: 100vh;}

.crate_campaing_sec h2{font-size: 28px;  font-weight: 100; margin-bottom: 45px; color: #222;  font-family: 'Comfortaa', cursive}
.crate_campaing_sec h2 i{color:#FB7132 }

.crate_campaing_sec .form-check-inline label{font-size: 13px; color: #444}

.crate_campaing_sec .form-control{border-radius: 0px; font-size: 13px; background: #ffffff0a; border-color: #ffffff21; color: #fff;}

.custom_inpuot_grup{font-size: 13px;}
.custom_inpuot_grup .input-group-text{border-radius: 0px; border-color: #ced4da; background: #e9ecef03}

.field_title{font-size: 14px; color: #ffffffd6}

.crate_campaing_sec  .sch_dths{font-size: 12px; padding: 5px; width: 25px; text-align: center;}


.csv_upload_sec textarea.form-control{ height: 100px; }

.conference_schedule_button{display: inline-block; font-size: 16px; padding: 8px 20px; text-align: center; border-radius: 20px; background: #f33c5e; border-color: #f33c5e;}

.toggle_switch_option{display: block;}
.toggle_switch_option span{display: inline-block; font-size: 13px; color: #c3c3c3}

.sperkr_upload:after{background: #5db75d; color: #fff; border-color: #5db75d ; border-radius: 0px; height: calc(1.5em + .80rem)}
.host_upload:after{background: #347ab8; color: #fff; border-color: #347ab8 ; border-radius: 0px; height: calc(1.5em + .80rem)}
.prtcpnt_upload:after{background: #efad4d; color: #fff; border-color: #efad4d ; border-radius: 0px; height: calc(1.5em + .80rem)}

.cs_sh_tab_link .nav-item .nav-link{cursor: pointer;}
.cs_sh_tab_link .nav-item .nav-link.active{ background: #c64141; border-color: #333 #333 transparent; color: #fff; font-weight: 400; }

.cs_sh_tab_content{border: solid 1px #333; padding: 0px 15px 10px; margin-top: -1px;}

 
.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::-moz-selection, .tgl:after::-moz-selection, .tgl:before::-moz-selection, .tgl *::-moz-selection, .tgl *:after::-moz-selection, .tgl *:before::-moz-selection, .tgl + .tgl-btn::-moz-selection {
  background: none;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
  background: none;
}
.tgl + .tgl-btn {
  outline: 0;
  display: inline-block; vertical-align: middle;
  width: 3em;
  height: 1.5em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-light + .tgl-btn {
  background: #f0f0f033;
  border-radius: 2em;
  padding: 2px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}
.tgl-light:checked + .tgl-btn {
  background: #9FD6AE;
}

.tgl-ios + .tgl-btn {
  background: #fbfbfb;
  border-radius: 2em;
  padding: 2px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  border: 1px solid #e8eae9;
}
.tgl-ios + .tgl-btn:after {
  border-radius: 2em;
  background: #fbfbfb;
  -webkit-transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
.tgl-ios + .tgl-btn:hover:after {
  will-change: padding;
}
.tgl-ios + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}
.tgl-ios + .tgl-btn:active:after {
  padding-right: .8em;
}
.tgl-ios:checked + .tgl-btn {
  background: #86d993;
}
.tgl-ios:checked + .tgl-btn:active {
  box-shadow: none;
}
.tgl-ios:checked + .tgl-btn:active:after {
  margin-left: -.8em;
}

.tgl-skewed + .tgl-btn {
  overflow: hidden;
  -webkit-transform: skew(-10deg);
          transform: skew(-10deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-family: sans-serif;
  background: #888;
}
.tgl-skewed + .tgl-btn:after, .tgl-skewed + .tgl-btn:before {
  -webkit-transform: skew(10deg);
          transform: skew(10deg);
  display: inline-block;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.tgl-skewed + .tgl-btn:after {
  left: 100%;
  content: attr(data-tg-on);
}
.tgl-skewed + .tgl-btn:before {
  left: 0;
  content: attr(data-tg-off);
}
.tgl-skewed + .tgl-btn:active {
  background: #888;
}
.tgl-skewed + .tgl-btn:active:before {
  left: -10%;
}
.tgl-skewed:checked + .tgl-btn {
  background: #86d993;
}
.tgl-skewed:checked + .tgl-btn:before {
  left: -100%;
}
.tgl-skewed:checked + .tgl-btn:after {
  left: 0;
}
.tgl-skewed:checked + .tgl-btn:active:after {
  left: 10%;
}

.tgl-flat + .tgl-btn {
  padding: 2px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  background: #fff;
  border: 4px solid #f2f2f2;
  border-radius: 2em;
}
.tgl-flat + .tgl-btn:after {
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  background: #f2f2f2;
  content: "";
  border-radius: 1em;
}
.tgl-flat:checked + .tgl-btn {
  border: 4px solid #7FC6A6;
}
.tgl-flat:checked + .tgl-btn:after {
  left: 50%;
  background: #7FC6A6;
}

.tgl-flip + .tgl-btn {
  padding: 2px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-family: sans-serif;
  -webkit-perspective: 100px;
          perspective: 100px;
}
.tgl-flip + .tgl-btn:after, .tgl-flip + .tgl-btn:before {
  display: inline-block;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 4px;
}
.tgl-flip + .tgl-btn:after {
  content: attr(data-tg-on);
  background: #02C66F;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}
.tgl-flip + .tgl-btn:before {
  background: #FF3A19;
  content: attr(data-tg-off);
}
.tgl-flip + .tgl-btn:active:before {
  -webkit-transform: rotateY(-20deg);
          transform: rotateY(-20deg);
}
.tgl-flip:checked + .tgl-btn:before {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.tgl-flip:checked + .tgl-btn:after {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
  left: 0;
  background: #7FC6A6;
}
.tgl-flip:checked + .tgl-btn:active:after {
  -webkit-transform: rotateY(20deg);
          transform: rotateY(20deg);
}

.react-datepicker-popper{width: 500px}

.react-datepicker-wrapper{width: 80%;}

.crate_campaing_sec .sch_dths{background: #ffffff0a; border-color: #ffffff21}
.note_list {list-style: none; margin: 0px; padding:0px;}
.note_list li { font-size: 13px; color: #cbcbcccf; margin: 5px 0px; font-weight: 100;}
.note_list li span {font-weight: 400;}

@media only screen and (max-width:575px) {
  .note_list li {font-size:12px;}
}

