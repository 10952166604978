 
.privacy-content p, .privacy-content ul {
     font-family: 'Roboto', sans-serif;
     color: rgb(49, 49, 49);
     font-size: 16px;
 }

 h1,
 h3 {
     font-family: 'Roboto', sans-serif;
     margin-top: 50px;
 }

 .privacy-container{
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
     background-color: white;

 }
.content-container{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 1100px;
}
.heading-container{
    text-align: center;
    font-size: 3em;
    color: #CE165E;
    font-weight: 600;
    margin: 0px 0px 40px 0px;
}