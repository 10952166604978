.registrationSection{
  display: flex;
  width: 100%;
  height: 100vh;

  .leftSideHeader{
    display: flex;
    border-bottom: 1px solid white;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
  }

  button:focus:not(:focus-visible){
    box-shadow: none;
  }

  .avatar{
    padding: 4px 6px;
    border: 3px solid ;
    border-radius: 5px;
  }

  .submitBtn{
    width: 300px;
    padding: 10px;
    border: 0;
    border-radius: 10px;
    color: white;
  }

  .maxH100{
    max-height: 80vh;
  }

 
}
