.meeting{
  max-height: 500px;
}

.meeting-list {
  .meetCard {
    display: flex;
    width: 100%;
    float: none;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 10px 12px !important;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .timeSection {
    display: inline-block;
    color: #fff;
    text-align: center;
    padding: 13px;
    float: left;
    border-radius: 8px;
    font-family: "Comfortaa";
    width: 110px;
  }

  .date {
    font-size: 25px;
    font-weight: 600;
    align-items: center;
  }

  .month {
    color: #fffffffd;
    font-size: 15px;
    display: block;
    font-style: normal;
    margin-left: 6px;
  }

  .day {
    color: #fff;
    font-size: 10px;
    display: block;
  }

  .details {
    display: inline-block;
    padding: 1px 15px;
  }
  .title {
    font-size: 19px;
    color: #fff;
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .time {
    font-size: 12px;
    margin-top: 8px;
  }

  .idSection {
    background: #343944c2;
    border-radius: 10px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding: 7px 15px;
    width: auto;
    font-size: 13px;
    text-align: center;
    border: 1px solid #8080806b;
  }

  .btnMeet {
    padding: 12px 12px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 14px;
    font-weight: 500;
    box-shadow: none;
    border: 0;
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  .schedule_meeting_unique {
    border: dashed 1px #f67d44;
  }

  .schedule_meeting_unique::after {
    position: absolute;
    left: 0px;
    right: 0px;
    top: -12px;
    content: "Schedule";
    width: 100px;
    background: #f67d44;
    color: #fff;
    border-radius: 25px;
    margin: 0 auto;
    text-align: center;
    font-size: 15px;
  }

  .schedule_meeting_unique_notFixed {
    border: dashed 1px #007bff;
  }

  .schedule_meeting_unique_notFixed::after {
    position: absolute;
    left: 0px;
    right: 0px;
    top: -12px;
    content: "Recurring";
    width: 100px;
    background: #007bff;
    color: #fff;
    border-radius: 25px;
    margin: 0 auto;
    text-align: center;
    font-size: 15px;
  }

}

.searchBtnFilter{
  width: 75%;
  padding: 10px 0px;
  margin: 0 auto;
  border: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 250px;
  border-radius: 10px;
}

.meetSectionUpload{
  border: 0;
  padding: 0px 22px;
  border-radius: 10px;
  margin-left: 10px;
  height: 48px;
}


.hideModal{
  width: 425px;
  position: fixed;
  z-index: 2;
  right: -430px;
  transition: 0.8s ease-in-out;
  height: 100vh;
  top: 0;
}

.showModal{
  right: 0 ;
  transition: 0.5s ease-in-out;
}

.colorHeaderSection{
  position: absolute;
    height: 46px;
    width: 100%;
    background: #ce165e;
    z-index: -1;
    top: 12px;
    opacity: 0.4;
}
.br-10 {
  border-radius: 10px;
}
.react-daterange-picker .react-datetimerange-picker__wrapper {
  border: 0px!important;
  outline: 0px;
  min-height: 48px;
  padding: 0 12px;
  border-radius: 10px;
}
.react-datetimerange-picker__inputGroup,
.react-datetimerange-picker__inputGroup__input {
  height: auto;
}

@media all and (max-width: 555px) {
  .meeting-list .meetCard {
    display: table-cell;

  }
}

@media screen and (max-width: 700px) {
  .headerBtnLabel{
    display: none;
  }
  .newWebinar-btn i {
    font-size: 20px;
  }
  button.newWebinar-btn.btn.btn-primary{
    padding: 10px 10px !important;            
  }
  .userNameCss{
    display: none;
  }
}

@media screen and (max-width: 414px) {
  .meetingButtons{
    margin-bottom: 10px;
  }
  .meetCardsMargin{
    margin-top: 15px;
  }
  .meetingID{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .schedule_actn_btn{
    display: grid !important;
  }
  .btnMeet{
    margin-top: 3px;
  }
}
@media screen and (max-width: 575px) {
  .previousMeet{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 576px) {
  .inputGroupCancel{
    position: relative;
    float: right;
    top: 0px;
  }
  .searchInputFilter{
    width: 200px !important;
  }
  
  .timeSectionRoom{
    width: 100% !important;
    margin-top: 10px;
  }
  .meetingButtons{
    margin-bottom: 10px;
  }
  .meetCardsMargin{
    margin-top: 15px;
  }
  .meetTitle{
    width: 100% !important;
    text-align: center;
  }
  .meetingID{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .schedule_actn_btn{
    display: grid;
  }
  .btnMeet{
    margin-top: 3px;
  }
  .searchBarFilter{
    width: 100% !important;
  }
  .filterButtonsRes{
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-template-rows: repeat(2, 2fr);
    row-gap: 5px;
    margin-bottom: 5px;
    position: relative;
    margin: auto;
    .previousMeet{
      grid-column: 1/ span 2;
    }
  }
  .dateRangePickerFormAndTo{
    width: 100% ;
    display: flex;
    justify-content: center;
  }
  .liListSection{
    display: block !important;

  }
}
@media screen and (max-width: 992px){
  .dateRangePickerFormAndTo{
    margin-top: 10px;
  }
}

.startDateDiv{
  position: relative;
  top: -2px;
  left: 5px;
  width: 60px;
}
span.react-datetimerange-picker__range-divider {
  position: relative;
  top: 5px;
}
span.react-datetimerange-picker__range-divider:before {
  content: "From";
  left: -92px;
  top: -13px;
}
span.react-datetimerange-picker__range-divider:before,
span.react-datetimerange-picker__range-divider:after {
  position: absolute;
  top: -13px;
  font-size: 12px;
  font-weight: 500;
  opacity: .5;
}
span.react-datetimerange-picker__range-divider:after {
  content: "To";
  left: 20px;
  top: -13px;
}
.react-datetimerange-picker__inputGroup {
  padding-top: 12px;
}