.wavewebinar_title {
  font-size: 24px;
  font-weight: 100;
  color: #fff;
  font-family: "Comfortaa";
}
.wavewebinar_title i {
  color: #5057f0;
}

.wave_webinar_list ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.wave_webinar_list ul li {
  display: flex;
  width: 100%;
  float: none;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 10px 0px;
  align-items: center;
  justify-content: space-between;
}

.wave_webinar_list ul li .m_start_date {
  display: inline-block;
  color: #fff;
  text-align: center;
  padding: 13px;
  float: left;
  border-radius: 8px;
  font-family: "Comfortaa";
  width: 102px;
}
.wave_webinar_list ul li .m_start_date font {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -1px;
  display: block;
}
.wave_webinar_list ul li .m_start_date font span {
  padding-top: 5px;
  letter-spacing: 0;
}
.wave_webinar_list ul li .m_start_date .month_wave_nc {
  color: #fffffffd;
  font-size: 16px;
  display: block;
  font-style: normal;
  margin-left: 3px;
}
.wave_webinar_list ul li .m_start_date span {
  color: #fff;
  font-size: 10px;
  display: block;
  padding-top: 0px;
}
.wave_webinar_list ul li .m_start_date i {
  display: none;
}

.wave_webinar_list ul li .sch_meeting_othr_dtl {
  display: inline-block;
  padding: 10px 15px;
}
.wave_webinar_list ul li .meeting_title {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.wave_webinar_list ul li .webinartype {
  font-size: 13px;
  margin-top: 5px;
}
.wave_webinar_list ul li .w_type_live {
  display: inline-block;
  color: #06caa5;
  line-height: normal;
  font-size: 13px;
  font-weight: 500;
}
.wave_webinar_list ul li .w_type_recorded {
  display: inline-block;
  color: #ed5362;
  line-height: normal;
  font-size: 13px;
  font-weight: 500;
}

.webinartype {
  padding: 6px;
  background: #979797;
  border-radius: 16px;
  padding-left: 35px;
  padding-right: 35px;
  text-align: center;
}

.wave_webinar_list ul li .meeting_date_time {
  font-size: 12px;
  color: #ffffff91;
  margin-top: 8px;
}

.wave_webinar_list ul li .schedule_actn_btn .btn {
  font-size: 14px;
}
.wave_webinar_list ul li .schedule_actn_btn .btn-view-detail {
  padding: 8px;
  padding-left: 20px;
  padding-right: 21px;
  border-radius: 6px; 
  font-weight: 400;
  box-shadow: none;
}

.wave_webinar_list ul li .schedule_actn_btn .btn-start-webinar {
  color: #fff;
  background-color: #06caa5;
  padding: 8px;
  padding-left: 20px;
  padding-right: 21px;
  border-radius: 6px;
  font-weight: 400;
}

.webinar .h55 {
  height: 55px !important;
}

.webinar .typeWebinar {
  background: #343944c2;
  border-radius: 23px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: auto;
}

input.searchInput.form-control {
  width: 250px;
  padding: 20px;
  font-size: 15px;
  border-radius: 9px;
  box-shadow: none;
  border: 0 !important;
  padding-left: 0px;
}
.webinar .DateRangePickerInput,
.webinar .DateInput {
  background: #282c35;
}
.webinar .DateInput_input {
  background: #282c35;
  font-size: 15px;
}
.webinar .DateRangePickerInput__withBorder {
  border-color: #282c35;
  height: 50px !important;
}

.webinar
  .DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2.DateRangePickerInput__showClearDates.DateRangePickerInput__showClearDates_3,
.webinar .DateInput.DateInput_1 {
  border-radius: 9px;
}

.webinar
  button.DateRangePickerInput_clearDates.DateRangePickerInput_clearDates_1.DateRangePickerInput_clearDates_default.DateRangePickerInput_clearDates_default_2 {
  top: 40% !important;
}

.webinar input#startingDate {
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
}

.activeBtn {
  background: #5057f0 !important;
  border-radius: 13px !important;
  color: white !important;
  border: 0px;
  font-size: 13px;
  padding: 6px;
}

.btnRadio {
  border-radius: 13px !important;
  font-size: 15px;
  padding: 10px 20px;
  font-weight: 300;
  margin-left: 10px;
}

.activeRadioBtn {
  background: #06caa5 !important;
}
.webinar {
  margin-left: 0;
  margin-right: 0;
}
.webinar input#startingDate,
.webinar input#EndingDate {
  height: 43px !important;
  border-bottom: none !important;
}

.searchSectionWebinar span#inputGroupPrepend {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  width: 160px;
}


.radioBtnWeb {
  display: flex;
  align-items: center;
}

.webinar .inputGroup {
  border: 0 !important;
  border-radius: 9px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.webinar .inputGroupCancel {
  border: 0 !important;
  border-radius: 9px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.upcoming_webinar_unique {
  position: relative;
  border: dashed 1px #2d91da;
  margin-top: 3px;
}
.upcoming_webinar_unique::after {
  position: absolute;
  left: 0px;
  right: 0px;
  top: -12px;
  content: "Upcoming";
  width: 100px;
  background: #2d91da;
  color: #fff;
  border-radius: 25px;
  margin: 0 auto;
  text-align: center;
  font-size: 15px;
}

.cancel_webinar {
  position: relative;
  border: dashed 1px #70787d;
  margin-top: 3px;
}
.cancel_webinar::after {
  position: absolute;
  left: 0px;
  right: 0px;
  top: -12px;
  content: "Canceled";
  width: 100px;
  background: #70787d;
  color: #fff;
  border-radius: 25px;
  margin: 0 auto;
  text-align: center;
  font-size: 15px;
}
.cancel_webinar .m_start_date {
  background: #70787d !important;
}
.cancel_webinar .meeting_title {
  color: #a09797;
}

button.newWebinar-btn.btn.btn-primary {
  border-radius: 6px; 
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: none !important;
  font-size: 15px;
}

/* Create Webinar Modal css start here */

.createWebModal .react-datepicker-wrapper {
  width: 100%;
}

.createWebModal .timezone_input .css-yk16xz-control,
.createWebModal .timezone_input .css-1pahdxg-control {
  border-radius: 10px;
  padding: 0.5rem;
}
.createWebModal .timezone_input {
}
.imgresponsive {
  max-width: 100%;
}

.submitBtn {
  padding-left: 31px;
  padding-right: 34px;
}

select.selectType {
  width: 180px;
  height: 38px;
  padding-left: 6px;
  border: 1px solid #9e9a9aa8;
  background: white;
  border-radius: 6px;
  outline: none;
}

.webinar .react-datepicker-wrapper {
  width: 100% !important;
}

@media screen and (max-width: 550px) {
  .wave_webinar_list ul li .schedule_actn_btn {
    display: block;
    width: 100%;
    text-align: center;
  }
  .wave_webinar_list ul li .sch_meeting_othr_dtl {
    width: 100%;
  }
  .wave_webinar_list ul li .sch_meeting_othr_dtl {
    text-align: center;
  }

  .wave_webinar_list ul li .m_start_date {
    width: 100%;
    display: block;
    border-radius: 5px 5px 0px 0px;
    padding: 5px 15px;
  }
  .wave_webinar_list ul li .m_start_date font {
    display: inline-block;
    margin-right: 5px;
    font-size: 25px;
  }
  .wave_webinar_list ul li .m_start_date .month_wave_nc {
    display: inline-block;
    margin-right: 10px;
    color: #ffffffde;
    font-size: 16px;
  }
  .wave_webinar_list ul li .m_start_date span {
    display: inline-block;
    font-size: 15px;
  }
  .wave_webinar_list ul li .m_start_date i {
    display: inline-block;
  }

  .wavewebinar_title {
    font-size: 18px;
  }
  .d_none_mob {
    display: none;
  }

  .f_14_mob {
    font-size: 14px;
  }
}

.w-120{
  width: 120px !important;
}
/* .paginate-m li {
  background: #282C35;

}
.wave_webinar_list .paginate-m li {
  background: #FFFFFF;
} */

.paginate-m li {
  background-color: #ffffff;
  border: 1px solid rgb(117, 117, 117);
}
.paginate-m .active {
background-color: #ce165e !important;
color: #fff;
border: 1px solid #ce165e;
}

.paginate-m li{
    display: inline-block !important;
    width: 45px !important;
    margin: 0 4px;
    text-align: center;
}
/* .paginate-m .previous {
  width: 12% !important;
}
.paginate-m .next {
  width: 12% !important;
} */


/******/

@media(max-width: 765px){
.webinar-text{
  text-align: center !important;
}
.webinar-btn-txt{
  margin: auto;
}
}
@media(max-width: 600px){
  .left-header-btn{
    display: none ;
  }
  .schedule_webinar_link_btn{
    display: grid !important;
  }
  .btn-webinar{
    padding: 12px 12px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 14px;
    font-weight: 500;
    box-shadow: none;
    border: 0;
    font-size: 12px;
    letter-spacing: 0.5px;
  }
}
@media(max-width: 600px){
.start-new-date{
  position: relative;
  left: 12px;
}
.url-invite-link{
  padding: 0px !important;
}
.webinarsTopBtnsGroup{
  display: grid;
    grid-template-columns: repeat(2, 2fr);
    row-gap: 10px;
}
.webinarSearchSection{
  width: 178px !important;
}
.previousBtn{
  grid-column: 1 / 2 span;
}
}
.share-msg-links{
  display: flex;
  justify-content: center;
  padding: 5px 10px;
}
.wbinarWelcmName{
  font-size: 24px !important;
}
.webMeetId{
  font-weight: 400 !important;
}
.webDate{
  font-weight: normal !important;
}
.newMeetStartDate{
  padding: 0 15px;
}