.auth-layout {
  // background-image: linear-gradient(
  //   to right bottom,
  //   #fdfdff,
  //   #eeeef7,
  //   #dde0ee,
  //   #ccd2e6,
  //   #bac5de
  // );
  padding: 40px;
  height: 100vh;

  .mobileInput {
    width: 100% !important;
  }

  .mobileVerificationBg {
    background-image: linear-gradient(
    to right bottom,
    #fdfdff,
    #eeeef7,
    #dde0ee,
    #ccd2e6,
    #bac5de
  );
  }

  .logoImg {
    width: 185px;
  }
  .innerCardAuth {
    background-color: white !important;
    border-radius: 15px;
    box-shadow: 0px 3px 17px 0px #e2e1e1;
    margin: 0 auto;
    width: 90%;
  }
  .subLabelContainer {
    color: #f04b55;
  }

  .subLabel {
    top: 7px;
  }

  .modalDescription {
    font-size: 14px;
  }
  .contactSales a {
    font-size: 13px;
  }
  .headingText {
    color: #251665;
  }

  input.bg-light.form-control {
    border-radius: 8px;
  }
  button.btn.btn-primary {
    background-color: #f04b55;
    border-color: #f04b55;
    box-shadow: 0px 5px 12px 0px #f5a7ab;
  }
  h2 {
    color: #281864;
  }
  .backIcon{
  width: 35px;
    position: absolute;
    top: 35px;
    margin-left: 4%;
    border: 0;
    background: transparent;
}
 .icon{
  font-size: 20px;
  color: #251665;
 }

 @media all and (max-width: 561px) {
  .logoImg {
    width: 275px ;
  }
  .containSection{
    text-align: center ;
  }
  .subLabelContainer{
    display: block !important;
  }
  .googleLayout{
    margin-top: 15px;
  }
}



@media all and (max-width: 459px) {
  .logoImg {
    width: 235px ;
  }
}

@media all and (max-width: 271px) {
  .logoImg {
    width: 193px ;
  }
}

}

@media all and (max-width: 428px) {
  .auth-layout{
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  .auth-layout .innerCardAuth{
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
}
@media (max-width: 992px){
  .auth-layout{
    height: 100%;
  }
}