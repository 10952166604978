.footerbottomContainer { border-top:1px solid #1f1f2e; }
.footerbottomContainer .footer-logo {font-size: 13px; color: #FFF; }
.footer-row {display: flex; flex-direction: row; justify-content: center; align-items: center;}
.footerbottomContainer .footer-logo a {margin-left: 5px;}
.footerbottomContainer .footer-divider {background: #2d2d3e; width: 1px; height: 50px; margin: 0px 30px; }
.footerbottomContainer .footer-sm-title {font-size: 13px; color: #FFF; margin-bottom: 10px;}

@media screen and (max-width:480px) { 
    .footerbottomContainer .footer-divider {margin: 0px 10px;}

}