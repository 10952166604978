@mixin breakpoints($points) {
    @if $points == mobile {
        @media screen and(max-width: 480px) {@content;}
    }

    @else if ($points) == laptop {
        @media screen and(max-width: 992px) {@content;}
    }

    @else if ($points) == desktop {
        @media screen and(min-width: 1224px) {@content;}
    }

    @else if ($points) == largescreen {
        @media screen and(min-width: 1450px) {@content;}
    }
        
}