.sarvwave_thme_bg { display: flex; flex-direction: row; align-items: center;  justify-content: center;
  min-height: 100vh;  width: 100%; margin: 0 auto; padding-top: 50px; background: #221e29} 
.sarvwave_thme_bg .middle_box_area{display: flex; width: 450px;   float: left;  align-items: left;   flex-direction: column; } 
.sarvwave_thme_bg .middle_box_area .wave_logo_part{ display: block; width: 100%; text-align: center;}
.sarvwave_thme_bg .middle_box_area .wave_logo_part a{ display: inline-block; width: 250px; height: 135px; 
	background-image: url(../../../../public/images/sarv-wave-white-bg.png); background-size: 250px; 
	background-repeat: no-repeat; background-position: 0px 3px}
.sarvwave_thme_bg .middle_box_area .wave_logo_part img{width: 250px;}
.attendy_name_bx {display: inline-block; width: 100%; margin-top: 30px;   background: #282330; border-radius: 10px; padding: 45px;}
.wxr_title{ display: block; text-align: center; font-size: 26px; color: #fff; font-weight: 100; letter-spacing: 1px; } 
.wxr_title span{display: block; font-size: 14px; letter-spacing: 0; padding-top: 5px; color: #ffffff9e; font-weight: 100;}
.wave_display_form{margin-top: 25px;}
.wave_display_form .form-group{margin-bottom: 25px;}
.wave_display_form .form-control{background: none; box-shadow: none; outline: none; border: none; border-bottom: solid 1px #ffffff1f; border-radius: 0px; padding: 0px; font-size: 15px; font-weight: 100; color: #fff;}
.wave_display_form .form-control:hover, .wave_display_form .form-control:focus{outline: none; box-shadow: none;}
.wave_display_form .meeting_enter_btn{  border: none; background: #ce165e; color: #fff; padding: 8px 15px; font-size: 15px; border-radius: 4px;}