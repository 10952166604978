.wave_wating_sctn {min-height: 100vh;  width: 100%; margin: 0 auto; background: #221e29; display: block; padding: 20px;}  
.wave_wating_sctn  .wave_logo_part{ display: inline-block; width: 100%; text-align: left;}
.wave_wating_sctn  .wave_logo_part img{width: 200px;}

 

.wave_wait_timer{color: #eea236; font-size: 35px; font-weight: 100; margin-top: 50px;}
.wave_wait_timer i{margin-right: 5px;}
 
.wait_msg{display: inline-block; width: 100%; text-align: center; font-size: 32px; color: #fff; font-weight: 100;}
.wait_msg span{display: block; font-size: 24px; padding-top: 15px;}

.hostwave_waiting_contianer { display: table;  margin:10px auto 0px; padding:10px 0px; border-radius: 20px; width: 100%; } 

.prtcpnt_chat_block{display: inline-block; width: 100%; background: #0000005c; border-radius: 5px; padding: 30px; min-height: 78vh; position: relative;}
.prtcpnt_chat_block ul{list-style: none; margin: 0px; padding: 0px 12px; max-height: 55vh; overflow: auto; }
.prtcpnt_chat_block ul li{display: inline-block; width: 100%; margin-bottom: 20px;}
.prtcpnt_chat_block ul li .sender_detail{display: block; font-size: 12px; color: #aaa; font-weight: 500;}
.prtcpnt_chat_block ul li .sender_detail span{float: right; font-size: 10px; color: #7d7d7d; display: inline-block; margin-top:5px; }
.prtcpnt_chat_block ul li .chat_text{ background: #2E2A35; padding: 15px; border-radius: 5px; margin-top: 10px; position: relative;
 font-size: 14px; color: #868686}
 .prtcpnt_chat_block ul li .chat_text audio{width: 100%;}
 .prtcpnt_chat_block  .chat_block ul li .chat_text:after { position: absolute; left: 25px; top: -8px; content: ""; display: inline-block; width: 0; 
 height: 0;  border-style: solid; border-width: 0 10px 10px 10px; border-color: transparent transparent #2E2A35 transparent;}
 

.prtcpnt_chat_block .chat_type_area{ position: absolute; bottom: 0px; left: 0px; width: 100%; padding: 20px;}
.prtcpnt_chat_block  .chat_type_bx{background: #1d1923; border:solid 2px #d3d3d3; border-radius: 40px; padding: 10px 20px; width: 100%; display: inline-block;}
.prtcpnt_chat_block  .chat_type_area .btn-link{padding:0px 4px; color: #46434d}
.prtcpnt_chat_block  .type_input{ width: 93%; border: none; outline: none; font-size: 15px; background: transparent; color: #f2f2f2; }

 .prtcpnt_chat_block h3 {text-align: left; color: #fff; font-size: 22px; margin: 0px 0px 15px; font-weight: 100}
 .prtcpnt_chat_block h3 i{margin-right: 5px;}


.wave_wait_rrom_title{color: #fabd47; font-size: 22px; text-align: left; font-weight: 100; margin-bottom: 20px; padding-left: 10px;}
.wave_wait_rrom_title i{margin-right: 5px;}

 

.waiting_participiant_list{display: inline-block; width: 100%; background: #0000005c; padding:30px 20px; min-height: 78vh; border-radius: 5px;}
.waiting_participiant_list h3{font-size: 22px; font-weight: 100; color: #fff; text-align: left; margin: 0px 0px 25px; padding: 0px;}
.waiting_participiant_list h3 font{color: #00c3ff}

.waiting_participiant_list{padding-right: 55px;}
.waiting_participiant_list ul{list-style: none; margin: 0px; padding: 0px 12px 0px 0px; max-height: 65vh; overflow: auto;}
.waiting_participiant_list ul li{display: inline-block; width: 100%; margin-bottom: 15px;}
.waiting_participiant_list ul li .prtcpnt_name {display: inline-block;  width: 55%;  font-size: 15px;  font-weight: 400;  color: #ffffffde;}
.waiting_participiant_list ul li .prtcpnt_name span {display: inline-block; width: 25px;  height: 25px; border-radius: 50%;  background: #eee;
    text-align: center;  margin-right: 5px; line-height: 25px;  color: #fff; font-weight: 400;}
.waiting_participiant_list ul li:nth-child(1) .prtcpnt_name span{background: #575ec8}
.waiting_participiant_list ul li:nth-child(2) .prtcpnt_name span{background: #4987b7}
.waiting_participiant_list ul li:nth-child(3) .prtcpnt_name span{background: #4fa480}
.waiting_participiant_list ul li:nth-child(4) .prtcpnt_name span{background: #a949b7}
.waiting_participiant_list ul li:nth-child(5) .prtcpnt_name span{background: #e9a686}
.waiting_participiant_list ul li:nth-child(6) .prtcpnt_name span{background: #b55b46}
.waiting_participiant_list ul li:nth-child(7) .prtcpnt_name span{background: #8153c6}
.waiting_participiant_list ul li:nth-child(8) .prtcpnt_name span{background: #e84242}
.waiting_participiant_list ul li:nth-child(9) .prtcpnt_name span{background: #35957a}
.waiting_participiant_list ul li:nth-child(10) .prtcpnt_name span{background: #ea5e5e}
.waiting_participiant_list ul li:nth-child(11) .prtcpnt_name span{background: #3e91b9}
.waiting_participiant_list ul li:nth-child(12) .prtcpnt_name span{background: #b93e3e}
.waiting_participiant_list ul li:nth-child(13) .prtcpnt_name span{background: #b96c3e}

.waiting_participiant_list ul li .prtcpnt_action{ display: inline-block;  float: right;}  
.w_approve_btn{display: inline-block; font-size: 12px; background: #299930; color: #fff; padding: 3px 8px; line-height: normal; border-radius: 10px; margin-left: 5px;}
.w_decline_btn{display: inline-block; font-size: 12px; background: #fff; color: #000; padding: 3px 8px; line-height: normal; border-radius: 10px; margin-left: 5px;}

.w_approve_btn:hover{color: #fff;}
.w_decline_btn:hover{color: #c83131}

.sound-wave{width:100%;}
.wave_audioPlayer label{color: #ffffff8c; font-size: 14px; width: 100%; font-weight: 400; }
.wave_audioPlayer audio{width:100%;}

.approve_partcpnt .w_approve_btn{ background: #000 }
.approve_partcpnt .prtcpnt_name{color: #66b649!important}

.partcpnt_disconnect .w_approve_btn{background: #4b4d4b}
.partcpnt_disconnect .prtcpnt_name{color: #787878 !important}


.wave_record_btn{ display: inline-block;    border-radius: 5px; background: #f48c1d; color: #fff; text-align: center;
 font-size: 14px; border:none; padding: 3px 10px; }

.wave_stop_btn{display: inline-block;  border-radius: 5px; background: #c43e14; color: #fff; text-align: center;
  font-size: 14px; border:none; padding:3px 10px; }

.waveno_participaint_info{ margin-top: 150px; padding: .75rem 1.25rem; border-radius: .25rem; background: #fff3cd24; border:solid 1px #fcc829; color: #d2a31b }

.prtcpnt_chat_block .chat_type_bx .btn-link:hover, .prtcpnt_chat_block .chat_type_bx .btn-link:focus{outline: none; box-shadow: none;}

.recording-modal-dialog{max-width: 500px;}

@media only screen and (max-width:1220px) { 
 .waiting_participiant_list{padding-right: 0px;} 
  .prtcpnt_chat_block .type_input{width: 90%;}
}

@media only screen and (max-width:990px) { 
 .prtcpnt_chat_block .type_input{width: 80%;}
 .waiting_participiant_list h3{font-size: 18px;}
 .prtcpnt_chat_block h3{font-size: 18px;}
 .prtcpnt_chat_block{padding:20px 15px;}
 .waiting_participiant_list{padding: 20px 15px;}
}

@media only screen and (max-width:768px) {
.prtcpnt_chat_block{margin-top: 15px;}
.wave_wait_rrom_title{font-size: 18px;}
 .meeting_dtl_78{ margin-top: 15px;}
 .waiting_participiant_list ul{padding-right: 0px;}
}



