.txt_theme{color: #483d8bd9}

 .wave_login_container { display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: 100vh;
background: url('../../../public/images/bg-03-01.svg')  center center no-repeat, url('../../../public/images/bg-03-01.svg') center center no-repeat; background-color: #221e29 /*hsla(216, 33%, 97%, 1)*/; 
background-size: 1150px 440px;}



.login_form_sec{display: block; height: auto; width: 450px;}
.lgn_frm_bx{box-shadow: 0 3px 6px hsla(0, 0%, 0%, 0.1); background: #fff; border-radius: 12px; padding: 2.5rem; width: 100%; margin:  auto;}
.lgn_logo{ text-align: center; margin-bottom: 1rem; }
.lgn_logo img{width: 150px;}
 
.w_login_heading{display: block; text-align: center; margin-bottom: 30px;}
.w_login_heading h4{color: #000; font-weight: 400; font-size: 1.6rem; margin-bottom: .5rem; font-family: 'Comfortaa', cursive;}
.w_login_heading p{font-weight: 100; font-size: 15px; color: #555; font-family:'Comfortaa', cursive;}

._wform_group{ margin-bottom: 1rem; /*background: hsla(216, 33%, 97%, 1);  border-radius: 30px;  border: 1px solid hsla(216, 33%, 97%, 1);*/ padding: 12px 5px;}
 
._wform_group_prepend{margin-right: -1px; display: flex;}
._wform_group_prepend i{font-size: 16px; color: #666; margin-top: 4px;}

._wform__group_text{  text-align: center; display: inline-block; min-width: 48px; border-radius: 0px; border:0px; padding:0px 0px 0px 5px;}
._wform_form_cntrl{border:none; padding-left: 0.25rem; border-radius: 0px; background: transparent; box-shadow: none; padding: 0px 10px 0px 5px ; height: auto; display: block; flex: 1 1 auto; width: 1%; margin-bottom: 0px; position: relative; font-size: 14px;}

._wform_form_cntrl:focus, ._wform_form_cntrl:active{box-shadow: none; outline: none;}

._grp_rw{ display: inline-block; width: 100%;  margin-bottom: 1rem; }

.extrnal_link span{display: inline-block; width:45%; padding: 0px 10px;}
.extrnal_link span a{font-size: 14px; color: #666}
.extrnal_link span .btn-link{font-size: 14px; color: #666}
.extrnal_link span .btn-link:hover, .extrnal_link span .btn-link:focus{box-shadow: none!important;}

.extrnal_link span:last-child{float: right; text-align: right;}

.submisn_btn{display: block; width: 100%; border-radius: 30px; font-size: 18px;}

.ftr_tag_log{text-align: center;  font-size: 14px;}

.error_msg_56{font-size: 12px; width: 100%; margin-top: -5px; margin-bottom: 10px; padding-left: 17px; color: #eb6737}

._wform_group.has-error{border:solid 1px #eb6737;}

.react-code-input{display:inline-flex !important;}
.react-code-input input{
font-size: 14px !important;
height: 20px !important;
width: 40px !important;
margin-top:0px !important; background: transparent!important; border:none!important; box-shadow: none!important; 
border-bottom: solid 1px #00000040!important; border-radius: 0px!important; font-family: inherit!important; text-align: center!important;
 padding-left: 0px!important
}




@media all and (min-width: 320px) and (max-width:767px){
.wave_login_container{background: #221e29 }
.lgn_frm_bx{ margin: 20px auto;} 
.react-code-input input{ height: 20px !important;width: 12% !important; }
.extrnal_link span{width: 50%!important; padding: 0px;}
.extrnal_link span.resend_otp_timer{width: 100%!important}
}

@media all and (min-width: 320px) and (max-width:500px){
.login_form_sec{width: 90%; margin: 0 auto;} 
}

@media all and (min-width: 320px) and (max-width:390px){
.lgn_logo img{width: 125px;}
.lgn_frm_bx{padding: 1.5rem;}
.w_login_heading h4{font-size: 22px;}
.w_login_heading p{font-size: 13px; line-height: 22px;}
._wform_group{padding: 8px 5px;}
.extrnal_link span .btn-link{font-size: 12px;}

}
